import React from 'react';
import styles from '../../styles/carDetailsModal.module.css';

interface CarDetailsModalProps {
    car: any;
    isOpen: boolean;
    onClose: () => void;
}

const CarDetailsModal: React.FC<CarDetailsModalProps> = ({ car, isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <button className={styles.closeButton} onClick={onClose}>
                    &times;
                </button>
                <h2 className={styles.modalHeading}>{`${car?.Make || 'Make no found'} ${car?.Model || 'Model no found'} - ${car?.Color || 'Color no found'}`}</h2>
                <div className={styles.modalBody}>
                    <p>{car?.bundeeProperties?.name || '-'}</p>
                    <p>{car?.bundeeProperties?.bodyclass || '-'}</p>
                    <p>{car?.bundeeProperties?.doors || '-'} Doors</p>
                    <p>{car?.bundeeProperties?.seatingCapacity || '-'} Seats</p>
                </div>
            </div>
        </div>
    );
};

export default CarDetailsModal;
