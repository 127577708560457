import React from "react";
import { CSSProperties } from "react";

interface InsuranceModalProps {
    show: boolean;
    onClose: () => void;
    onStartProcess: () => void;
    onNoInsurance: () => void;
}

const InsuranceModal: React.FC<InsuranceModalProps> = ({ show, onClose, onStartProcess, onNoInsurance }) => {
    if (!show) {
        return null;
    }

    return (
        <div style={modalStyles.overlay}>
            <div style={modalStyles.modal}>
                <h2>Insurance Verification</h2>
                <p>It looks like you haven't submitted your insurance details yet. Would you like to provide them now, or click "I don't have insurance" to proceed without?</p>
                <div style={modalStyles.buttons}>
                    <button style={modalStyles.button} onClick={onStartProcess}>Submit insurance details</button>
                    <button style={modalStyles.button} onClick={onNoInsurance}>I don't have insurance</button>
                </div>
            </div>
        </div>
    );
};

const modalStyles: { [key: string]: CSSProperties } = {
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2000,
    },
    modal: {
        position: "relative",
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "8px",
        maxWidth: "500px",
        margin: "0 10px",
        width: "100%",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
    },
    buttons: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
    },
    button: {
        padding: "10px 20px",
        backgroundColor: "#C7F100",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        flex: 1,
        margin: "0 5px",
        transition: "background-color 0.3s ease",
    },
    closeButton: {
        position: "absolute",
        top: "10px",
        right: "10px",
        padding: "5px 10px",
        backgroundColor: "#515151",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        zIndex: 2001,
    },
};

export default InsuranceModal;
