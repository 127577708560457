import React,  { CSSProperties, useState, useEffect,  } from "react";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
// assets
import stationDefault from "../../images/EV-Charging-Stations.jpg";
import pointGreen  from '../../images/point_green.png';
import pointYellow from '../../images/point_yellow_alt.png';
import pointRed from '../../images/point_red.png';
import solidDirections from '../../images/fa-solid_directions.png';
import chargepoint from '../../images/chargepoint.png';
import feesImage from '../../images/fees.png';
import plugImage from '../../images/plug.png';
import SwitchImage from '../../images/icons/switch.png';
import CarStationImage from '../../images/icons/car_station.png';
// assets
import { LazyLoadImage } from 'react-lazy-load-image-component';
// components
import MapComponent from "../MapComponent";
import Loader from "../Loader";
// context
import { useAuth } from "../../Context/AuthContext";
import { useModal } from "../../Context/ModalContext";
// utils
import showToast from "../../utils/toastHelpers";
// styles
import authStyles from "../../styles/authStyles";
// firebase
import { rtdbAvailableData } from '../../firebase/firebase';
import { ref, update } from '@firebase/database';
// services
import GoogleNewPlacesApi from "../../services/GoogleNewPlacesApi";

interface ChargingStationDetailProps {
    feature: any;
    switchContainer: 'car' | 'station';
    setSwitchContainer: (mode: 'car' | 'station') => void;
}
const CDN_URL = process.env.REACT_APP_CDN_URL;

// principal component
const ChargingStationDetail: React.FC<ChargingStationDetailProps> = ({
    feature, switchContainer, setSwitchContainer
}) => {
    // context
    const { user } = useAuth();

    //states
    const [imageSrc, setImageSrc] = useState(stationDefault);
    const [activeTab, setActiveTab] = useState<"Charging" | "Location" | "Cars">("Charging");
    const [loading, setLoading] = useState(false);
    const [stationStatus, setStationStatus] = useState(feature.properties.status);
    const [availableData, setAvailableData] = useState(feature.properties.availableData);
    // const [placeId, setPlaceId] = useState(feature?.properties?.places_id || '');

    // functions
    const handleUpdate = async() => {
        setLoading(true);
        try {
            if (!feature?.properties?.places_id) {
                throw new Error('No places_id found');
            }
            const response = await GoogleNewPlacesApi.getEvChargingAvailibility(feature?.properties?.places_id || '');
            if (!response) {
                throw new Error('Error fetching data from Google Places API');
            }
            const evChargeOptions = response?.data?.evChargeOptions;
            if (evChargeOptions) {
                const availabilityRef = ref(rtdbAvailableData, '/' + feature?.properties?.places_id);
                // object to update
                const updateData = {
                    "available": evChargeOptions?.connectorAggregation[0]?.availableCount || 0,
                    "occupied": evChargeOptions?.connectorAggregation[0]?.count - (evChargeOptions?.connectorAggregation[0]?.availableCount || 0) - (evChargeOptions?.connectorAggregation[0]?.outOfServiceCount || 0),
                    "outOfService": evChargeOptions?.connectorAggregation[0]?.outOfServiceCount || 0,
                    "reserved": 0,
                    "timestamp": evChargeOptions?.connectorAggregation[0]?.availabilityLastUpdateTime || "2024-04-25T19:45:00Z",
                    "unknown": 0
                }
                // update data
                await update(availabilityRef, updateData);
                setAvailableData(updateData);
                setStationStatus(updateData.available > 0 ? 'active' : 'unknown');
                console.log('runs-avalibity-update');
                setLoading(false);
                showToast("Update success", "success");
            }
        } catch (error: any) {
            console.log(error.message);
            showToast("Sorry, update failed", "error");
            setLoading(false);
        }
    }

    // effects
    useEffect(() => {
        const imageUrl = feature?.properties?.imageID
            ? `${CDN_URL}${feature.properties.imageID}.jpg`
            : stationDefault;
        setImageSrc(imageUrl);
    }, [feature]);

    useEffect(() => {
        if (feature?.properties?.places_id && user && feature?.properties?.status !== 'down / off') {
            // get data from Google Places API
            const placeId = feature?.properties?.places_id;
            const fetchData = async () => {
                try {
                    const response = await GoogleNewPlacesApi.getEvChargingAvailibility(placeId);
                    if (!response) {
                        throw new Error('Error fetching data from Google Places API');
                    }
                    const evChargeOptions = response?.data?.evChargeOptions;
                    if (evChargeOptions) {
                        const availabilityRef = ref(rtdbAvailableData, '/' + placeId);
                        // object to update
                        const updateData = {
                            "available": evChargeOptions?.connectorAggregation[0]?.availableCount || 0,
                            "occupied": evChargeOptions?.connectorAggregation[0]?.count - (evChargeOptions?.connectorAggregation[0]?.availableCount || 0) - (evChargeOptions?.connectorAggregation[0]?.outOfServiceCount || 0),
                            "outOfService": evChargeOptions?.connectorAggregation[0]?.outOfServiceCount || 0,
                            "reserved": 0,
                            "timestamp": evChargeOptions?.connectorAggregation[0]?.availabilityLastUpdateTime || "2024-04-25T19:45:00Z",
                            "unknown": 0
                        }
                        // update data
                        await update(availabilityRef, updateData);
                        setAvailableData(updateData);
                        setStationStatus(updateData.available > 0 ? 'active' : 'unknown');
                        console.log('runs-avalibity-update-efect');
                    } else {
                        setAvailableData(null);
                        setStationStatus(feature.properties.status || 'unknown');
                    }
                } catch (error: any) {
                    console.log(error.message);
                    setAvailableData(feature.properties.availableData || null);
                    setStationStatus(feature.properties.status || 'unknown');
                }
            }
            fetchData();
        } else {
            setAvailableData(feature.properties.availableData || null);
            setStationStatus(feature.properties.status || 'unknown');
        }
    }, [feature, user]);

    useEffect(() => {
        if(switchContainer === 'car') {
            setActiveTab('Cars');
        } else {
            setActiveTab('Charging');
        }
    }, [switchContainer]);

    return (
        <>
            <Loader loading={loading} />
            <StationImage imageSrc={imageSrc} setImageSrc={setImageSrc} />
            <StationDetails  key={'StationDetails:' + feature.properties.places_id} stationStatus={stationStatus} feature={feature} />

            <TabSelector activeTab={activeTab} onTabChange={setActiveTab} />

            {activeTab === "Charging" && <ChargingInfo availableData={availableData} feature={feature} handleUpdate={handleUpdate} user={user} />}
            {activeTab === "Location" && <LocationInfo feature={feature} stationStatus={stationStatus} switchContainer={switchContainer} setSwitchContainer={setSwitchContainer} />}
            {activeTab === "Cars" && <CarsInfo feature={feature} />}
        </>
    );
}

// components
interface StationImageProps {
    imageSrc: string;
    setImageSrc: (src: string) => void;
}
const StationImage: React.FC<StationImageProps> = ({ imageSrc, setImageSrc }) => {
    return (
        <div style={styles.parentContainer}>
            <div style={styles.imageContainer}>
                <LazyLoadImage
                    src={imageSrc}
                    alt="Station"
                    effect="blur"
                    placeholderSrc={stationDefault}
                    style={styles.imageStation}
                    onError={(e) => { setImageSrc(stationDefault) }}
                />
            </div>
        </div>
    );
}
interface StationDetailsProps {
    feature: any;
    stationStatus: string;
}
const StationDetails: React.FC<StationDetailsProps> = ({ feature, stationStatus }) => {
    return (
        <div style={styles.detailContainer}>
            <div style={styles.leftContent}>
                <div style={styles.titleName}>{feature.properties.flux_name || ''}</div>
                <div style={styles.titleAddress}>{feature.properties.address || ''}</div>
            </div>
            <div style={styles.rightContent}>
                <img src={stationStatus ? stationStatus === 'active' ? pointGreen : stationStatus === 'unknown' ? pointYellow : pointRed : pointYellow} alt="Menu" style={styles.icon} />
                <img src={solidDirections} alt="Menu" style={styles.icon} />
            </div>
        </div>
    );
}
// tab & components of tab
interface TabSelectorProps {
    activeTab: "Charging" | "Location" | "Cars";
    onTabChange: (tab: "Charging" | "Location" | "Cars") => void;
}
const TabSelector: React.FC<TabSelectorProps> = ({ activeTab, onTabChange }) => {
    return (
        <div style={styles.tabsContainer}>
            <Tab label="Charging" isActive={activeTab === "Charging"} onClick={() => onTabChange("Charging")} />
            <Tab label="Location" isActive={activeTab === "Location"} onClick={() => onTabChange("Location")} />
            <Tab label="Cars" isActive={activeTab === "Cars"} onClick={() => onTabChange("Cars")} />
        </div>
    );
}
interface TabProps {
    label: string;
    isActive: boolean;
    onClick: () => void;
}
const Tab: React.FC<TabProps> = ({ label, isActive, onClick }) => {
    return (
        <button
            style={{
                ...styles.tab,
                backgroundColor: isActive ? "#C7F100" : "#bebebe66",
            }}
            onClick={onClick}
        >
            {label}
        </button>
    );
};
interface ChargingInfoProps {
    feature: any;
    handleUpdate: () => void;
    user: any;
    availableData: any;
}
const ChargingInfo: React.FC<ChargingInfoProps> = ({ feature, handleUpdate, user, availableData}) => {
    // context
    const { openModal, setStationData } = useModal();
    // funtions
    const lastUpdate = (timestamp: string) => {
        let timestamp2 = new Date();
        if (moment(timestamp).isSame(timestamp2, 'day')) {
            return moment(timestamp).format('h:mm a');
        }
        else {
            return moment(timestamp).format('MMMM do YY, h:mm a');
        }
    }

    const handleOpenModal = () => {
        openModal();
        setStationData(feature);
    }

    return (
        <div style={styles.chargingInfoContainer}>
            <div style={styles.imageChargingInfoContainer}>
                <img src={chargepoint} alt="chargepoint" style={styles.chargepointImage}/>
                {feature.properties.charge_cost && <img src={feesImage} alt="fees" style={styles.chargepointImage} />}
            </div>
            <div style={styles.networkName}>
                {feature.properties.network_name || "NOT NETWORK NAME"}
                {user && <button style={styles.buttonProblem} onClick={handleOpenModal}>Problem?</button>}
            </div>
            {
                availableData ? (
                    <>
                        <div style={styles.grayBlock}>
                            <div style={styles.plugInfo}>
                                <img src={plugImage} alt="plug" style={styles.chargepointImage} />
                                <div>J1772 •{' '}{/* some thing */}{' '}KW</div>
                            </div>
                            <div style={styles.availableInfo}>
                                Available: {availableData.available || 0}
                            </div>
                        </div>
                        <div style={styles.statsList}>
                            <div><b>Available:</b> {availableData.available || 0}</div>
                            <div><b>Occupied:</b> {availableData.occupied || 0}</div>
                            <div><b>Reserved:</b> {availableData.reserved || 0}</div>
                            <div><b>Unknown:</b> {availableData.unknown || 0}</div>
                            <div><b>Out of Service:</b> {availableData.outOfService || 0}</div>
                            <div><b>Last Updated:</b> {lastUpdate(availableData.timestamp) || ''}</div>
                        </div>
                        {
                            user && feature?.properties?.places_id && (feature?.properties?.status !== 'down / off') &&
                            (
                                <div style={styles.buttonContainer}>
                                    <button className="buttonBlack" style={{ width: '50%' }} onClick={handleUpdate}>Update Now</button>
                                </div>
                            )
                        }
                    </>) : null
            }

        </div>
    );
}
interface LocationInfoProps {
    feature: any;
    switchContainer: 'car' | 'station';
    setSwitchContainer: (mode: 'car' | 'station') => void;
    stationStatus: string;
}
const LocationInfo: React.FC<LocationInfoProps> = ({ feature, stationStatus }) => {
    //location info share styles with charging info
    return (
        <div style={styles.chargingInfoContainer}>
            <div style={styles.locationFeeContainer}>
                <div style={styles.titleName}>Chargers</div>
                {feature.properties.park_cost && <img src={feesImage} alt="fees" style={styles.chargepointImage} />}
            </div>
            <div style={styles.titleAddress}>{feature.properties.chargers_location || ''}</div>
            <div style={styles.titleName}>Nearby</div>
            <div style={styles.titleAddress}>{feature.properties.chargers_nearby || ''}</div>
            <div style={styles.mapContainer}>
                <MapComponent
                    key={'MapComponent:'+ feature.properties.places_id}
                    initialLatitude={feature.geometry.coordinates[1]}
                    initialLongitude={feature.geometry.coordinates[0]}
                    initialZoom={17}
                    width={'100%'}
                    height={'100%'}
                    feature={feature}
                    stationStatus={stationStatus}
                />
            </div>
        </div>
    );
}
interface CarsInfoProps {
    feature: any;
}
const CarsInfo: React.FC<CarsInfoProps> = ({ feature }) => {
    if ( feature.properties?.cars?.length > 0 ) {
        return (<>
            {feature.properties?.cars.map((car: any) => (
                <Card key={car.VIN} car={car} station={feature}/>
            ))}
        </>);
    } else {
        return (
            <div style={styles.carInfoContainer}>
                <h2 style={styles.titleName}>There are no cars at this station!</h2>
                <p style={styles.titleAddress}>Finding a car is easy. Go back to the main map, and filter the map by car, look for this switch:</p>
                <img src={SwitchImage} alt="Menu" style={styles.imageCarInfo} />
                <p style={styles.titleAddress}>Stations with cars are identified using this icon:</p>
                <img src={CarStationImage} alt="Menu" style={styles.imageCarInfo} />
            </div>
        );

    }
}

interface CardProps {
    car: any;
    station: any;
}

const Card: React.FC<CardProps> = ({ car, station }) => {
    const [imageSource, setImageSource] = useState(`https://fiat.b-cdn.net/${car.ID}.jpeg`);
    const navigate = useNavigate();

    const handleReserveNow = () => {
        navigate('/booking')
    }

    return (
        <div style={styles.carInfoContainer}>
            <LazyLoadImage
                alt="flux-car"
                effect="blur"
                placeholderSrc={require('../../images/place-holder.png')}
                src={imageSource}
                style={styles.imageCar}
                onError={(e) => { setImageSource(require('../../images/no-image-available-2.png')) }}
            />
            <div style={{ ...authStyles.commonStyles.center, ...authStyles.commonStyles.buttonContent, margin: '20px' }}>
                <h2 style={styles.titleName}>{`${car.Model || 'No model'} - ${car.Color || ''}`}</h2>
                <button type='button' className="buttonBlack" onClick={handleReserveNow}>Reserve Now</button>
            </div>
        </div>
    );
}

// styles
const styles: { [key: string]: CSSProperties } = {
    parentContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        maxHeight: '50vh',
    },
    imageContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        width: '100%',
        maxHeight: '45vh',
        overflow: 'hidden',
        margin: '20px 30px 0 30px',
        border: '1px solid #e0e0e0',
        borderRadius: '5px'
    },
    imageStation: {
        width: '100%',
        height: 'auto',
    },
    imageCar: {
        width: '100%',
        height: 'auto',
        borderRadius: '10px',
    },
    detailContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 20px',
        margin: '0 20px 0 20px',
    },
    leftContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    },
    titleName: {
        fontSize: '20px',
        margin: '0',
        color: '#333',
        fontWeight: 'bold',
    },
    titleAdress: {
        fontSize: '14px',
        margin: '0',
        color: '#333',
    },
    rightContent: {
        display: 'flex',
        gap: '20px'
    },
    icon: {
        maxWidth: '30px',
        maxHeight: '30px',
        cursor: 'pointer'
    },
    // tabs
    tabsContainer: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'center',
        marginTop: '20px',
    },
    tab: {
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        outline: 'none',
        transition: 'background-color 0.3s',
        color: 'black'
    },
    // charging info styles & location info styles
    chargingInfoContainer: {
        backgroundColor: 'white',
        width: 'calc(100% - 40px)',
        height: '450px',
        padding: '10px 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    imageChargingInfoContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        overflow: 'hidden'
    },
    networkName: {
        fontSize: '20px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        padding: '10px 0',
        alignItems: 'center',
    },
    buttonProblem: {
        backgroundColor: '#C7F100',
        color: '#333',
        border: 'none',
        borderRadius: '5px',
        padding: '10px 10px',
        fontWeight: 'bold',
        cursor: 'pointer',
        outline: 'none',
        transition: 'background-color 0.3s',
    },
    grayBlock: {
        width: '100%',
        height: '40px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#dbdbdb66',
        alignItems: 'center',
        padding: '5px'
    },
    statsList: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        fontSize: '18px',
        fontWeight: 'normal'
    },
    chargepointImage: {
        maxHeight: '40px',
        width: 'auto'
    },
    plugInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '5px 10px',
        gap: '5px'
    },
    availableInfo: {
        backgroundColor: '#03A76D',
        borderRadius: '5px',
        padding: '5px 10px',
        color: 'white',
    },
    mapContainer: {
        marginTop: '20px',
        width: '100%',
        height: 200,
    },
    locationFeeContainer: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
    },
    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '10px'
    },
    // cars info styles
    carInfoContainer: {
        width: 'calc(100% - 40px)',
        height: 'auto',
        padding: '20px 20px',
        margin: '20px 0 20px 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '5px',
        borderRadius: '10px',
        border: '1px solid #e0e0e0',
    },
    imageCarInfo: {
        maxWidth: '70px',
        height: 'auto',
    },
};

export default ChargingStationDetail;