// ModalContext.js
import React, { createContext, useContext, useState } from 'react';

type ModalContextType = {
    showModal: boolean;
    openModal: () => void;
    closeModal: () => void;
    stationData: any;
    setStationData: any;
};
const ModalContext = createContext<ModalContextType>({
    showModal: false,
    openModal: () => {},
    closeModal: () => {},
    stationData: {},
    setStationData: () => {},

});

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
    const [showModal, setShowModal] = useState(false);
    const [stationData, setStationData] = useState({});

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    return (
        <ModalContext.Provider value={{ showModal, openModal, closeModal, stationData, setStationData }}>
            {children}
        </ModalContext.Provider>
    );
};
