import React, { useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';

const NotFound: React.FC = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Show the loading for 1 second and then render the page
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        // Cleanup the timer on component unmount
        return () => clearTimeout(timer);
    }, []);


    return (
        <>
            <Loader loading={loading} />
            {!loading && (
                <div style={styles.errorContainer}>
                    <h2>Oops! Something went wrong.</h2>
                    <h3>404 - Page not found</h3>
                    <p>Sorry, the page you are looking for does not exist.</p>
                    <button onClick={() => navigate('/')} style={styles.refreshButton}>Go Home</button>
                </div>
            )}
        </>
    );
}

const styles: { [key: string]: React.CSSProperties } = {
    errorContainer:  {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        backgroundColor: '#f8f8f8',
        textAlign: 'center',
        padding: '20px'
    },
    refreshButton: {
        marginTop: '20px',
        padding: '10px 20px',
        fontSize: '16px',
        backgroundColor: '#333',
        color: 'white',
        border: 'none',
        borderRadius: '15px',
        cursor: 'pointer',
    }
}

export default NotFound;