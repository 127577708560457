import React from 'react';
import { CSSProperties } from 'react';

interface FullScreenModalProps {
    show: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const FullScreenModal: React.FC<FullScreenModalProps> = ({ show, onClose, children }) => {
    if (!show) {
        return null;
    }

    return (
        <div style={styles.overlay}>
            <div style={styles.modal}>
                <button style={styles.closeButton} onClick={onClose}>Close</button>
                {children}
            </div>
        </div>
    );
};

const styles: { [key: string]: CSSProperties } = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        height: '100%',
        zIndex: 2000,
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    modal: {
        width: '100%',
        height: 'calc(100% + 130px)',
        backgroundColor: '#fff',
        position: 'relative',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        padding: '10px',
        backgroundColor: 'transparent',
        color: '#000000',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        zIndex: 2001,
    },
};

export default FullScreenModal;
