import React, { useEffect, useRef, useState, useMemo } from 'react';
// assets
import point_green_url from '../images/point_green.png';
import point_red_url from '../images/point_red.png';
import point_yellow_alt_url from '../images/point_yellow_alt.png';
// hooks
// components
import { Wrapper, Status } from '@googlemaps/react-wrapper';

const renderLoading = (status: Status): React.ReactElement => {
    switch (status) {
        case Status.LOADING:
            return <h1>Loading...</h1>;
        case Status.FAILURE:
            return <h1>Error loading maps</h1>;
        default:
            return <></>;
    }
};

interface MapProps {
    initialLatitude: number;
    initialLongitude: number;
    initialZoom: number;
    width?: string | number;
    height?: string | number;
    feature: any;
    stationStatus: any;
}

const MapComponent: React.FC<MapProps> = ({
    initialLatitude,
    initialLongitude,
    initialZoom,
    width,
    height,
    feature,
    stationStatus,
}) => {

    return (
        <>
            <Wrapper apiKey={process.env.REACT_APP_GOOGLE_API_KEY || ''} render={renderLoading}>
                <Map initialLatitude={initialLatitude} stationStatus={stationStatus} initialLongitude={initialLongitude} initialZoom={initialZoom} width={width} height={height} feature={feature} />
            </Wrapper>
        </>
    );
};

const Map: React.FC<MapProps> = ({ initialLatitude, initialLongitude, initialZoom, width, height, feature, stationStatus }) => {
    // refs
    const ref = useRef<HTMLDivElement>(null);
    const markerRefs = useRef<google.maps.marker.AdvancedMarkerElement[]>([]);
    // states
    const [map, setMap] = useState<google.maps.Map>();
    // const markerRefs = useRef<google.maps.marker.AdvancedMarkerElement[]>([]);
    const center = useMemo(() => ({ lat: initialLatitude, lng: initialLongitude }), [initialLatitude, initialLongitude]);
    const zoom = initialZoom;

    //effects
    useEffect(() => {
        if (ref.current && !map) {
            const newMap = new window.google.maps.Map(ref.current, {
                center,
                zoom,
                disableDefaultUI: false,
                zoomControl: true,
                mapTypeControl: false,
                mapId: '6d5ee41397cfdef1',
            });
            setMap(newMap);
        }
    }, [ref, map, center, zoom, feature]);

    useEffect(() => {
        // Function to determine which icon to use based on the feature's status
        const determineIcon = (status: string) => {
            switch (status) {
                case 'active':
                    return point_green_url;
                case 'unknown':
                    return point_yellow_alt_url;
                case 'down / off':
                    return point_red_url;
                default:
                    return point_yellow_alt_url; // A default icon if status is not recognized
            }
        };

        if (!map || !feature || !stationStatus) return;

        const loadMarkers = async () => {

            // clean up markers
            markerRefs.current.forEach(marker => {
                if (marker.element && marker.element.parentNode) {
                    marker.element.parentNode.removeChild(marker.element);
                }
            });
            markerRefs.current = [];

            const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
            const Img = document.createElement('img');
            Img.src = determineIcon(stationStatus);
            Img.style.width = '30px';

            const marker = new AdvancedMarkerElement({
                position: { lat: feature.geometry.coordinates[1], lng: feature.geometry.coordinates[0] },
                map: map,
                title: feature.properties.flux_name,
                content: Img

            });

            markerRefs.current.push(marker);
        };
        loadMarkers();

        // clean up
        return () => {
            // clean up markers
            markerRefs.current.forEach(marker => {
                if (marker.element && marker.element.parentNode) {
                    marker.element.parentNode.removeChild(marker.element); // Remove from DOM
                }
            });
            markerRefs.current = [];
        };

    }, [map, feature, stationStatus]);

    return (
        <div ref={ref} style={{ width, height }} />
    );
};


export default React.memo(MapComponent);
