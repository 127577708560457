import React, { useState, useEffect } from 'react';
import authStyles from '../../styles/authStyles';
import no_image_available from '../../images/no-image-available.png';

interface SplashImageProps {
    isMobile: boolean;
    animate: boolean;
    SplashScreen: string;
}

const SplashImage: React.FC<SplashImageProps> = ({ isMobile, animate, SplashScreen }) => {
    const [image, setImage] = useState(SplashScreen || no_image_available);

    useEffect(() => {
        setImage(SplashScreen || no_image_available);
    }, [SplashScreen]);
    return (
        <div style={{...authStyles.dynamicStyles.imagePart(isMobile), transform: animate ? 'translateX(0%)' : 'translateX(-100%)'}}>
            <img
                src={image}
                alt="Flux-Splash"
                style={authStyles.dynamicStyles.image as any}
                onError={() => setImage(no_image_available)}
                />
        </div>
    );
}

export default SplashImage;
