import React from 'react';
import { useNavigate } from 'react-router-dom';
// assets
import menuIcon from '../images/menu-icon.png';
import Logo from '../images/Logo.png';
// context
import { useAuth } from '../Context/AuthContext';
import { useChat } from '../Context/ChatContext';
import { useBar } from '../Context/BarContext';
// components
import Sidebar from './Sidebar';
// hooks
import { useIsMobile } from '../hooks/useIsMobile';

const TopBar: React.FC = () => {
    const { toggleBar } = useBar();
    const {openChat} = useChat();
    const navigate = useNavigate();
    const { user, authSignOut } = useAuth();
    const isMobile = useIsMobile();
    const mobileButtonStyle = {
        fontSize: '8px',
    };

    //funtions
    const handleLogin = () => {
        navigate("/login");
    }

    const handleSignUp = () => {
        navigate("/signup");
    }

    const handleHome = () => {
        navigate("/");
    }

    const handleLogout = async () => {
        await authSignOut();
        navigate("/login");
    }

    return (
        <div id="TopBar" style={styles.container as React.CSSProperties}>
            <img id="menu-icon" src={menuIcon} alt="Menu" style={styles.icon} onClick={(e) => {
                e.stopPropagation(); // Prevent the event from propagating
                toggleBar();
            }
            } />
            <span onClick={handleHome}><img src={Logo} alt="Logo" style={styles.logo} /></span>
            <a href="https://www.fluxev.city/help" style={{ ...styles.button, ...(isMobile ? mobileButtonStyle : {}), textDecoration: 'none', color: 'black' }}>HELP</a>
            <div style={styles.downloadButtonContainer}>
                <a href="https://www.fluxev.city/download" style={{ ...styles.button, ...(isMobile ? mobileButtonStyle : {}), ...styles.downloadButton }}>DOWNLOAD THE APP</a>
            </div>
            {
                !isMobile && (
                    <div style={styles.authButtons}>

                        {
                            user ?
                                <>
                                    <span style={styles.button} onClick={openChat}>Chat With Us</span>
                                    <span style={styles.button} onClick={handleLogout}>Log out</span>
                                </>
                                : <>
                                    <span style={styles.button} onClick={handleSignUp}>Sign up</span>
                                    <span style={styles.button} onClick={handleLogin}>Log in</span>
                                </>
                        }
                    </div>
                )
            }
            <Sidebar onClose={toggleBar} handleLogout={handleLogout} isMobile={isMobile} />
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        padding: '20px 20px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        position: 'fixed', // Changed from 'relative' to 'fixed'
        top: 0, // Position it at the top
        left: 0, // Align to the left
        width: '100%', // Full width
        zIndex: 1500,
    },
    icon: {
        maxWidth: '24px',
        maxHeight: '24px',
        marginRight: '20px',
        cursor: 'pointer'
    },
    logo: {
        maxWidth: '150px',
        maxHeight: '24px',
        marginRight: '20px',
        cursor: 'pointer'
    },
    title: {
        fontSize: '24px',
        margin: '0',
        color: '#333'
    },
    // sign up / login
    authButtons: {
        display: 'flex',
        alignItems: 'center'
    },
    button: {
        marginLeft: '10px',
        marginRight: '10px',
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    downloadButtonContainer: {
        borderColor: 'black',
        borderWidth: 1,
        borderRadius: '20px',
        padding: '8px',
        borderStyle: 'solid',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    downloadButton: {
        textDecoration: 'none',
        color: 'black',
        background: 'transparent'
    }
};


export default TopBar;
