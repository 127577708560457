import React from 'react';
import './App.css';
import './sentry';
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
// components
import TopBar from './components/TopBar';
import AppRoutes from './components/AppRoutes';
// context
import { AuthProvider } from '../src/Context/AuthContext';
import { ModalProvider } from '../src/Context/ModalContext';
import { ChatProvider } from '../src/Context/ChatContext';
import { BarProvider } from './Context/BarContext';
// stripe
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// hooks



const stripe_key = process.env.REACT_APP_STRIPE_PUBLISH ? process.env.REACT_APP_STRIPE_PUBLISH : '';
const stripePromise = loadStripe(stripe_key);


function App() {
    return (
        <Elements stripe={stripePromise}>
            <Router>
                <AuthProvider>
                    <ChatProvider>
                        <ModalProvider>
                            <BarProvider >
                                <TopBar />
                                <AppRoutes />
                                <ToastContainer
                                    position="bottom-right"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                    theme="light"
                                />
                            </BarProvider>
                        </ModalProvider>
                    </ChatProvider>
                </AuthProvider>
            </Router>
        </Elements >
    );
}



export default App;
