// ChatModal.tsx
import React from 'react';
import Chat from './Chat';
import styles from './ChatModal.module.css';

interface ChatModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const ChatModal: React.FC<ChatModalProps> = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div className={styles["modal-overlay"]} onClick={handleOverlayClick}>
            <div className={styles["modal-content"]}>
                <button className={styles["close-button"]} onClick={onClose}>Close</button>
                <Chat />
            </div>
        </div>
    );
};

export default ChatModal;
