// components/ModalTripReminder.tsx
import React, { CSSProperties } from 'react';

interface ModalTripReminderProps {
    showModal: boolean;
    closeModal: () => void;
    navigateToTrips: () => void;
    isMobile: boolean;
}

const ModalTripReminder: React.FC<ModalTripReminderProps> = ({
    showModal,
    closeModal,
    navigateToTrips,
    isMobile,
}) => {
    if (!showModal) return null;

    return (
        <div style={styles.overlay} onClick={closeModal}>
            <div style={styles.modal(isMobile)} onClick={(e) => e.stopPropagation()}>
                <button style={styles.closeButton} onClick={closeModal}>
                    ×
                </button>
                <div style={styles.content}>
                    <h2>It looks like you have a trip!</h2>
                    <p>Would you like to navigate to your trips?</p>
                    <div style={styles.buttonContainer}>
                        <button style={styles.button} onClick={navigateToTrips}>
                            Go to Trips
                        </button>
                        <button style={styles.button} onClick={closeModal}>
                            Skip
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const styles = {
    overlay: {
        position: 'fixed' as 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    modal: (isMobile: boolean) =>
    ({
        backgroundColor: 'white',
        borderRadius: '8px',
        width: isMobile ? '90%' : '60%',
        maxHeight: '80%',
        overflowY: 'auto',
        position: 'relative',
        padding: '20px',
    } as CSSProperties),
    closeButton: {
        position: 'absolute' as 'absolute',
        top: '10px',
        right: '10px',
        background: 'none',
        border: 'none',
        fontSize: '24px',
        cursor: 'pointer',
    },
    content: {
        padding: '20px',
        textAlign: 'center' as 'center',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '30px',
    },
    button: {
        padding: '10px 20px',
        fontSize: '16px',
        backgroundColor: '#C7F100',
        color: 'black',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        margin: '0 10px',
    },
    buttonHover: {
        backgroundColor: '#b2d700',
    },
};

export default ModalTripReminder;
