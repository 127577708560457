import React, { useState, useEffect, CSSProperties } from 'react';
import IDVC from '@idscan/idvc2';
import '@idscan/idvc2/dist/css/idvc.css';
import License from '../images/modal-license.png';
import showToast from '../utils/toastHelpers';
import fluxRestApi from '../services/FluxRestApi';
import { useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import bundeeRestApi from '../services/BundeeRestApi';
import moment from 'moment';

// Get the token from the URL query parameters
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const secretKey = process.env.REACT_APP_SECRET_KEY || '';

const VerifyLicenseMobileFluxApp: React.FC = () => {
    // Params
    const query = useQuery();
    const token = query.get('token');

    // States
    const [error, setError] = useState<string | null>(null);
    const [isButtonHovered, setIsButtonHovered] = useState<boolean>(false);
    const [isProcessStarted, setIsProcessStarted] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isThankYou, setIsThankYou] = useState<boolean>(false);
    const [contactData, setContactData] = useState<any>(null);
    const [errorFetchingContactData, setErrorFetchingContactData] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [userId, setUserId] = useState<string>('');

    const handleButtonMouseEnter = () => setIsButtonHovered(true);
    const handleButtonMouseLeave = () => setIsButtonHovered(false);

    // const sendNotificationToSupportUsers = async (result: any, failedValidations: string[]) => {
    //     try {
    //         const data = {
    //             title: `Idscan - license verification`,
    //             body: `${result?.document?.fullName || 'Full name: N/A'} requestId:${result.requestId} has completed the IDscan license verification process. The following validations were below 90%: ${failedValidations.join(', ')}. Please review it here: https://mydive.idscan.net/Requests/Details/${result.requestId}`,
    //             send_sms: true,
    //             send_email: true,
    //         };
    //         const response = await fluxRestApi.sendNotificationToSupportUsers(data.title, data.body, data.send_sms, data.send_email);
    //         if (response !== null) {
    //             console.log("Notification sent successfully to support users");
    //         }
    //     } catch (error) {
    //         console.log("There was an error while trying to send a notification to support users");
    //     }
    // };

    const validateVerificationResults = (result: any) => {
        const { documentVerificationResult, faceMatchVerificationResult, antiSpoofingVerificationResult } = result;

        const documentConfidence = documentVerificationResult.documentConfidence >= 90;
        const faceMatchConfidence = faceMatchVerificationResult.faceMatchConfidence >= 90;
        const antiSpoofingConfidence = antiSpoofingVerificationResult.antiSpoofingFaceImageConfidence >= 90;

        const addressValidation = result.externalVerificationResults?.find((v: any) => v.name === 'AddressValidation');
        const dmvValidation = result.externalVerificationResults?.find((v: any) => v.name === 'DMVValidation');
        const identiFraudValidation = result.externalVerificationResults?.find((v: any) => v.name === 'IdentiFraudValidation');

        const addressValidationScore = addressValidation?.values !== null
        const dmvValidationScore = dmvValidation?.values !== null
        const identiFraudValidationScore = identiFraudValidation?.values !== null

        const failedValidations = [];
        if (!documentConfidence) failedValidations.push('Document Confidence');
        if (!faceMatchConfidence) failedValidations.push('Face Match Confidence');
        if (!antiSpoofingConfidence) failedValidations.push('Anti-Spoofing Confidence');
        if (!addressValidationScore) failedValidations.push('Address Validation');
        if (!dmvValidationScore) failedValidations.push('DMV Validation');
        if (!identiFraudValidationScore) failedValidations.push('IdentiFraud Validation');

        const isVerificationSuccessful = documentConfidence && faceMatchConfidence && antiSpoofingConfidence && addressValidationScore && dmvValidationScore && identiFraudValidationScore;
        return { isVerificationSuccessful, failedValidations };
    };

    const updateFluxProperties = async (requestId: string, status: string) => {
        try {
            if (!contactData?.contact?.id) {
                throw new Error('Contact ID is missing');
            }
            const data = {
                idscan_request_id: requestId,
                idscan_status: status,
            };
            const response = await fluxRestApi.updatePartialFluxProperties(contactData.contact.id, data);
            if (response?.data?.contact) {
                console.log('Contact properties updated successfully');
            } else {
                throw new Error('Error updating contact properties');
            }
        } catch (error) {
            console.error('Error updating contact properties:', error);
            throw new Error('Error updating contact properties');
        }
    };

    const startIDVCProcess = () => {
        setIsProcessStarted(true);

        const licenseKey = process.env.REACT_APP_LICENSE_KEY || '';
        const secretKey = process.env.REACT_APP_SECRET_KEY || '';

        if (!licenseKey || !secretKey) {
            setError('License key or secret key is missing. Please check your environment variables.');
            return;
        }

        const idvcInstance = new IDVC({
            el: 'videoCapturingEl',
            licenseKey,
            networkUrl: 'networks/',
            chunkPublicPath: '/networks/',
            resizeUploadedImage: 1200,
            fixFrontOrientAfterUpload: false,
            autoContinue: true,
            isShowDocumentTypeSelect: false,
            useCDN: false,
            isShowGuidelinesButton: false,
            showSubmitBtn: false,
            language: 'en',
            realFaceMode: 'auto',
            processingImageFormat: 'jpeg',
            documentTypes: [
                {
                    type: 'ID',
                    steps: [
                        {
                            type: 'front',
                            name: 'Document Front',
                            mode: { uploader: false, video: true },
                        },
                        {
                            type: 'pdf',
                            name: 'Document PDF417 Barcode',
                            mode: { uploader: false, video: true },
                        },
                        {
                            type: 'face',
                            name: 'Face',
                            mode: { uploader: false, video: true },
                        },
                    ],
                },
            ],
            onChange(data: any) {
                // console.log('on change', data);
            },
            onCameraError(data: any) {
                console.error('Camera error:', data);
                showToast('An error occurred while accessing the camera. Please reload the page.');
            },
            onReset(data: any) {
                // console.log('on reset', data);
            },
            onRetakeHook(data: any) {
                // console.log('retake hook', data);
            },
            clickGuidlines() {
                console.log('click Guidelines');
            },
            submit: async (data: any) => {
                idvcInstance.showSpinner(true);
                setIsLoading(true);
                let frontStep, pdfStep, faceStep;
                let frontImage, backImage, faceImage;
                let captureMethod;
                let rawTrackString;

                try {
                    switch (data.documentType) {
                        case 1: // Drivers License and Identification Card
                            frontStep = data.steps.find((item: any) => item.type === 'front');
                            pdfStep = data.steps.find((item: any) => item.type === 'pdf');
                            faceStep = data.steps.find((item: any) => item.type === 'face');

                            frontImage = frontStep.img.split(/:image\/(jpeg|png);base64,/)[2];
                            backImage = pdfStep.img.split(/:image\/(jpeg|png);base64,/)[2];
                            faceImage = faceStep.img.split(/:image\/(jpeg|png);base64,/)[2];

                            rawTrackString = pdfStep && pdfStep.trackString ? pdfStep.trackString : '';

                            captureMethod = JSON.stringify(+frontStep.isAuto) + JSON.stringify(+pdfStep.isAuto) + JSON.stringify(+faceStep.isAuto);
                            break;
                    }

                    const trackStringArray = rawTrackString.split('.');
                    let trackString = trackStringArray[0];
                    let barcodeParams = trackStringArray[1];

                    if (!trackString) {
                        showToast('Sorry, we could not read the barcode from your license. Please try again.', 'error', 5000);
                        throw new Error('Barcode is missing');
                    }

                    let request = {
                        frontImageBase64: frontImage,
                        backOrSecondImageBase64: backImage,
                        faceImageBase64: faceImage,
                        documentType: data.documentType,
                        trackString: {
                            data: trackString,
                            barcodeParams: barcodeParams,
                        },
                        overriddenSettings: {
                            isOCREnabled: true,
                            isBackOrSecondImageProcessingEnabled: true,
                            isFaceMatchEnabled: true,
                        },
                        metadata: {
                            captureMethod: captureMethod,
                        },
                    };

                    const response = await fetch('https://dvs2.idware.net/api/v4/verify', {
                        method: 'POST',
                        headers: {
                            Authorization: 'Bearer ' + secretKey,
                            'Content-Type': 'application/json;charset=utf-8',
                        },
                        body: JSON.stringify(request),
                    });

                    const result = await response.json();

                    if (!result?.requestId) {
                        showToast('your verification request failed, please try again.', 'error');
                        throw new Error('Invalid response from the server');
                    }

                    // console.log('result', result);

                    // Check if all verification results are at least 90%
                    const { isVerificationSuccessful } = validateVerificationResults(result);

                    if (isVerificationSuccessful) {
                        await updateFluxProperties(result.requestId, 'completed');
                        const bundeeData =  {
                            "userId": contactData?.contact?.bundee_user_id,
                            "idScanRequestID": result.requestId,
                            "isVerified": true,
                            "drivingLicenseStatus": "isVerified",
                            "expiryDate" : result?.document?.expires ? moment(result?.document?.expires, 'YYYY-MM-DD').utc().toISOString() : ''
                        };
                        await bundeeRestApi.verifyLicenseStatusBundee(bundeeData);
                        // Consider the verification process completed
                        console.log('Verification successful');
                        setIsThankYou(true);
                        showToast('Verification completed successfully!', 'success');
                    } else {
                        showToast('We are unable to verify your license, please try again.', 'error', 5000);
                        showToast('Please make sure the pictures are clear and all the information is visible.', 'info', 7000);
                        idvcInstance.resetAllSteps();
                        setIsLoading(false);
                        idvcInstance.showSpinner(false);
                        return;
                    }

                    idvcInstance.showSpinner(false);
                    setIsLoading(false);
                    document.getElementById('videoCapturingEl')!.style.display = 'none';
                } catch (err) {
                    idvcInstance.showSpinner(false);
                    idvcInstance.resetAllSteps();
                    setIsLoading(false);
                    console.error('Fetch error:', err);
                    showToast('An error occurred while processing your verification request. Please try again.', 'error');
                }
            },
        });

        // Add error handling for chunk loading
        window.addEventListener('error', (e) => {
            if (e.message.includes('Loading chunk')) {
                setError('An error occurred while loading the application. Please reload the page.');
            }
        });
    };

    // Hide the top bar
    useEffect(() => {
        let display = "";
        let topPadding = "";
        const topBar = document.getElementById('TopBar');
        const mainComponent = document.getElementById('app-routes-component');
        if (topBar && mainComponent) {
            display = topBar.style.display;
            topBar.style.display = 'none';
            topPadding = mainComponent.style.paddingTop;
            mainComponent.style.paddingTop = '0px';
        }
        // Cleanup event listener on unmount
        return () => {
            if (topBar && mainComponent) {
                topBar.style.display = display;
                mainComponent.style.paddingTop = topPadding;
            }
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!email || !userId) {
                    throw new Error('Email or userId is missing');
                }
                const response = await fluxRestApi.getContactDataByEmail(email);
                if (response?.data?.contact) {
                    if (response.data.contact?.id === userId && response.data.contact?.email === email) {
                        setContactData(response.data);
                        console.log('Contact data fetched successfully');
                    } else {
                        throw new Error('Contact data does not match the user');
                    }
                }
            } catch (error) {
                setErrorFetchingContactData(true);
                console.log("There was an error while trying to get the contact data");
            }
        };
        if (email && userId && !contactData) {
            fetchData();
        }
    }, [email, userId, contactData]);

    useEffect(() => {
        const decodeToken = (token: any, secretKey: any) => {
            try {
                const bytes = CryptoJS.AES.decrypt(token, secretKey);
                const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

                if (!decryptedData.exp) {
                    console.error('Token is missing an expiration date');
                    return null;
                }

                if (decryptedData.exp && new Date().getTime() > decryptedData.exp) {
                    console.error('Token has expired');
                    return null;
                }

                return decryptedData;
            } catch (error) {
                console.error('Error decoding token:', error);
                return null;
            }
        };

        if(token) {
            const decodedToken = decodeToken(token, secretKey);
            if (decodedToken) {
                setEmail(decodedToken.email);
                setUserId(decodedToken.userId);
            }
        }
    }, [token]);


    const styles: { [key: string]: CSSProperties } = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: isProcessStarted ? '5px' : '20px',
            height: '100vh', // Ensures the container takes the full viewport height
            backgroundColor: '#f0f2f5',
            overflowY: isProcessStarted ? 'auto' : 'hidden',
            overflowX: 'hidden',
        },
        videoContainer: {
            width: '100%',
            maxWidth: '500px',
            height: isProcessStarted ? '100%' : '300px', // Adjust height based on process state
            margin: '20px 0',
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            position: 'relative',
            overflow: 'hidden', // Ensure content does not overflow
        },
        imageLicense: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '10px',
            display: isProcessStarted ? 'none' : 'block',
        },
        button: {
            width: '60%',
            padding: '10px',
            marginTop: '10px',
            marginBottom: '10px',
            border: 'none',
            borderRadius: '5px',
            fontSize: '1em',
            cursor: 'pointer',
            backgroundColor: '#C7F100',
            color: 'black',
            transition: 'background-color 0.3s ease',
        },
        buttonHover: {
            backgroundColor: '#b2d700',
        },
        cancelButton: {
            padding: '10px 10px',
            marginTop: '10px',
            marginBottom: '10px',
            border: 'none',
            borderRadius: '5px',
            fontSize: '12px',
            cursor: 'pointer',
            backgroundColor: '#ff4d4d',
            color: 'white',
            transition: 'background-color 0.3s ease',
            position: 'absolute',
            top: '10px',
            right: '10px',
        },
        cancelButtonHover: {
            backgroundColor: '#ff1a1a',
        },
        error: {
            color: 'red',
            marginTop: '10px',
        },
        instructions: {
            marginTop: '20px',
            fontSize: '1em',
            color: '#555',
            textAlign: 'center',
        },
        loading: {
            fontSize: '1.5em',
            color: '#555',
            marginTop: '20px',
        },
        thankYou: {
            fontSize: '18px',
            backgroundColor: '#C7F100',
            color: 'black',
            borderRadius: '5px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            padding: '20px',
            marginTop: '10px',
        },
    };

    // If the email and userId are not present in the URL, show an error message
    if (!email || !userId || errorFetchingContactData) {
        return (
            <div style={styles.container}>
                <h3>Error</h3>
                <div style={styles.instructions}>
                    <p>Sorry, the verification process cannot be started. Please contact support for further assistance.</p>
                </div>
            </div>
        );
    }

    return (
        <div style={styles.container}>
            {!isProcessStarted && (
                <>
                    <h3>License Verification</h3>
                    <div style={styles.instructions}>
                        <p>You are able to start the validation process. Grab your license and activate the permissions for the camera.</p>
                    </div>
                </>
            )}
            <div id="videoCapturingEl" style={styles.videoContainer}>
                <img src={License} alt="license" style={styles.imageLicense} />
            </div>
            {!isProcessStarted && !isLoading && !isThankYou && (
                <button
                    onClick={startIDVCProcess}
                    onMouseEnter={handleButtonMouseEnter}
                    onMouseLeave={handleButtonMouseLeave}
                    style={{ ...styles.button, ...(isButtonHovered && styles.buttonHover) }}
                >
                    Start License Validation
                </button>
            )}
            {isLoading && <div style={styles.loading}>Loading...</div>}
            {isThankYou && <div style={styles.thankYou}>Completed, please go back to the Flux app.</div>}
            {error && <div style={styles.error}>{error}</div>}
        </div>
    );
};

export default VerifyLicenseMobileFluxApp;
