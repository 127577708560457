// ChatContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';
import ChatModal from '../components/chat/ChatModal';

interface ChatContextType {
    openChat: () => void;
    closeChat: () => void;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

interface ChatProviderProps {
    children: ReactNode;
}

export const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
    const [openChat, setOpenChat] = useState(false);

    const openChatHandler = () => {
        setOpenChat(true)
    };
    const closeChatHandler = () => setOpenChat(false);

    return (
        <ChatContext.Provider value={{ openChat: openChatHandler, closeChat: closeChatHandler }}>
            {children}
            <ChatModal isOpen={openChat} onClose={closeChatHandler} />
        </ChatContext.Provider>
    );
};

export const useChat = (): ChatContextType => {
    const context = useContext(ChatContext);
    if (context === undefined) {
        throw new Error('useChat must be used within a ChatProvider');
    }
    return context;
};
