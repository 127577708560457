import React, { useState, CSSProperties, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// components
import Loader from '../components/Loader';
import ChargingStationDetail from '../components/stations/ChargingStationDetail';
import ModalContactSupport from '../components/ModalContactSupport';
import ModalHelpNewUser from '../components/help/ModalHelpNewUser';
import ModalChargingStationDetail from '../components/ModalChargingStationDetail';
import NewMap from './NewMap';
import ModalTripReminder from '../components/ModalTripReminder';
// hooks
import { useApiStations } from '../hooks/useApiStations';
import { useIsMobile } from '../hooks/useIsMobile';
import { useAuth } from '../Context/AuthContext';
import { useBar } from '../Context/BarContext';
// other
// import CryptoJS from 'crypto-js';
import bundeeRestApi from '../services/BundeeRestApi';
import stylesCharginStations from '../styles/ChargingStations.module.css';

function ChargingStations() {
    const location = useLocation();
    const isMobile = useIsMobile();
    const { user, contactData } = useAuth();
    const { openBar, closeBar, isBarOpen } = useBar();
    const navigate = useNavigate();
    // states
    const [selectedFeature, setSelectedFeature] = useState(null);
    const [switchContainer, setSwitchContainer] = useState<'car' | 'station'>('station');
    const [carType, setCarType] = useState<'car-ev' | 'car-nev' | 'car'>('car');
    // states moldal ModalHelpNewUser
    const [showModalHelpNewUser, setShowModalHelpNewUser] = useState<boolean>(false);
    // states modal Trip reminder
    const [showModalTripReminder, setShowModalTripReminder] = useState(false);

    // Extract query parameters from URL
    const params = new URLSearchParams(location.search);
    const bookType = params.get('book');

    // Check if user has already seen the modal
    useEffect(() => {
        if (user) {
            const hasSeenModalHelpNewUser = localStorage.getItem('hasSeenModalHelpNewUser');
            if (hasSeenModalHelpNewUser) {
                setShowModalHelpNewUser(false);
            } else {
                setShowModalHelpNewUser(true);
            }
        } else {
            setShowModalHelpNewUser(false);
        }

    }, [user]);

    // set carType and switchContainer based on bookType
    useEffect(() => {
        let newCarType = 'car';
        let newSwitchContainer = 'station';

        switch (bookType) {
            case 'car-ev':
                newCarType = 'car-ev';
                newSwitchContainer = 'car';
                break;
            case 'car-nev':
                newCarType = 'car-nev';
                newSwitchContainer = 'car'
                break;
            case 'car':
                newCarType = 'car';
                newSwitchContainer = 'car';
                break;
            // default case remains the same
            default:
                newCarType = 'car';
                newSwitchContainer = 'station';
        }

        setCarType(newCarType as 'car' | 'car-ev' | 'car-nev');
        setSwitchContainer(newSwitchContainer as 'car' | 'station');
    }, [bookType]);

    // hook to get data from RTDB
    const { dataset, loading, error } = useApiStations(carType, user);

    // funtions
    const handleFeatureClick = (feature: any) => {
        setSelectedFeature(feature);
    };

    const closeModalHelpNewUser = () => {
        localStorage.setItem('hasSeenModalHelpNewUser', 'true');
        setShowModalHelpNewUser(false)
    };

    const navigateToTrips = () => {
        const today = new Date().toISOString().split('T')[0];
        localStorage.setItem('tripReminderDismissedDate', today);
        navigate('/trips');
        setShowModalTripReminder(false);
    };

    const closeModalTripReminder = () => {
        const today = new Date().toISOString().split('T')[0];
        localStorage.setItem('tripReminderDismissedDate', today);
        setShowModalTripReminder(false);
    };

    // Check if the user has relevant trips
    useEffect(() => {
        if (user && contactData) {
            const dismissedDate = localStorage.getItem('tripReminderDismissedDate');
            const today = new Date().toISOString().split('T')[0];

            if (dismissedDate === today) {
                // User has dismissed the modal today
                return;
            }

            const checkUserTrips = async () => {
                try {
                    const bundee_user_id = contactData?.contact?.bundee_user_id;
                    if (!bundee_user_id) return;
                    let startTime = '';
                    let endTime = '';

                    const response = await bundeeRestApi.getAllTripDetailsFromValue('userId', bundee_user_id, startTime, endTime);

                    if (response?.data?.errorCode === '0') {
                        const trips = response.data.activetripresponse || [];
                        const relevantStatuses = ['REREQ', 'REAPP', 'TRSTR'];
                        const filteredTrips = trips.filter((trip: any) => relevantStatuses.includes(trip.statusCode));

                        if (filteredTrips.length > 0) {
                            setShowModalTripReminder(true);
                        }
                    }
                } catch (error) {
                    console.error('Failed to fetch trips:', error);
                }
            };

            checkUserTrips();
        }
    }, [user, contactData]);

    // render
    if (loading) return <Loader loading={loading} />;
    if (error) {
        console.error(error.message); // Log the raw error for debugging
        return (
            <div style={styles.errorContainer as CSSProperties}>
                <h2>Oops! Something went wrong.</h2>
                <p>We're sorry for the inconvenience. Please try refreshing the page or come back later.</p>
                <button onClick={() => window.location.reload()} style={styles.refreshButton as CSSProperties}>Refresh</button>
            </div>
        );
    }


    return (
        <div className={stylesCharginStations.page}>
            <div style={styles.container(isMobile)}>
                <ModalTripReminder
                    showModal={showModalTripReminder}
                    closeModal={closeModalTripReminder}
                    navigateToTrips={navigateToTrips}
                    isMobile={isMobile}
                />
                <ModalHelpNewUser showModal={showModalHelpNewUser} closeModal={closeModalHelpNewUser} isMobile={isMobile} />
                {/* Modal to conctac user */}
                <ModalContactSupport />
                {/* Charging Stations */}
                <div className={stylesCharginStations.header}>
                    <h2 className={stylesCharginStations.headerTitle}>Charging Stations</h2>
                    <p className={stylesCharginStations.headerSubtitle}>Find the nearest charging station for your electric vehicle</p>
                    <div className={stylesCharginStations.buttonContainer}>
                        {!isBarOpen && <button
                            className={stylesCharginStations.button}
                            onClick={openBar}>
                            Flux Menu
                        </button>}
                        {isBarOpen && <button
                            className={stylesCharginStations.button}
                            onClick={closeBar}>
                            Close Menu
                        </button>}
                        <button
                            className={stylesCharginStations.button}
                            onClick={() => {
                                navigate('/booking')
                            }}>
                            Book a Car
                        </button>
                        <button
                            className={stylesCharginStations.button}
                            onClick={() => {
                                setShowModalHelpNewUser(true);
                            }}
                        >
                            Map Info
                        </button>
                    </div>
                </div>

                <div style={styles.map}>
                    <NewMap
                        initialLatitude={30.2585795}
                        initialLongitude={-97.749079}
                        initialZoom={12}
                        width={'100%'}
                        height={isMobile ? '80%' : '100%'}
                        dataset={dataset}
                        onFeatureClick={handleFeatureClick}
                        switchContainer={switchContainer}
                        setSwitchContainer={setSwitchContainer}
                        selectedFeature={selectedFeature}
                    />
                </div>
                {selectedFeature && (
                    <ModalChargingStationDetail
                        isOpen={true}
                        onClose={() => setSelectedFeature(null)}
                        isMobile={isMobile}
                    >
                        <ChargingStationDetail
                            feature={selectedFeature}
                            switchContainer={switchContainer}
                            setSwitchContainer={setSwitchContainer}
                        />
                    </ModalChargingStationDetail>
                )}
            </div>
        </div>
    );

}


// Styles
const styles = {
    container: (isMobile: boolean): CSSProperties => ({
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'column',
        backgroundColor: '#f8f8f8',
        width: isMobile ? '100vw' : '80vw',
        height: isMobile ? '80vh' : '75vh',
        margin: '30px',
    }),
    detail: (selectedFeature: any, isMobile: boolean): CSSProperties => ({
        flex: selectedFeature ? isMobile ? 0.5 : 0.7 : 0,
        transition: 'flex 0.5s',
        overflow: 'auto',
        display: selectedFeature || !isMobile ? 'block' : 'none',
    }),
    map: {
        flex: 1,
        transition: 'flex 0.5s',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
    },
    errorContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        backgroundColor: '#f8f8f8',
        textAlign: 'center',
        padding: '20px'
    },
    refreshButton: {
        marginTop: '20px',
        padding: '10px 20px',
        fontSize: '16px',
        backgroundColor: '#333',
        color: 'white',
        border: 'none',
        borderRadius: '15px',
        cursor: 'pointer',
    },
}





export default ChargingStations;
