import { useState, useEffect } from 'react';
import { rtdbFleetCars, rtdbStations, rtdbAvailableData } from '../firebase/firebase';
import { ref, off, onValue } from '@firebase/database';

export function useApiStations(carType: 'car-ev' | 'car-nev' | 'car' = 'car', user: any) {

    const [dataset, setDataset] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const availabilityRef = ref(rtdbAvailableData, '/');
        const carDataRef = ref(rtdbFleetCars, '/');
        const stationDataRef = ref(rtdbStations, '/');

        const fetchStaticData = async () => {
            try {
                onValue(stationDataRef, (snapshot) => {
                    const stationsDataset = snapshot.val();
                    if (stationsDataset) {
                        onValue(carDataRef, (carDataSnapshot) => {
                            const carData = carDataSnapshot.val();
                            if (!carData) {
                                throw new Error('Invalid data received on carData');
                            }
                            onValue(availabilityRef, (availabilitySnapshot) => {
                                const availabilityRTDB = availabilitySnapshot.val();
                                if (!availabilityRTDB) {
                                    throw new Error('Invalid data received on availabilityRTDB');
                                }
                                processData(stationsDataset, availabilityRTDB, carData);
                            });
                        });
                    } else {
                        throw new Error('No data');
                    }
                });
            } catch (err: any) {
                setError(err);
                setDataset(null);
                setLoading(false);
                return null;
            }
        };

        const merge = async (stationsDataset: any, availabilityRTDB: any) => {
            return {
                ...stationsDataset,
                features: stationsDataset.features.map((feature: any) => {
                    const rtdbItem = availabilityRTDB[feature?.properties?.places_id];

                    if (feature?.properties?.status === 'down / off') {
                        return {
                            ...feature,
                            properties: {
                                ...feature.properties,
                                status: 'down / off',
                                availableData: "",
                            },
                        };
                    };

                    if (!rtdbItem) {
                        return {
                            ...feature,
                            properties: {
                                ...feature.properties,
                                status: 'unknown',
                                availableData: "",
                            },
                        };
                    };

                    const {
                        available,
                        unknown,
                        occupied,
                        reserved,
                        outOfService,
                        timestamp
                    } = rtdbItem;

                    if (available === 0) {
                        return {
                            ...feature,
                            properties: {
                                ...feature.properties,
                                status: 'unknown',
                                availableData: {
                                    "available": available,
                                    "unknown": unknown,
                                    "occupied": occupied,
                                    "reserved": reserved,
                                    "outOfService": outOfService,
                                    "timestamp": timestamp
                                },
                            },
                        };
                    }

                    if (
                        available > 0
                    ) {
                        return {
                            ...feature,
                            properties: {
                                ...feature.properties,
                                status: 'active',
                                availableData: {
                                    "available": available,
                                    "unknown": unknown,
                                    "occupied": occupied,
                                    "reserved": reserved,
                                    "outOfService": outOfService,
                                    "timestamp": timestamp
                                },
                            }
                        };
                    }

                    return {
                        ...feature,
                        properties: {
                            ...feature.properties,
                            status: 'unknown',
                            availableData: {
                                "available": available,
                                "unknown": unknown,
                                "occupied": occupied,
                                "reserved": reserved,
                                "outOfService": outOfService,
                                "timestamp": timestamp
                            },
                        }
                    };
                }),
            };
        };

        const processData = async(stationsDataset: any, availabilityRTDB: any, carData: any) => {
            const mergedData = await merge(stationsDataset, availabilityRTDB);
            // const mergedData = stationsDataset;
            if (carData) {
                console.log('processData');
                const updatedFeatures = mergedData.features.map((station: any) => {
                    const places_id = station?.properties?.places_id;
                    const EV_MODELS = ['Bolt'];
                    const NEV_MODELS = ['500e'];

                    const carsForStation = carData.filter((car: any) => {
                        const isEV = EV_MODELS.includes(car?.Model);
                        const isNEV = NEV_MODELS.includes(car?.Model);
                        const isCarTypeMatch = carType === 'car' ||
                            (carType === 'car-ev' && isEV) ||
                            (carType === 'car-nev' && isNEV);


                        // return poiId === car?.POI_ID && car?.vehicleid && car?.BundeeActive && isCarTypeMatch;
                        return places_id === car?.places_id && car?.BundeeActive && car?.vehicleid && isCarTypeMatch;
                    });

                    return {
                        ...station,
                        properties: {
                            ...station.properties,
                            cars: carsForStation,
                            numCars: carsForStation.length,
                        },
                    };
                });

                mergedData.features = updatedFeatures;
            }
            setDataset(mergedData);
            setLoading(false);
        };

        fetchStaticData();

        // Clean up the subscriptions when the component is unmounted
        return () => {
            off(carDataRef);  // Detach the listener for car data
            off(availabilityRef);  // Detach the listener for availability data
            off(stationDataRef);  // Detach the listener for station data
        };

    }, [user, carType]);

    return { dataset, loading, error };
}
