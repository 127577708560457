import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// assets
import SplashScreen from '../images/banners/banner-drive-electric.png';
import authStyles from '../styles/authStyles';
// hooks
import { useIsMobile, useAnimateOnDesktop } from '../hooks/useIsMobile';
// utils
import showToast from '../utils/toastHelpers';
import { authHelpers } from '../utils/authHelpers';
// components
import Loader from '../components/Loader';
import SplashImage from '../components/auth/SplashImage';
// services
import fluxRestApi from '../services/FluxRestApi';
// context
import { useAuth } from '../Context/AuthContext';

const SignUp: React.FC = () => {
    const isMobile = useIsMobile();
    const shouldAnimate = useAnimateOnDesktop(isMobile);
    const navigate = useNavigate();
    // context
    const { authSignUp } = useAuth();

    // states
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({
        email: '',
        password: '',
        phoneNumber: '',
        confirmPassword: ''
    });
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [phoneNumberCode, setPhoneNumberCode] = useState('');
    const [confirmPhoneNumberCode, setConfirmPhoneNumberCode] = useState('');
    enum SignUpStep {
        SIGNUP_FORM,
        PHONE_CONFIRMATION,
        EMAIL_CONFIRMATION
    }
    const [currentStep, setCurrentStep] = useState(SignUpStep.SIGNUP_FORM);
    // check boxes
    const [marketingSms, setMarketingSms] = useState(false);
    const [accountUpdateSms, setAccountUpdateSms] = useState(false);


    // funtions
    const validateForm = () => {
        const { phoneNumber, email, password, confirmPassword } = userDetails;

        if (!authHelpers.isValidPhoneNumber(phoneNumber)) {
            showToast("Invalid USA Phone Number", "error");
            return false;
        }

        if (!authHelpers.isValidEmail(email)) {
            showToast("Invalid Email!", "error");
            return false;
        }

        if (!authHelpers.isValidPassword(password)) {
            showToast("Invalid Password!", "error");
            return false;
        }

        if (password !== confirmPassword) {
            showToast("Passwords do not match!", "error");
            return false;
        }

        return true;
    }

    const checkPhoneNumber = async (phoneNumber: string) => {
        // check the phone number is already registered
        let phone =  "1" + phoneNumber
        const isPhoneNumberRegistered = await fluxRestApi.checkIfUserExistsByPhone(phone);
        if (isPhoneNumberRegistered?.data?.user_exists) throw new Error('The phone number is already registered!');
        if (isPhoneNumberRegistered === null) throw new Error('Failed to check the phone number!, please try again.');
        return true;
    }

    const checkEmail = async (email: string) => {
        try {
            const isEmailRegistered = await fluxRestApi.checkIfUserExistsByEmail(email);

            if (isEmailRegistered?.data?.user_exists) {
                throw new Error('The email is already registered!');
            }

            return true;
        } catch (error: any) {
            if (error?.response && error?.response?.status === 404) {
                // This means the email is not found, which is good in this context.
                return true;
            } else if(error?.message === 'The email is already registered!') {
                throw new Error('The email is already registered!');
            }
            throw new Error('Failed to check the email!, please try again.');
        }
    }


    const handleFormSubmit = (e: React.FormEvent) => {
        // prevent default form submission
        e.preventDefault();
        handleSignUp();
    }

    const handleSignUp = async () => {
        // Validation logic
        if (!validateForm()) return;

        const { phoneNumber, email } = userDetails;

        setLoading(true);
        try {
            await checkPhoneNumber(phoneNumber);
            await checkEmail(email);
            await verifyPhoneNumber(phoneNumber);
        } catch (error: any) {
            showToast(error.message, "error");
        } finally {
            setLoading(false);
        }
    }

    const verifyPhoneNumber = async (phoneNumber: string) => {
        const phone = "1" + phoneNumber
        // verify the phone number
        const data = {
            phone: phone,
        }
        const getPhoneNumberCode = await fluxRestApi.getVerificationCode(data);
        if (getPhoneNumberCode?.data) {
            setPhoneNumberCode(getPhoneNumberCode.data);
            setCurrentStep(SignUpStep.PHONE_CONFIRMATION);
            showToast("Verification code sent", "success");
        } else {
            throw new Error('Failed to send verification code!, please try again.');
        }
    }

    const handleConfirmationPhone = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        const phone = "+1" + userDetails.phoneNumber;
        if (confirmPhoneNumberCode === phoneNumberCode) {
            const verifiedPhone = true;
            const { success, message } = await authSignUp(userDetails.email, userDetails.password, phone, marketingSms, accountUpdateSms, verifiedPhone);
            if (success) {
                showToast(message, "success");
                setCurrentStep(SignUpStep.EMAIL_CONFIRMATION);
                setLoading(false);
            } else {
                showToast(message, "error");
                setLoading(false);
            }
        } else {
            showToast("Invalid Code!", "error");
            setLoading(false);
        }
    }

    return (
        <div style={authStyles.commonStyles.container}>
            <Loader loading={loading} />
            <SplashImage isMobile={isMobile} animate={shouldAnimate} SplashScreen={SplashScreen} />

            {currentStep === SignUpStep.SIGNUP_FORM &&
                <SignUpForm
                    isMobile={isMobile}
                    email={userDetails.email}
                    setEmail={(value) => setUserDetails({ ...userDetails, email: value })}
                    phoneNumber={userDetails.phoneNumber}
                    setPhoneNumber={(value) => setUserDetails({ ...userDetails, phoneNumber: value })}
                    password={userDetails.password}
                    setPassword={(value) => setUserDetails({ ...userDetails, password: value })}
                    confirmPassword={userDetails.confirmPassword}
                    setConfirmPassword={(value) => setUserDetails({ ...userDetails, confirmPassword: value })}
                    animate={shouldAnimate}
                    isPasswordVisible={isPasswordVisible}
                    setIsPasswordVisible={setIsPasswordVisible}
                    handleFormSubmit={handleFormSubmit}
                    marketingSms={marketingSms}
                    setMarketingSms={setMarketingSms}
                    accountUpdateSms={accountUpdateSms}
                    setAccountUpdateSms={setAccountUpdateSms}
                />
            }

            {currentStep === SignUpStep.PHONE_CONFIRMATION &&
                <ConfirmationCode
                    isMobile={isMobile}
                    animate={shouldAnimate}
                    confirmPhoneNumberCode={confirmPhoneNumberCode}
                    setConfirmPhoneNumberCode={setConfirmPhoneNumberCode}
                    handleConfirmationPhone={handleConfirmationPhone}
                />}

            {currentStep === SignUpStep.EMAIL_CONFIRMATION &&
                <ConfirmEmail
                    isMobile={isMobile}
                    animate={shouldAnimate}
                    navigate={navigate}
                />}
        </div>
    );
}

interface SignUpFormProps {
    phoneNumber: string;
    setPhoneNumber: (value: string) => void;
    email: string;
    setEmail: (value: string) => void;
    password: string;
    setPassword: (value: string) => void;
    confirmPassword: string;
    setConfirmPassword: (value: string) => void;
    animate: boolean;
    isPasswordVisible: boolean;
    setIsPasswordVisible: (value: boolean) => void;
    isMobile: boolean;
    handleFormSubmit: (e: React.FormEvent) => void;
    marketingSms: boolean;
    setMarketingSms: (value: boolean) => void;
    accountUpdateSms: boolean;
    setAccountUpdateSms: (value: boolean) => void;
}

const SignUpForm: React.FC<SignUpFormProps> = ({
    phoneNumber, setPhoneNumber, email, setEmail, password, setPassword, confirmPassword, setConfirmPassword,
    animate, isPasswordVisible, setIsPasswordVisible, isMobile, handleFormSubmit, marketingSms, setMarketingSms, accountUpdateSms, setAccountUpdateSms
}) => {
    const navigate = useNavigate();
    return (
        <div style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            transition: 'transform 0.5s ease-in-out',
            maxHeight: '100vh',
            transform: isMobile ? 'translateX(0%)' : (animate ? 'translateX(0%)' : 'translateX(-100%)')}}>
                <div style={{height: isMobile ? 'calc(100vh - 67px)' : 'calc(100vh - 77px)', overflowY: 'auto'}}>
                    <h2 style={authStyles.commonStyles.center}>Sign Up</h2>
                    <form onSubmit={handleFormSubmit} style={authStyles.commonStyles.formLogin}>
                        <div style={{ display: 'flex', alignItems: 'center', width: isMobile ? '95%' : 'auto' }}>
                            <span style={{ marginRight: '10px', border: '1px solid #ccc', padding: '8px', borderRadius: '5px' }} >
                                +1
                            </span>
                            <input
                                type="tel"
                                name="phoneNumber"
                                placeholder="Phone Number"
                                style={isMobile ? { ...authStyles.commonStyles.input, width: '100%', fontSize: 20 } : { ...authStyles.commonStyles.input, width: '100%' }}
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                        </div>
                        <input
                            type="email"
                            name="email"
                            autoComplete='on'
                            placeholder="Email"
                            style={isMobile ? {...authStyles.commonStyles.input, width: '90%', fontSize: 20} : authStyles.commonStyles.input}
                            value={email}
                            onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
                        />
                        <div style={isMobile ? {...authStyles.commonStyles.inputWrapper, width: '95%'} : authStyles.commonStyles.inputWrapper}>
                            <input
                                type={isPasswordVisible ? "text" : "password"}
                                name="password"
                                autoComplete="new-password"
                                placeholder="Password"
                                style={isMobile ?{ ...authStyles.commonStyles.input, width: '100%', fontSize: 22 } : { ...authStyles.commonStyles.input, width: '100%' }}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <span
                                style={authStyles.commonStyles.eyeIcon}
                                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                            >👁</span>
                        </div>
                        <div style={isMobile ? {...authStyles.commonStyles.inputWrapper, width: '95%'}: authStyles.commonStyles.inputWrapper}>
                            <input
                                type={isPasswordVisible ? "text" : "password"}
                                name="confirmPassword"
                                autoComplete="new-password"
                                placeholder="Confirm Password"
                                style={isMobile ?{ ...authStyles.commonStyles.input, width: '100%', fontSize: 22 } : { ...authStyles.commonStyles.input, width: '100%' }}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            <span
                                style={authStyles.commonStyles.eyeIcon}
                                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                            >👁</span>
                        </div>
                        <div style={isMobile ? {...authStyles.commonStyles.inputWrapper, width: '95%'}: authStyles.commonStyles.inputWrapper}>
                            <label style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                                <input
                                    type="checkbox"
                                    name="marketingSms"
                                    checked={marketingSms}
                                    onChange={(e) => setMarketingSms(e.target.checked)}
                                />
                                <span
                                    style={{ width: '100%', marginLeft: '10px' }}
                                >
                                    I agree to receive marketing SMS messages from Flux
                                </span>
                            </label>
                        </div>
                        <div style={isMobile ? {...authStyles.commonStyles.inputWrapper, width: '95%'}: authStyles.commonStyles.inputWrapper}>
                            <label style={{ display: 'flex', alignItems: 'center', marginTop: '10px', marginBottom: '20PX' }}>
                                <input
                                    type="checkbox"
                                    name="accountUpdateSms"
                                    checked={accountUpdateSms}
                                    onChange={(e) => setAccountUpdateSms(e.target.checked)}
                                />
                                <span
                                    style={{ width: '100%', marginLeft: '10px' }}
                                >
                                    I agree to receive account update SMS messages from Flux
                                </span>
                            </label>
                        </div>

                        <div style={isMobile ? {...authStyles.commonStyles.inputWrapper, width: '95%'}: authStyles.commonStyles.inputWrapper}>
                            <p>
                                By selecting the checkboxes above agree to receive text messages from Flux to the phone number providedMessage and data rates may apply. Message frequency variesText HELP for helpText STOP to stop.
                                <br />
                                <br />
                                By signing up, I agree to Flux's <span>
                                    <a href="https://www.fluxev.city/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="https://www.fluxev.city/tos" target="_blank" rel="noopener noreferrer">Terms of Service</a>
                                </span>
                            </p>
                        </div>


                        <div style={{ ...authStyles.commonStyles.center, ...authStyles.commonStyles.buttonContent }}>
                            <button type="submit" className="buttonBlack">Sign Up</button>
                        </div>

                        <p
                            onClick={() => {
                                navigate("/login")
                            }}
                            style={authStyles.commonStyles.left}
                        >
                            Log In
                        </p>
                        <p onClick={() => navigate("/")} style={authStyles.commonStyles.left}>Charging Station</p>
                    </form>
                    {isMobile && <div style={{ height: '130px', width: '100%'}}></div>}
                </div>
        </div>
    )
}

interface ConfirmationCodeProps {
    isMobile: boolean;
    animate: boolean;
    confirmPhoneNumberCode: string;
    setConfirmPhoneNumberCode: (value: string) => void;
    handleConfirmationPhone: (e: React.FormEvent) => void;

}

const ConfirmationCode: React.FC<ConfirmationCodeProps> = ({
    confirmPhoneNumberCode, setConfirmPhoneNumberCode, handleConfirmationPhone, isMobile, animate
}) => {

    // styles
    const inputStyles = {
        ...authStyles.commonStyles.input,
        width: isMobile ? '90%' : authStyles.commonStyles.input.width,
        fontSize: isMobile ? '24px' : authStyles.commonStyles.input.fontSize,
    };

    const formPartStyles = {
        ...authStyles.commonStyles.formPart,
        justifyContent: isMobile ? 'flex-start' : authStyles.commonStyles.formPart.justifyContent,
        transform: isMobile ? 'translateX(0%)' : (animate ? 'translateX(0%)' : 'translateX(-100%)'),
        paddingBottom: isMobile ? '2rem' : 0,
    };

    return (
        <div style={formPartStyles}>
            <h2 style={authStyles.commonStyles.center}>Verify your phone number</h2>
            <p style={{...authStyles.commonStyles.center, fontSize: '14px'}}>We'll send your authorization code to <br />your email when our SMS provider is down</p>
            <form onSubmit={handleConfirmationPhone} style={authStyles.commonStyles.formLogin}>
                <input
                    type="text"
                    name="code"
                    placeholder="Verification Code"
                    style={inputStyles}
                    value={confirmPhoneNumberCode}
                    onChange={(e) => setConfirmPhoneNumberCode(e.target.value)}
                />
                <div style={{ ...authStyles.commonStyles.center, ...authStyles.commonStyles.buttonContent }}>
                    <button type="submit" className="buttonBlack">Confirm</button>
                </div>
            </form>
        </div>
    )
}

interface ConfirmEmailProps {
    isMobile: boolean;
    animate: boolean;
    navigate: (path: string) => void;
}

const ConfirmEmail: React.FC<ConfirmEmailProps> = ({ isMobile, animate, navigate  }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            navigate("/login");
        }, 5000);
        return () => clearTimeout(timer);
    }, [navigate, isMobile]);

    // styles
    const formPartStyles = {
        ...authStyles.commonStyles.formPart,
        justifyContent: isMobile ? 'flex-start' : authStyles.commonStyles.formPart.justifyContent,
        transform: isMobile ? 'translateX(0%)' : (animate ? 'translateX(0%)' : 'translateX(-100%)'),
        paddingBottom: isMobile ? '2rem' : 0,
    };

    return (
        <div style={formPartStyles}>
            <h2 style={authStyles.commonStyles.center}>Email Confirmation</h2>
            <p style={{ textAlign: 'center', width: '80%' }}>
                Please check your email to verify your account. If you don't see the email, check other places it might be, like your junk, spam, social, or other folders.
            </p>
        </div>
    )
}



export default SignUp;
