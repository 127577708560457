import React, { CSSProperties, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// assets
import Menu_Header from '../images/Menu_Header.png';
// context
import { useAuth } from '../Context/AuthContext';
import showToast from '../utils/toastHelpers';
import { useChat } from '../Context/ChatContext';
import { useBar } from '../Context/BarContext';

interface SidebarProps {
    onClose: () => void;
    handleLogout: () => void;
    isMobile: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ onClose, handleLogout, isMobile }) => {
    const navigate = useNavigate();
    const sidebarRef = useRef<HTMLDivElement>(null);
    const { isBarOpen, closeBar } = useBar();
    const { user } = useAuth();
    // chat
    const { openChat } = useChat();

    const handleNavigation = (path: string) => {
        if (path === "/workingonit") return showToast("This feature is still being worked on", "info");
        navigate(path);
        onClose();
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const menuIconElement = document.getElementById('menu-icon');
            if (
                sidebarRef.current &&
                !sidebarRef.current.contains(event.target as Node) &&
                menuIconElement &&
                !menuIconElement.contains(event.target as Node)
            ) {
                closeBar();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [closeBar]);

    const styles: { [key: string]: CSSProperties } = {
        container: {
            position: 'fixed',
            top: isMobile? '67px':'77px',
            left: 0,
            height: isMobile ? 'calc(100% - 67px)' : 'calc(100% - 77px)',
            width: isMobile ? '100%' :'250px',
            backgroundColor: 'white',
            overflowY: 'auto',
            zIndex: 1500,
        },
        list: {
            listStyleType: 'none',
            padding: 0,
            margin: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontSize: '14px',
        },
        listItem: {
            cursor: 'pointer',
            padding: '10px 0',
        },
        logoContainer: {
            widows: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            marginBottom: '20px',
        },
        logo: {
            width: '100%',
            height: isMobile ? '100px' : 'auto',
            objectFit: isMobile ? 'contain' : 'cover',
        },
        logoutContainer: {
            position: 'absolute',
            bottom: 50,
            width: '100%',
            textAlign: 'center',
        },
        logoutItem: {
            cursor: 'pointer',
            padding: '10px 0',
            fontWeight: 'bold',
            font: '12px'
        },
        closeButtonContainer: {
            position: 'absolute',
            top: 0,
            right: 0,
        },
        closeButton: {
            background: 'none',
            border: 'none',
            fontSize: '24px',
            cursor: 'pointer',
            lineHeight: '1',
            padding: '10px',
        },
    };

    // Only render the sidebar if it's open
    if (!isBarOpen) return null;


    return (
        <div style={styles.container} ref={sidebarRef}>
            {isMobile && (
                <div style={styles.closeButtonContainer}>
                    <button style={styles.closeButton} onClick={onClose}>×</button>
                </div>
            )}
            <div style={styles.logoContainer}>
                <img src={Menu_Header} alt="Logo" style={styles.logo} />
            </div>
            <ul style={styles.list}>

                <li style={styles.listItem} onClick={() => handleNavigation("/")}>Map</li>

                {
                    user ?
                        <>
                            <li style={styles.listItem} onClick={() => handleNavigation("/account")}>Account</li>
                            <li style={styles.listItem} onClick={() => handleNavigation("/booking")}>Book a Car</li>
                            <li style={styles.listItem} onClick={() => handleNavigation("/trips")}>My Trips</li>
                            <li style={styles.listItem} onClick={() => window.open("https://www.fluxev.city/help#hs_cos_wrapper_main_content-module-2", "_blank")}>
                                FAQs
                            </li>
                            <li style={styles.listItem} onClick={() => window.open("https://www.fluxev.city/tos", "_blank")}>
                                Terms & Conditions
                            </li>
                            <li style={styles.listItem} onClick={() => window.open("https://www.fluxev.city/privacy-policy", "_blank")}>
                                Privacy Policy
                            </li>
                            <li style={styles.listItem} onClick={openChat}>Chat With Us</li>
                        </>
                        :
                        <>
                            <li style={styles.listItem} onClick={() => handleNavigation("/signup")}>Sign Up</li>
                            <li style={styles.listItem} onClick={() => handleNavigation("/login")}>Login</li>
                            <li style={styles.listItem} onClick={() => handleNavigation("/booking")}>Book a Car</li>
                        </>

                }
            </ul>
            {
                user && isMobile && (
                    <div style={styles.logoutContainer}>
                        <ul style={styles.list}>
                            <li style={{ ...styles.listItem, ...styles.logoutItem }} onClick={() => {
                                handleLogout();
                                onClose();
                            }}>
                                Log out
                            </li>
                        </ul>
                    </div>
                )
            }
        </div>
    );
}

export default Sidebar;
