import React, { CSSProperties, useState } from 'react';
import showToast from '../../../utils/toastHelpers';
import authStyles from "../../../styles/authStyles";
import fluxRestApi from '../../../services/FluxRestApi';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';

interface CardFormProps {
    isMobile: boolean;
    animate: boolean;
    setShowCardForm: (value: boolean) => void;
    setLoading: (value: boolean) => void;
    loading: boolean;
    stripeId: string;
    stripe: any;
    elements: any;
    fetchCards: () => void;
    cards: any;
}

const CardForm: React.FC<CardFormProps> = ({ cards, fetchCards, isMobile, animate, setShowCardForm, setLoading,loading, stripeId, stripe, elements }) => {
    // styles
    const styles: { [key: string]: CSSProperties } = {
        // modal
        modalOverlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            opacity: 0,
            transition: 'opacity 0.3s',
        },
        modalContent: {
            backgroundColor: 'white',
            padding: '20px',
            maxWidth: '500px',
            width: '80%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '10px',
            transform: 'translateY(50px)',
            opacity: 0,
            transition: 'transform 0.3s, opacity 0.3s',
        },
        closeButton: {
            position: 'absolute',
            right: '10px',
            top: '10px',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            fontSize: '20px'
        },
        modalImage: {
            maxWidth: '50%',
            height: 'auto',
        },
        container: {
            width: '80%',
            padding: '20px',
            boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
            borderRadius: '8px',
            margin: '0 auto',
            backgroundColor: 'white',
        },
        label: {
            fontSize: '14px',
            color: '#555',
            marginBottom: '8px',
            display: 'block',
        },
        cardElementWrapper: {
            border: '1px solid #dcdcdc',
            borderRadius: '4px',
            padding: '8px 12px',
            marginBottom: '20px',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'center',
        },
        cardsMainContainer: {
            maxHeight: '300px',
            width: '70%',
            overflowY: 'auto',
            marginBottom: '20px',
            marginTop: '20px',

        },
        cardsContainer: {
            display: 'flex',
            flexDirection: 'row',
            width: 'auto',
            justifyContent: 'space-around',
            alignItems: 'center',
            border: '1px solid #dcdcdc',
            borderRadius: '4px',
            marginBottom: '10px',
            cursor: 'pointer',
            marginRight: '10px',
            marginLeft: '10px',
        }
    };
    const inputStyles = {
        ...authStyles.commonStyles.input,
        width: isMobile ? '90%' : '96%',
        fontSize: isMobile ? '20px' : authStyles.commonStyles.input.fontSize,
    };
    const formPartStyles = {
        ...authStyles.commonStyles.formPart,
        justifyContent: isMobile ? 'flex-start' : authStyles.commonStyles.formPart.justifyContent,
        transform: isMobile ? 'translateX(0%)' : (animate ? 'translateX(0%)' : 'translateX(-100%)'),
        paddingBottom: isMobile ? '2rem' : 0,
    };
    // states
    const [formData, setFormData] = useState<any>({
        fullName: '',
    });
    // attach payment method to customer
    const attachPaymentMethodToCustomer = async (paymentMethodId: string, customerId: string) => {
        try {
            setLoading(true);
            const data = {
                "payment_method_id": paymentMethodId,
                "customer_id": customerId
            }
            const paymentMethod = await fluxRestApi.attachPaymentMethodToCustomer(data);
            if (paymentMethod) {
                setLoading(false);
                return;
            } else {
                throw new Error('Error attaching payment method');

            }
        } catch (error) {
            setLoading(false);
            console.error('Error attaching payment method:', error);
            throw error;
        }
    };

    // create payment method
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!formData?.fullName) {
            showToast('Please enter your full name.', 'error');
            return;
        }
        // Validate the name for letters, spaces, hyphens, and apostrophes
        if (!/^[a-zA-Z-' ]+$/.test(formData?.fullName)) {
            showToast('Please enter a valid name. Only letters, hyphens, apostrophes, and spaces are allowed.', 'error');
            return;
        }

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            console.log("Stripe.js has not yet loaded.");
            showToast('Something went wrong. Please try again later.', 'error');
            return;
        }

        try {
            setLoading(true);
            const cardNumberElement = elements.getElement(CardNumberElement);

            if (cardNumberElement) {
                // Create PaymentMethod
                const { error, paymentMethod } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardNumberElement,
                    billing_details: {
                        name: formData.fullName,
                    },
                });

                if (error) {
                    console.error(error);
                    showToast('There was an error with your card details. Please check and try again.', 'error');
                    setLoading(false);
                } else if (paymentMethod) {
                    // Get last four digits of existing cards
                    const existingLast4 = cards.map((card: any) => card.last4);

                    // Check if the new card's last four digits match any existing card
                    if (existingLast4.includes(paymentMethod.card.last4)) {
                        // The card is already saved
                        showToast('This card is already added.', 'error');
                        // Optionally, you can detach or delete the new PaymentMethod if it's not attached to a customer
                        setLoading(false);
                        return;
                    }

                    // Attach the PaymentMethod to the customer
                    await attachPaymentMethodToCustomer(paymentMethod.id, stripeId);

                    // Refresh the list of cards
                    fetchCards();

                    setShowCardForm(false);
                    showToast('Card added successfully.', 'success');
                    setLoading(false);
                }
            } else {
                console.log("Card elements are not fully loaded or there's an error.");
                showToast('Something went wrong. Please try again.', 'error');
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
            showToast('Something went wrong. Please try again.', 'error');
        }
    };


    return (
        <div style={formPartStyles}>
            <button style={styles.closeButton} onClick={() => setShowCardForm(false)}>
                &times;
            </button>
            <h2 style={authStyles.commonStyles.center}>Add Card</h2>
            <form name="CreditCardForm" style={authStyles.commonStyles.formLogin} onSubmit={handleSubmit}>
                <div style={{ width: isMobile ? '90%' : '80%', padding: '10px' }}>
                    <label style={{ ...styles.label, display: 'flex', flexDirection: 'column' }}>
                        Full Name:
                        <input
                            type="text"
                            name="fullName"
                            value={formData.fullName}
                            onChange={(e) => setFormData({ ...formData, fullName: e.target.value })}
                            required
                            style={inputStyles}
                            placeholder="Full Name" autoComplete="cc-name"
                        />
                    </label>
                    <label style={styles.label}>
                        Card Number:
                        <div style={styles.cardElementWrapper}>
                            <CardNumberElement options={{ style: { base: { fontSize: isMobile ? '20px' : '16px', color: '#333' } } }} />
                        </div>
                    </label>

                    <label style={styles.label}>
                        Expiration Date:
                        <div style={styles.cardElementWrapper}>
                            <CardExpiryElement options={{ style: { base: { fontSize: isMobile ? '20px' : '16px', color: '#333' } } }} />
                        </div>
                    </label>

                    <label style={styles.label}>
                        CVC:
                        <div style={styles.cardElementWrapper}>
                            <CardCvcElement options={{ style: { base: { fontSize: isMobile ? '20px' : '16px', color: '#333' } } }} />
                        </div>
                    </label>
                </div>
                <div style={{ ...authStyles.commonStyles.center, ...authStyles.commonStyles.buttonContent }}>
                    <button
                        type="submit"
                        className="buttonBlack"
                        disabled={loading}
                    >
                        {!loading ? "Add Card" : "Adding Card..."}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default CardForm;