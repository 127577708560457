import axios, { AxiosInstance } from 'axios';

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || '';



const apiAuthenticated: AxiosInstance = axios.create({
    baseURL: `https://places.googleapis.com/v1/`,
    timeout: 30000,
    headers: {
        'X-Goog-Api-Key': API_KEY,
        'Content-Type': 'application/json',
    }
});

class GoogleNewRestPlacesApi {
    getEvChargingAvailibility = async (placeId: string): Promise<any>  => {
        try {
            const response = await apiAuthenticated.get(`places/${placeId}?fields=id,displayName,evChargeOptions,location,formattedAddress,plusCode`);
            if (response.status !== 200) {
                throw new Error('Error fetching data from Google Places API');
            }
            return response;
        } catch (error) {
            console.error('Error fetching data from Google Places API', error);
            return null;
        }
    }

}

const GoogleNewPlacesApi = new GoogleNewRestPlacesApi();
export default GoogleNewPlacesApi;
