import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// styles
import 'react-lazy-load-image-component/src/effects/blur.css';
import styles from '../styles/reservationSuccessStyles.module.css';
// components
import Loader from '../components/Loader';
// services
import bundeeRestApi from '../services/BundeeRestApi';
// others
import moment from 'moment-timezone';
// utils
import showToast from '../utils/toastHelpers';
// context
import { useAuth } from "../Context/AuthContext";

const ReservationSuccess: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const tripId = queryParams.get('tripId');
    const email = queryParams.get('email');
    const { user } = useAuth();

    const [loading, setLoading] = useState(false);
    const [tripDetails, setTripDetails] = useState<any>(null);

    // functions
    const handleGoTo = () => {
        if (user) {
            navigate('/trips');
        } else {
            navigate('/login');
        }
    }

    // effects
    useEffect(() => {
        const fetchTripDetails = async () => {
            try {
                setLoading(true);
                const response = await bundeeRestApi.getAllTripDetailsFromValue('tripid', tripId, '', '');
                if (response?.data?.errorCode === '0') {
                    if (response?.data?.activetripresponse?.length > 0) {
                        if (email === response.data.activetripresponse[0].userEmail && response.data.activetripresponse[0].statusCode === 'REREQ') {
                            //console.log('Trip details:', response.data.activetripresponse[0]);
                            setTripDetails(response.data.activetripresponse[0]);
                        } else {
                            console.error('Unauthorized access');
                            showToast('Unauthorized access', 'error');
                            navigate('/');
                        }
                    } else {
                        console.error("Failed to fetch trip details");
                        showToast('Trip details not found', 'error');
                        navigate('/');
                    }
                } else {
                    showToast('Trip details not found', 'error');
                    navigate('/');
                }
            } catch (error) {
                console.error('Error fetching trip details:', error);
                showToast('An error occurred, please try again', 'error');
                navigate('/');
            } finally {
                setLoading(false);
            }
        };

        if (tripId && email) {
            fetchTripDetails();
        } else {
            navigate('/');
        }
    }, [tripId, email, navigate]);

    // rendering

    if (loading) {
        return <Loader loading={loading} />;
    }

    if (!tripDetails) {
        return null;
    }

    return (
        <div className={styles.container}>
            <div className={styles.card}>
                <h1 className={styles.title}>🎉 Congratulations!</h1>
                <p className={styles.message}>Your reservation was requested.</p>
                <div className={styles.tripDetails}>
                    <h2 className={styles.subTitle}>Trip Details</h2>
                    <p className={styles.detail}><strong>Car:</strong> {tripDetails.vehicleDetails[0].make} {tripDetails.vehicleDetails[0].model} - {tripDetails.vehicleDetails[0].color}</p>
                    <p className={styles.detail}><strong>From:</strong> {moment(tripDetails.starttime).format('dddd, MMMM Do YYYY, h:mm A')}</p>
                    <p className={styles.detail}><strong>To:</strong> {moment(tripDetails.endtime).format('dddd, MMMM Do YYYY, h:mm A')}</p>
                    <p className={styles.detail}><strong>Location:</strong> {tripDetails.vehicleDetails[0].address1}</p>
                </div>
                <div className={styles.nextSteps}>
                    <h3 className={styles.subTitle}>Next Steps</h3>
                    <ul className={styles.stepsList}>
                        <li className={styles.step}>📝 Please note that the host will need to approve this trip request.</li>
                        {!user && <li className={`${styles.step} ${styles.newUserStep}`}>📧 If you don't have an account with us, one has been created for you after the booking. You will receive an email to verify and set your password. If you don't see the email, please check your junk, spam, social, or other folders.</li>}
                        <li className={styles.step}>📧 Check your email for the reservation confirmation and further instructions.</li>
                        <li className={styles.step}>🆔 License will be verified before to start the trip.</li>
                        <li className={styles.step}>📞 If you have any questions, contact our support team. support@fluxev.city</li>
                    </ul>
                </div>
                <button className={styles.button} onClick={handleGoTo}>{user ? 'Go to Trips' : 'Log In'}</button>
            </div>
        </div>
    );
};

export default ReservationSuccess;
