import React, { useState } from 'react';
//assets
import SplashScreen from '../images/banners/banner-white-car.webp';
import authStyles from '../styles/authStyles';
// hooks
import { useIsMobile, useAnimateOnDesktop } from '../hooks/useIsMobile';
// context
import Loader from '../components/Loader';
import SplashImage from '../components/auth/SplashImage';
// components
import ContactForm from '../components/ContactForm';

const Contact: React.FC = () => {
    const isMobile = useIsMobile();
    const shouldAnimate = useAnimateOnDesktop(isMobile);

    // states
    const [loading, setLoading] = useState(false);

    return (
        <div style={authStyles.commonStyles.container}>
            <Loader loading={loading} />
            <SplashImage isMobile={isMobile} animate={shouldAnimate} SplashScreen={SplashScreen} />
            <ContactForm
                isMobile={isMobile}
                animate={shouldAnimate}
                setLoading={setLoading}
            />
        </div>
    );
}


export default Contact;
