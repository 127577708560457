import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authStyles from '../styles/authStyles';
// utils
import countries from '../utils/countries';
import states from '../utils/states';
// utils
import showToast from '../utils/toastHelpers';
// context
import { useAuth } from '../Context/AuthContext';



interface ContactFormProps {
    isMobile: boolean;
    animate: boolean;
    setLoading: (value: boolean) => void;
    renderInAnotherPage?: boolean;
    setShowAgreementsForm?: (value: boolean) => void;
}

const ContactForm: React.FC<ContactFormProps> = ({ isMobile, animate, setLoading, renderInAnotherPage = false, setShowAgreementsForm }) => {
    // context
    const { contactData, updateContactData } = useAuth();

    const navigate = useNavigate();

    const initialState = {
        first_name: '',
        last_name: '',
        city: '',
        country: '',
        state: '',
        street1: '',
        street2: '',
        postal_code: '',
    };

    // states
    const [contactInfo, setContactInfo] = useState<{
        first_name: string;
        last_name: string;
        city: string;
        country: string;
        state: string;
        street1: string;
        street2: string;
        postal_code: string;
    }>(initialState);
    const [currentContactData, setCurrentContactData] = useState<any>(null);

    // funtions
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setContactInfo(prev => ({ ...prev, [name]: value }));
    }

    const validateContactInfo = () => {
        if (!contactInfo.first_name) {
            throw new Error('First name is required');
        }
        if (!/^[a-zA-Z\s-]+$/.test(contactInfo.first_name)) {
            throw new Error('First name contains invalid characters');
        }
        if (!contactInfo.last_name) {
            throw new Error('Last name is required');
        }
        if (!/^[a-zA-Z\s-]+$/.test(contactInfo.last_name)) {
            throw new Error('Last name contains invalid characters');
        }
        if (!contactInfo.city) {
            throw new Error('City is required');
        }
        if (!contactInfo.street1) {
            throw new Error('Street 1 is required');
        }
        if (contactInfo.street1.includes('/')) {
            throw new Error('Street cannot contain the character "/"');
        }
        if (contactInfo.street2.includes('/')) {
            throw new Error('Street 2 cannot contain the character "/"');
        }
        if (!contactInfo.country) {
            throw new Error('Country is required');
        }
        if (contactInfo.country === 'US' && !contactInfo.state) {
            throw new Error('State is required');
        }
    }

    const handleUpdate = async () => {
        // validate
        try {
            validateContactInfo();
        } catch (error: any) {
            // console.error(error);
            showToast(error.message, 'error');
            return;
        }
        // update
        try {
            setLoading(true);
            const formContactInfo = {
                first_name: contactInfo.first_name,
                last_name: contactInfo.last_name,
                street1: contactInfo.street1,
                street2: contactInfo.street2 ? `/${contactInfo.street2}/` : '',
                country: contactInfo.country,
                state: contactInfo.state,
                city: contactInfo.city,
                postal_code: contactInfo.postal_code,
            };
            const response = await updateContactData(currentContactData, formContactInfo);
            if (response.success) {
                showToast('Contact info updated successfully', 'success');
                // Check if the agreements are completed
                if(currentContactData?.contact?.contact_agreeTerms !== 'Agree' || currentContactData?.contact?.contact_privacyPolicy !== 'Agree') {
                    showToast('Please accept our agreements to complete your register', 'info');
                    if (!renderInAnotherPage) {
                        navigate('/agreements');
                    } else if (renderInAnotherPage && setShowAgreementsForm) {
                        setShowAgreementsForm(true);
                    }
                }
            } else {
                showToast(response.message, 'error');
            }
        } catch (error: any) {
            console.error(error);
            showToast('Sorry,There was an error while trying to upload the data, please try again', 'error');
        } finally {
            setLoading(false);
        }
    }

    // style update
    const inputStyles = {
        ...authStyles.commonStyles.input,
        width: isMobile ? '90%' : authStyles.commonStyles.input.width,
        fontSize: isMobile ? '20px' : authStyles.commonStyles.input.fontSize,
    };

    const scrollableContentStyles = {
        ...authStyles.commonStyles.scrollableContent,
        paddingTop: isMobile ? 0 : authStyles.commonStyles.scrollableContent.paddingTop,
    };

    const formPartStyles = {
        ...authStyles.commonStyles.formPart,
        justifyContent: isMobile ? 'flex-start' : authStyles.commonStyles.formPart.justifyContent,
        transform: isMobile ? 'translateX(0%)' : (animate ? 'translateX(0%)' : 'translateX(-100%)'),
        paddingBottom: isMobile ? '2rem' : 0,
    };

    // effects
    useEffect(() => {
        if (contactData) {
            setContactInfo({
                first_name: contactData.contact.first_name !== '-' ? contactData.contact.first_name : '',
                last_name: contactData.contact.last_name !== '-' ? contactData.contact.last_name : '',
                city: contactData.contact.city || '',
                country: contactData.contact.country || 'US',
                state: contactData.contact.state || '',
                street1: contactData.contact.line1.split('/')[0] || '',
                street2: contactData.contact.line1.split('/')[1] || '',
                postal_code: contactData.contact.postal_code || '',
            });
            setCurrentContactData(contactData);
        }
    }, [contactData]);

    const renderContactForm = () => {
        return (
            <>
            <h2 style={authStyles.commonStyles.center}>Contact Info</h2>
                <form id='contact-info' onSubmit={(e) => e.preventDefault()} style={authStyles.commonStyles.formLogin}>
                    <input
                        type="text"
                        name="first_name"
                        placeholder="First Name"
                        style={inputStyles}
                        value={contactInfo.first_name}
                        autoComplete="given-name"
                        onChange={handleChange}
                    />
                    <input
                        type="text"
                        name="last_name"
                        placeholder="Last Name"
                        style={inputStyles}
                        value={contactInfo.last_name}
                        onChange={handleChange}
                        autoComplete="family-name"
                    />
                    <input
                        type="text"
                        name="street1"
                        placeholder="Street 1"
                        style={inputStyles}
                        value={contactInfo.street1}
                        onChange={handleChange}
                        autoComplete="street-address"
                    />
                    <input
                        type="text"
                        name="street2"
                        placeholder="Street 2"
                        style={inputStyles}
                        value={contactInfo.street2}
                        onChange={handleChange}
                        autoComplete="street-address"
                    />
                    <input
                        type="text"
                        name="city"
                        placeholder="City"
                        style={inputStyles}
                        value={contactInfo.city}
                        onChange={handleChange}
                    />
                    {contactInfo.country === 'US' && (
                        <select
                            name="state"
                            value={contactInfo.state}
                            onChange={handleChange}
                            style={{ ...inputStyles, background: '#f9f9f9', cursor: 'pointer' }}
                        >
                            <option value="" disabled>Select State</option>
                            {states.map(state => (
                                <option key={state.value} value={state.value}>
                                    {state.label}
                                </option>
                            ))}
                        </select>
                    )}
                    <input
                        type="text"
                        name="postal_code"
                        placeholder="Postal Code"
                        style={inputStyles}
                        value={contactInfo.postal_code}
                        onChange={handleChange}
                        autoComplete="postal-code"
                    />
                    <select
                        name="country"
                        value={contactInfo.country}
                        onChange={handleChange}
                        style={{ ...inputStyles, background: '#f9f9f9', cursor: 'pointer' }}
                    >
                        <option value="" disabled>Select Country</option>
                        {countries.map(country => (
                            <option key={country.value} value={country.value}>
                                {country.label}
                            </option>
                        ))}
                    </select>
                    <div style={{ ...authStyles.commonStyles.center, ...authStyles.commonStyles.buttonContent }}>
                        <button type="submit" className="buttonBlack" onClick={handleUpdate}>Update</button>
                    </div>
                </form>
            </>
        )
    }

    return (
        <div style={formPartStyles}>
            {renderInAnotherPage ? renderContactForm() :
            <div style={scrollableContentStyles}>
                {renderContactForm()}
            </div>}
        </div>
    );
}

export default ContactForm;