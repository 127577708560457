// Chat.tsx
import React, { useRef, useEffect, useState, CSSProperties, useMemo, useCallback } from 'react';
// Firebase
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import { app, rtdbTwilioData } from '../../firebase/firebase';
import { set, ref, get } from '@firebase/database';
// services
import fluxRestApi from '../../services/FluxRestApi';
// others
import moment from 'moment';
import { jwtDecode } from 'jwt-decode';
import imageCompression from 'browser-image-compression';
import { Buffer } from 'buffer';
// Twilio
import { Client } from '@twilio/conversations';
import showToast from '../../utils/toastHelpers';
// context
import { useAuth } from '../../Context/AuthContext';

type TokenData = {
    exp: number;
};

const Chat: React.FC = () => {
    const auth = getAuth(app);

    // context
    const { contactData } = useAuth();

    // ref
    const scrollViewRef = useRef<HTMLDivElement>(null);
    // states
    const [user, setUser] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);
    const [chatToken, setChatToken] = useState<string | null>(null);
    const [conversation, setConversation] = useState<any>(null);
    const [messages, setMessages] = useState<any[]>([]);
    const [newMessage, setNewMessage] = useState<string>('');
    const [isSendingDisabled, setIsSendingDisabled] = useState<boolean>(false);
    const [conversationSid, setConversationSid] = useState<string | null>(null);
    const [client, setClient] = useState<any>(null);
    const [clientReady, setClientReady] = useState<boolean>(false);
    const [loadingConversation, setLoadingConversation] = useState<boolean>(false);
    const [listenerSet, setListenerSet] = useState<boolean>(false);
    // images
    const [messagesWithMedia, setMessagesWithMedia] = useState<any[]>([]);
    const [isImageViewVisible, setIsImageViewVisible] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [sendingImage, setSendingImage] = useState<boolean>(false);

    //funtions
    const setTimeStamp = async (id: string,) => {
        try {
            if (!id) throw new Error('ID is required to set timestamp');
            const timestamp = moment().utc().toISOString();
            await set(ref(rtdbTwilioData, `conversations/${id}/fluxAppReadTimestamp`), timestamp);
            return true;
        } catch (error) {
            console.log('Error creating timestamp', error);
            return null
        }
    }

    const sendPushNotification = async (message: string) => {
        const name = (contactData?.contact?.first_name !== '-' || !contactData?.contact?.first_name) ? `${contactData?.contact?.first_name} ${contactData?.contact?.last_name}` : '';

        const data = {
            "title": `Chat Support: New Message from ${name || user.email}`,
            "body": `Chat Support: ${message} | New Message from ${name || ''} ${user.email}`,
            "send_sms": true,
            "send_email": true,
        }
        try {
            //title: string, body: string, send_sms: boolean, send_email: boolean
            const push = await fluxRestApi.sendNotificationToSupportUsers(data.title, data.body, data.send_sms, data.send_email);
            if (push?.status === 202) {
                console.log('Push notification sent');
            }
        } catch (error) {
            console.error('Error sending push notification', error);
        }
    };

    const handleSendMessage = async () => {
        if (newMessage !== '' && conversation && !isSendingDisabled) {
            try {
                const message = newMessage;
                setNewMessage('');
                const sendMessage = await conversation.sendMessage(message);
                if (sendMessage) {
                    await sendPushNotification(newMessage);
                    await setTimeStamp(user?.uid || '');
                };
            } catch (error) {
                setIsSendingDisabled(true);
                setError('Error while sending the message');
                console.error('Error while sending the message', error);
            }
        }
    };

    const messageHour = (date: any) => {
        return moment(date).format('h:mm a');
    };

    const titleDate = (date: any) => {
        return moment(date).format('MMMM Do YY');
    };

    const addSupportToConversation = async (conversationSid: string) => {
        const maxAttempts = 5;
        let attempts = 0;

        while (attempts < maxAttempts) {
            try {
                const response = await fluxRestApi.addSupportToConversation(conversationSid);
                if (response?.status === 200) {
                    return true; // Success, so return true
                }
            } catch (error) {
                console.log('addSupportToConversation failed:', error);
            }

            attempts++;

            // Wait for 2 seconds before the next attempt
            await new Promise(resolve => setTimeout(resolve, 2000));
        }

        return null; // Return null after all attempts fail
    };

    const groupedMessages = useMemo(() => {
        if (!messagesWithMedia.length) return [];
        return messagesWithMedia.reduce((result, message, index) => {
            const messageDate = titleDate(message.dateCreated);
            const today = titleDate(new Date());
            if (messageDate !== result.lastPrintedDate) {
                if (messageDate === today) {
                    result.grouped.push({ type: 'dateHeader', date: 'Today' });
                    result.lastPrintedDate = messageDate;
                } else {
                    result.grouped.push({ type: 'dateHeader', date: messageDate });
                    result.lastPrintedDate = messageDate;
                }
            }

            result.grouped.push(message); // Add the message itself
            return result;
        }, { grouped: [], lastPrintedDate: "" }).grouped;
    }, [messagesWithMedia]);

    // Memoize the onPressImage function
    const onPressImage = useCallback((url: string) => {
        setSelectedImage(url); // Set the selected image URL
        setIsImageViewVisible(true); // Show the image modal
    }, []);

    const convertUrlsToLinks = (text: string) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.split(urlRegex).map((part, index) => {
            if (urlRegex.test(part)) {
                return <a key={index} href={part} target="_blank" rel="noopener noreferrer">{part}</a>;
            }
            return part;
        });
    };

    // Function to handle image upload and send
    const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        // Allowed MIME types for PNG and JPEG images
        const allowedFileTypes = ['image/jpeg', 'image/png'];

        // File size validation: Allow only files under 50MB
        const maxFileSizeMB = 50; // Set the max file size limit, e.g., 50 MB
        const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024;

        // Check if file type is allowed (PNG or JPEG)
        if (!allowedFileTypes.includes(file.type)) {
            showToast(`Invalid file type. Only JPEG and PNG images are allowed.`, 'error');
            return;
        }

        // Check if the file size is below the limit
        if (file.size > maxFileSizeBytes) {
            showToast(`File is too large. Maximum allowed size is ${maxFileSizeMB} MB.`, 'error');
            return;
        }

        // Compress the image (optional)
        const options = {
            maxSizeMB: 1, // Compress the image to be under 1 MB
            maxWidthOrHeight: 1600, // Max width or height
            useWebWorker: true // Use web workers for faster compression
        };

        try {
            setSendingImage(true);
            const compressedFile = await imageCompression(file, options);

            // Convert the compressed file to a base64 string
            const base64String = await convertToBase64(compressedFile);

            // Convert base64 to a Buffer
            let buffer = Buffer.from(base64String.split(',')[1], 'base64');

            // Send the buffer as media via Twilio
            sendImage(buffer, compressedFile.type);
            setSendingImage(false);
        } catch (error) {
            setSendingImage(false);
            console.error('Error processing the image:', error);
        }
    };

    // Utility function to convert a file to base64
    const convertToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });
    };

    // Modify the handleSendMessage to handle the base64 image
    const sendImage = async (base64Image: Buffer, type: string) => {
        if (conversation && !isSendingDisabled) {
            try {
                await conversation.sendMessage({
                    contentType: type,
                    media: base64Image,
                });
                console.log('Media sent');
                const bodyMessage = 'The user has sent an image, please check the chat.';
                await sendPushNotification(bodyMessage);
                await setTimeStamp(user?.uid || '');
                console.log('Image sent successfully');
            } catch (error) {
                setIsSendingDisabled(true);
                setError('Error while sending the image');
                console.error('Error while sending the image', error);
            }
        }
    };

    // effects
    // check if user is logged in
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (firebaseUser: any) => {
            if (firebaseUser?.email && firebaseUser?.emailVerified) {
                setUser(firebaseUser);
            } else {
                setUser(null);
            }
        });

        // Clean up the listener when the component is unmounted
        return () => unsubscribe();
    }, [auth]);

    // get the chatToken
    useEffect(() => {
        const fetchToken = async (uid: string) => {
            try {
                if (!uid) {
                    throw new Error('No user id found');
                }
                const response = await fluxRestApi.getTwilioToken(uid);
                if (response?.data?.token) {
                    const decodedToken: TokenData = jwtDecode(response.data.token);
                    const currentTime = Math.floor(Date.now() / 1000);
                    const tokenExpiryTime = decodedToken.exp - currentTime;

                    // Log or check remaining time before expiration
                    // console.log('Token expires in:', tokenExpiryTime, 'seconds');

                    // Set the token and start a timer to refresh it before it expires
                    setChatToken(response.data.token);
                    setError(null);
                    console.log('Chat token fetched');
                    // Set a timeout to refresh the token 1 minute before it expires
                    setTimeout(() => {
                        console.log('Refreshing chat token');
                        setChatToken(null);
                        setClient(null);
                        setConversation(null);
                        setClientReady(false);
                    }, (tokenExpiryTime - 60) * 1000); // Refresh 1 minute before expiry
                } else {
                    console.error('Error fetching chat token:', response);
                    setChatToken(null);
                    setError('Error fetching chat token');
                }
            } catch (error) {
                console.error('Error fetching chat token:', error);
                setError('Error fetching chat token');
            }
        };

        if (user?.uid && !chatToken && !client && !conversation) {
            fetchToken(user.uid);
        }

    }, [user, chatToken, conversation, client]);

    // get the conversation
    useEffect(() => {
        const fetchConversationSid = async () => {
            try {
                const uid = user.uid;
                const snapshot = await get(ref(rtdbTwilioData, `conversations/${uid}`));
                // console.log('conversationSid', snapshot.exists() ? snapshot.val().conversation_sid : '');
                return setConversationSid(snapshot.exists() ? snapshot.val().conversation_sid : '');
            } catch (err) {
                console.error("An error occurred while fetching the conversation SID:", err);
            }
        }

        if (user && !conversationSid) {
            fetchConversationSid();
        }
    }, [user, conversationSid]);

    // init Client
    useEffect(() => {
        if (chatToken && conversationSid && !clientReady) {
            const client = new Client(chatToken);

            client.on('initialized', async () => {
                setClient(client);
                setClientReady(true);
                console.log('Client initialized');
            });

            client.on('initFailed', ({ error }) => {
                setError('Failed to initialize client');
                console.error("Failed to initialize client", error);
            });
        }
    }, [chatToken, conversationSid, clientReady]);

    useEffect(() => {
        if (clientReady && conversation && !listenerSet) {
            conversation.on('messageAdded', (message: any) => {
                setMessages((prevMessages) => [...prevMessages, message]);
            });
            setListenerSet(true);
        }
    }, [conversation, clientReady, listenerSet]);

    useEffect(() => {
        const removeConversation = async () => {
            const maxAttempts = 5;
            let attempts = 0;
            while (attempts < maxAttempts) {
                try {
                    const response = await fluxRestApi.removeAConversation(user.uid);
                    if (response?.status === 200) {
                        return true; // Success, so return true
                    }
                } catch (error) {
                    console.log('removeConversation failed:', error);
                }

                attempts++;

                // Wait for 2 seconds before the next attempt
                await new Promise(resolve => setTimeout(resolve, 2000));
            }

            return null; // Return null after all attempts fail
        };

        if (user && client && clientReady && !conversation && conversationSid) {
            console.log('getSubscribedConversations');
            setLoadingConversation(true);
            client.getSubscribedConversations().then((conversationsPaginator: any) => {
                // console.log('conversationsPaginator', conversationsPaginator);
                let isConversationFound = false;
                for (let i = 0; i < conversationsPaginator.items.length; i++) {
                    const conversation = conversationsPaginator.items[i];
                    // console.log('conversation', conversation);
                    if (conversation.sid === conversationSid) {
                        console.log("Joined conversation:");
                        setConversation(conversation);
                        // Get existing messages
                        conversation.getMessages().then((messagesPaginator: any) => {
                            setMessages(messagesPaginator.items);
                        });
                        isConversationFound = true;
                        setLoadingConversation(false);
                        break;
                    }
                }

                if (!isConversationFound) {
                    // Create and join a new conversation if it wasn't found in the subscribed conversations
                    client.createConversation({ friendlyName: user.email, uniqueName: user.uid })
                        .then(async (conversation: any) => {
                            // Save new conversation SID to Firebase
                            setConversationSid(conversation.sid);
                            set(ref(rtdbTwilioData, `conversations/${user.uid}`), {
                                conversation_sid: conversation.sid
                            }).catch(error => {
                                console.error('Error while saving conversation SID to Firebase:', error);
                            });
                            const addSupport = await addSupportToConversation(conversation.sid);
                            if (addSupport) {
                                console.log('addSupport success');
                            } else {
                                console.log('addSupport failed');
                                setError('Failed to add support to the conversation');
                                setConversation(null);
                                const remove = await removeConversation();
                                setLoadingConversation(false);
                                if (remove) {
                                    return console.log('remove conversation success');
                                } else {
                                    return console.log('remove conversation failed');
                                }
                            }
                            conversation.join().then(async () => {
                                console.log("Joined new conversation:", conversation);
                                setConversation(conversation);
                            });
                            setLoadingConversation(false);
                        }).catch((error: any) => {
                            console.error('Error while creating and joining a new conversation', error);
                        });
                }


            }).catch((error: any) => {
                console.error('Error while getting subscribed conversations', error);
                setError('Error while getting subscribed conversations');
                setLoadingConversation(false);
            });
        }
    }, [conversation, clientReady, conversationSid, client, user]);

    useEffect(() => {
        if (scrollViewRef.current) {
            scrollViewRef.current.scrollTop = scrollViewRef.current.scrollHeight;
        }
    }, [messagesWithMedia]);

    // get the media messages
    useEffect(() => {
        let isMounted = true;

        const fetchMediaUrls = async () => {
            if (!isMounted) return;
            const updatedMessages = await Promise.all(messages.map(async (message) => {
                if (!isMounted) return;
                if (message.type === 'media' && !message.mediaUrl) {
                    try {
                        const mediaUrl = await message.media.getContentTemporaryUrl();
                        const expireTime = Date.now() + 300000; // Current time + 5 minutes (300000 ms)

                        console.log('Get Media URL');

                        message.mediaUrl = mediaUrl;
                        message.mediaExpireTime = expireTime; // Save the expire time with the message

                        return message;
                    } catch (error) {
                        console.error('Error fetching media content:', error);
                        return message; // Return the original message if there's an error
                    }
                } else if (message.type === 'media' && message.mediaUrl && message.mediaExpireTime && Date.now() >= message.mediaExpireTime - 60000) {
                    // Refresh the URL if it's about to expire (e.g., 1 minute before expiration)
                    try {
                        console.log('Refreshing media content');

                        const mediaUrl = await message.media.getContentTemporaryUrl();
                        const newExpireTime = Date.now() + 300000; // Reset expiration time

                        message.mediaUrl = mediaUrl;
                        message.mediaExpireTime = newExpireTime;

                        return message;
                    } catch (error) {
                        console.error('Error refreshing media content', error);
                        return message; // Return the original message if there's an error
                    }
                }
                return message; // Return the original message if no media URL needs fetching or refreshing
            }));

            if (!isMounted) return;
            setMessagesWithMedia(updatedMessages);
        };

        const interval = setInterval(fetchMediaUrls, 60000); // Check every minute

        fetchMediaUrls(); // Initial fetch

        return () => {
            console.log('Clearing interval');
            clearInterval(interval)
            isMounted = false;
        };
    }, [messages]);

    // renders
    interface MessageComponentProps {
        message: {
            mediaUrl?: string;
            media?: {
                contentType: string;
            };
            body?: string;
            dateCreated: string;
            author: string;
        };
        isSentByCurrentUser: boolean;
        messageHourText: string;
        onPressImage: (url: string) => void;
    }
    const MessageComponent: React.FC<MessageComponentProps> = React.memo(({ message, isSentByCurrentUser, messageHourText, onPressImage }) => (
        <div
            style={isSentByCurrentUser ? styles.messageContainerReverse : styles.messageContainer}
        >
            {message.mediaUrl ? (
                message.media?.contentType.startsWith('image/') ? (
                    <div style={{ ...styles.message, backgroundColor: isSentByCurrentUser ? "#C7F100" : '#f1f0f0', }} onClick={() => onPressImage(message.mediaUrl!)}>
                        <img alt="messageMedia" src={message.mediaUrl} style={styles.mediaImage} />
                        <p style={styles.messageHourText}>{messageHourText}</p>
                    </div>
                ) : (
                    <div style={{ ...styles.message, backgroundColor: isSentByCurrentUser ? "#C7F100" : '#f1f0f0', }}>
                        <p style={styles.messageText}>Unsupported media type</p>
                        <p style={styles.messageHourText}>{messageHourText}</p>
                    </div>
                )
            ) :
                (<>
                    <div style={{ ...styles.message, backgroundColor: isSentByCurrentUser ? "#C7F100" : '#f1f0f0', }}>
                        <p style={styles.messageText}>{convertUrlsToLinks(message.body || '')}</p>
                        <p style={styles.messageHourText}>{messageHourText}</p>
                    </div>
                </>)}
        </div>
    ));

    // renderItem function for groupedMessages map
    const renderMessageItem = useCallback((item: any, index: any) => {
        if (!user.uid) return null;
        if (item.type === 'dateHeader') {
            return (
                <div key={index} style={{ textAlign: 'center', margin: '10px 0' }}>
                    {item.date}
                </div>
            );
        }

        const message = item;
        const messageHourText = messageHour(message.dateCreated);
        const isSentByCurrentUser = message.author === user.uid;

        return (
            <MessageComponent
                key={message.sid}
                message={message}
                isSentByCurrentUser={isSentByCurrentUser}
                messageHourText={messageHourText}
                onPressImage={onPressImage}
            />
        );
    }, [user, onPressImage, MessageComponent]);

    if (error) {
        return (
            <div style={styles.container}>
                <h2 style={styles.title}>Chat Window</h2>
                <p style={styles.subTitle}>Error: {error}</p>
                <p>Please try to open the chat again</p>
            </div>
        );
    }

    if (!user) {
        return (
            <div style={styles.container}>
                <h2 style={styles.title}>Chat Window</h2>
                <p style={styles.subTitle}>Please login to chat with us!</p>
            </div>
        );
    }

    return (<>
        {(!conversationSid || !clientReady || !chatToken) ? (
            <div style={styles.container}>
                <h2 style={styles.title}>Chat Window</h2>
                <p style={styles.subTitle}>Loading the chat...</p>
            </div>
        ) : (
            <div style={styles.containerChat}>
                {isImageViewVisible && selectedImage && (
                    <div style={styles.fullScreenOverlay} onClick={() => setIsImageViewVisible(false)}>
                        <img alt="Full Screen" src={selectedImage} style={styles.fullScreenImage} />
                    </div>
                )}
                <div
                    ref={scrollViewRef}
                    style={styles.scrollView}
                    onScroll={() => {
                        if (scrollViewRef.current) {
                            const { scrollHeight, scrollTop, clientHeight } = scrollViewRef.current;
                            if (scrollHeight - scrollTop === clientHeight) {
                                // You're at bottom. Load more data or do whatever you want.
                            }
                        }
                    }}
                >
                    {loadingConversation ? (
                        <p>Loading conversation</p>
                    ) : (
                        <div>
                            {groupedMessages.map(renderMessageItem)}
                        </div>
                    )}
                </div>
                <div style={styles.textareContent}>
                    {sendingImage ? (<>
                        <p style={styles.sendingImageText}>Uploading...</p>
                    </>) : (
                        <>
                            <input
                                disabled={sendingImage}
                                type="file"
                                accept="image/*"
                                onChange={handleImageUpload}
                                id="image-upload"
                                style={{ display: 'none' }} // Hide the default file input
                            />
                            <label htmlFor="image-upload" style={styles.uploadButton}>
                                <img src={require("../../images/plus.png")} alt="Upload" style={styles.plusIcon} />
                            </label>
                        </>)}
                    <textarea
                        style={styles.textArea}
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        rows={4}
                    />
                    <button
                        onClick={handleSendMessage}
                        disabled={isSendingDisabled}
                        style={styles.buttonSend}
                    >
                        Send
                    </button>
                </div>
            </div>
        )}
    </>
    );

};

const styles: { [key: string]: CSSProperties } = {
    buttonSend: {
        padding: '10px',
        margin: '10px',
        borderRadius: '10px',
        backgroundColor: 'transparent',
        color: '#333',
        border: '1px solid #4c4c4c',
        cursor: 'pointer',
        fontFamily: 'Roboto, sans-serif'
    },
    containerChat: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        fontFamily: 'Roboto, sans-serif',
    },
    scrollView: {
        maxHeight: '75%',
        padding: '10px',
        marginTop: '10px',
        overflowY: 'auto',
    },
    dateHeader: {
        textAlign: 'center',
        margin: '10px 0'
    },
    messageContainer: {
        display: 'flex',
        margin: '10px 0'
    },
    messageContainerReverse: {
        display: 'flex',
        flexDirection: 'row-reverse',
        margin: '10px 0',
    },
    message: {
        padding: '10px',
        borderRadius: '10px',
        backgroundColor: '#f1f0f0',
        color: '#333',
        maxWidth: '70%',
        wordWrap: 'break-word'
    },
    mediaImage: {
        width: 100,
        height: 100,
        objectFit: 'contain',
        cursor: 'pointer'
    },
    messageText: {
        margin: 0
    },
    messageHourText: {
        margin: 0,
        fontSize: '0.8rem',
        textAlign: 'right',
        fontFamily: 'Roboto, sans-serif'
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    textArea: {
        margin: '10px',
        width: '80%',
        height: '50px',
        borderRadius: '10px',
        border: '1px solid #ccc',
        resize: 'none',
        fontFamily: 'Roboto, sans-serif'
    },
    container: {
        width: '100%',
        height: '100%'
    },
    title: {
        fontSize: '1.5rem',
        color: '#333',
        margin: '10px 0',
        fontFamily: 'Roboto, sans-serif',
    },
    subTitle: {
        fontSize: '1rem',
        color: '#666',
        margin: '10px 0',
        fontFamily: 'Roboto, sans-serif',
    },
    fullScreenOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999, // Ensure it's on top
        cursor: 'pointer',
    },
    fullScreenImage: {
        maxWidth: '90%',
        maxHeight: '90%',
        objectFit: 'contain',
    },
    uploadButton: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        padding: '10px',
        backgroundColor: 'transparent',
        borderRadius: '5px',
        cursor: 'pointer',
        textAlign: 'center',
        borderColor: '#000000',
        borderWidth: '0.5px',
        borderStyle: 'solid',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        marginRight: '10px',
        marginLeft: '10px',
    },
    plusIcon: {
        width: '20px',
        height: '20px',
        verticalAlign: 'middle',
    },
    sendingImageText: {
        margin: '10px',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '0.8rem',
        color: '#333',
    },
    textareContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: '10px 0',
        backgroundColor: '#fff',
        boxSizing: 'border-box',
    },
};

export default Chat;
