import React, { CSSProperties,useState } from "react";
// context
import { useModal } from "../Context/ModalContext";
import { useAuth } from "../Context/AuthContext";
// utils
import showToast from "../utils/toastHelpers";
// components
import Loader from "./Loader";
// services
import fluxRestApi from "../services/FluxRestApi";


const ModalContactSupport: React.FC = () => {
    // states
    const { showModal, closeModal, stationData, setStationData } = useModal();
    const { user, contactData } = useAuth();
    const [displayForm, setDisplayForm] = useState(false);
    const [issueDescription, setIssueDescription] = useState("");
    const [issueTitle, setIssueTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    // functions
    const handleOptionClick = (option: string) => {
        setDisplayForm(true);
        setIssueTitle(option);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event?.target?.files?.[0];
        if (file) {
            setSelectedFile(file);
        }
    };

    const handleCloseModal = () => {
        closeModal();
        setStationData({});
        setDisplayForm(false);
        setIssueDescription("");
        setIssueTitle("");
        setSelectedFile(null);
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setIssueDescription(e.target.value);
    };

    const requestCreateTicket = async (ticketData: any) => {
        try {
            const response = await fluxRestApi.createTicket(ticketData);
            if (!response) throw new Error('error creating ticket');
            return response;
        } catch (e: any) {
            console.log("error", e);
            throw new Error(e);
        }
    };

    const handleFileUpload = async (ticketData: any) => {
        try {
            const response = await fluxRestApi.addPhotoCreateTicket(selectedFile, ticketData);
            if (!response) throw new Error('error creating ticket');
            return response;
        } catch (e: any) {
            console.log("error", e);
            throw new Error(e);
        }
    };

    const handleSubmit = async(e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (issueDescription === "") {
            showToast("Please enter a description", "error");
            return;
        }
        if (!user) {
            showToast("Please login to submit issue", "error");
            return;
        }
        if (issueTitle === "Add Photo") {
            if (!selectedFile) {
                showToast("Please select a file", "error");
                return;
            }

            const fileExtension = selectedFile.name.split('.').pop();
            if (fileExtension !== 'jpg' && fileExtension !== 'png') {
                showToast("Please select a jpg or png file", "error");
                return;
            }

            if (selectedFile.size > 20000000) {
                showToast("Please select a file smaller than 20MB", "error");
                return;
            }
        }

        if (issueTitle !== "Add Photo") {
            try {
                setLoading(true);
                const ticketData = {
                    "hubspot_id": contactData?.contact?.id || 'error reading hubspot id',
                    "subject": issueTitle + " - " + stationData?.properties?.flux_name,
                    "content": `${issueDescription} // station_id: ${stationData?.id || 'error reading station id'}`,
                }
                const createTicket = await requestCreateTicket(ticketData);
                if (!createTicket) throw new Error('error creating ticket');
                showToast("Issue submitted successfully", "success");
                setLoading(false);
                handleCloseModal();
            }
            catch (e) {
                showToast("Error submitting issue", "error");
                console.log("error", e);
                setLoading(false);
            }
        } else {
            try{
                setLoading(true);
                const ticketData = {
                    "hubspot_id": contactData?.contact?.id || 'error reading hubspot id',
                    "subject": `Report Issue with Image - ${stationData?.properties?.flux_name || 'error reading station name'}`,
                    "content": `${issueDescription} // station_id: ${stationData?.id || 'error reading station id'}`,
                }
                const createPhotoTicket = await handleFileUpload(ticketData);
                if (!createPhotoTicket) throw new Error('error creating ticket');
                showToast("Issue submitted successfully", "success");
                handleCloseModal();
                setLoading(false);
            } catch (e) {
                console.log("error", e);
                showToast("Error submitting issue", "error");
                setLoading(false);
            }
        }

    };


    return (
        <>
            <Loader loading={loading} />
            {showModal && !loading && (
                <div style={styles.modalContainer}>
                    <div style={styles.modalContent}>
                        <div style={styles.modalHeader}>
                            <h2 style={{ fontSize: '18px' }}>{ issueTitle ||'Charging Station Problem'}</h2>
                            <button style={styles.closeButton} onClick={handleCloseModal}>
                                X
                            </button>
                        </div>
                        <div id="form-issue" style={styles.modalBody}>
                            {displayForm ? (
                                <>
                                    <textarea
                                        id="issue-description"
                                        style={{ width: "100%", height: "150px", marginBottom: "10px" }}
                                        placeholder="Describe the issue"
                                        value={issueDescription}
                                        onChange={handleDescriptionChange}
                                        maxLength={255}
                                    />
                                    {issueTitle === "Add Photo" && (
                                        <input
                                            type="file"
                                            onChange={handleFileChange}
                                            style={styles.fileInput}
                                        />
                                    )
                                    }
                                    <button style={styles.modalButton} onClick={handleSubmit}>
                                        Submit
                                    </button>
                                </>
                            ) : (<>
                                <button style={styles.modalButton} onClick={() => handleOptionClick("Add Photo")}>
                                    Add Photo
                                </button>
                                <button style={styles.modalButton} onClick={() => handleOptionClick("Report Issue")}>
                                    Report Issue
                                </button>
                                <button style={styles.modalButton} onClick={() => handleOptionClick("Station Off")}>
                                    Station Off
                                </button>
                                <button style={styles.modalButton} onClick={() => handleOptionClick("Charging Issues")}>
                                    Charging Issues
                                </button>
                                <button style={styles.modalButton} onClick={() => handleOptionClick("Station Blocked")}>
                                    Station Blocked
                                </button>
                                <button style={styles.modalButton} onClick={() => handleOptionClick("Other")}>
                                    Other
                                </button>
                            </>)
                            }

                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

const styles: { [key: string]: CSSProperties } = {
    modalContainer: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1000,
    },
    modalContent: {
        backgroundColor: "white",
        padding: "20px",
        borderRadius: "5px",
        width: "300px",
    },
    modalHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "10px",
    },
    closeButton: {
        border: "none",
        backgroundColor: "transparent",
        cursor: "pointer",
        fontSize: "20px",
    },
    modalBody: {},
    modalButton: {
        display: "block",
        width: "100%",
        padding: "10px",
        marginBottom: "10px",
        border: "none",
        borderRadius: "5px",
        backgroundColor: "#C7F100",
        color: "#333",
        fontWeight: "bold",
        cursor: "pointer",
        outline: "none",
        transition: "background-color 0.3s",
    },
    reportIssueOptions: {
        marginTop: "10px",
    },
    fileInput: {
        marginBottom: '10px',
        width: '100%',
    },
};

export default ModalContactSupport;
