// src/utils/sendConversion.ts
import axios from 'axios';

export const sendConversion = async (cid: string, amount: string, customFile: any) => {
    const url = 'https://flux.trackdesk.com/tracking/conversion/v1';
    const data = {
        cid: cid,
        conversionTypeCode: 'sale',
        amount: {
            value: amount,
        },
        customParams: {
            advS1:  `carID:${customFile?.advS1 || ''}`,
            advS2: `tripID:${customFile?.advS2 || ''}`,
            advS3: `email:${customFile?.advS3 || ''}`,
        }
    };

    try {
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        console.log('Conversion tracked:', response.data);
    } catch (error) {
        console.error('Error tracking conversion:', error);
    }
};
