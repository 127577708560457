import React, { useState, CSSProperties, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// assets
import SplashScreen from "../images/banners/banner-blue-car.webp";
import authStyles from "../styles/authStyles";
// componets
import SplashImage from "../components/auth/SplashImage";
import Loader from "../components/Loader";
// hooks
import { useIsMobile, useAnimateOnDesktop } from "../hooks/useIsMobile";
// context
import { useAuth } from "../Context/AuthContext";
// utils
import showToast from '../utils/toastHelpers';
import fluxRestApi from "../services/FluxRestApi";
// stripe
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
// services
import bundeeRestApi from "../services/BundeeRestApi";

const Payment: React.FC = () =>  {
    const navigate = useNavigate();
    const { contactData } = useAuth();
    // hooks
    const isMobile = useIsMobile();
    const shouldAnimate = useAnimateOnDesktop(isMobile);
    // stripe
    const stripe = useStripe();
    const elements = useElements();
    // states
    const [loading, setLoading] = useState<boolean>(true);
    const [showCardForm, setShowCardForm] = useState<boolean>(false);
    const [stripeId, setStripeId] = useState<string>("");
    const [cards, setCards] = useState<any[]>([]);
    const [cardSelected, setCardSelected] = useState<any>(null);
    const [loadingCards, setLoadingCards] = useState<boolean>(true);
    const [loadingTrips, setLoadingTrips] = useState<boolean>(false);
    const [trips, setTrips] = useState<any[]>([]);
    // modal states
    const [showModal, setShowModal] = useState<boolean>(false);

    // funtions
    const handleDeleteCard = async () => {
        return showToast('Delete a card is not longer available. Please contact support.', 'info');
    };
    const closeModal = () => {
        setShowModal(false);
        if (cardSelected) {
            setCardSelected(null);
        }
    }

    // effects
    useEffect(() => {
        // Show the loading for 1 second and then render the page
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        // Cleanup the timer on component unmount
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (contactData?.contact?.stripe_id) {
            setStripeId(contactData.contact.stripe_id);
        }
    }, [contactData]);

    useEffect(() => {
        const fetchCards = async () => {
            try{
                const response = await fluxRestApi.getPaymentMethods(stripeId);
                if (response?.data?.payment_methods?.length > 0) {
                    const transformedCards = response?.data?.payment_methods.map((card: any) => {
                        return {
                            id: card.id,
                            brand: card.card.brand,
                            fingerprint: card.card.fingerprint,
                            last4: card.card.last4,
                            exp_month: card.card.exp_month,
                            exp_year: card.card.exp_year
                        };
                    });

                    setCards(transformedCards);
                    setLoadingCards(false);
                    // console.log('getUserCards',response?.data?.payment_methods);
                } else if (response?.data?.payment_methods?.length === 0) {
                    setCards([]);
                    setLoadingCards(false);
                } else {
                    throw new Error('Something went wrong');
                }

                setLoading(false);
            }catch (e: any) {
                console.log('getUserCards error',e);
                setLoadingCards(false);
                throw new Error(e);
            }
        }

        if (stripeId) {
            try{
                setLoading(true);
                fetchCards();
            }catch (e) {
                console.log('getUserCards error',e);
                showToast("Ops! it was not possible to get your payment methods, please try again!", "error");
                setLoading(false);
            }
        }
    }, [stripeId, showCardForm]);

    useEffect(() => {
        if (cardSelected) {
            if (trips.length > 0) {
                setCardSelected(null);
                return showToast('Delete a card is not longer available. Please contact support.', 'info');
            }
            setShowModal(true);
        }
    }, [cardSelected, trips]);

    // get trips data from a user
    useEffect(() => {
        const getTrips = async () => {
            try {
                setLoadingTrips(true);
                let startTime = '';
                let endTime = '';

                const response = await bundeeRestApi.getAllTripDetailsFromValue('userId', contactData?.contact?.bundee_user_id, startTime, endTime);
                if (response?.data?.errorCode === '0') {
                    const allTrips = response?.data?.activetripresponse || [];
                    // Assign the result of the filter to a new variable
                    const activeTrips = allTrips.filter((trip: any) => trip.statusCode === 'REREQ' || trip?.statusCode === 'REAPP' || trip?.statusCode === 'TRSTR');
                    setTrips(activeTrips);
                    setLoadingTrips(false);
                } else if (response?.data?.errorCode === '1' && response?.data?.errorMessage === "Trip Data is Empty...") {
                    console.log('activeTrips', 'No active trips');
                    setTrips([]);
                    setLoadingTrips(false);
                } else {
                    throw new Error('Error getting trips');
                }
            } catch (error) {
                console.error('Error getting trips:', error);
                showToast('Something went wrong loading your trips. Please try again.', 'error');
                setLoadingTrips(false);
                navigate(-1);
            }
        }

        if (contactData?.contact?.bundee_user_id) {
            getTrips();
        }
    }, [contactData, navigate]);


    return(
        <div>
            <div style={authStyles.commonStyles.container}>
            <Loader loading={loading || loadingTrips} />
            <SplashImage isMobile={isMobile} animate={shouldAnimate} SplashScreen={SplashScreen} />
                {showCardForm ?
                    <CardForm
                        isMobile={isMobile}
                        animate={shouldAnimate}
                        setShowCardForm={setShowCardForm}
                        setLoading={setLoading}
                        stripeId={stripeId}
                        stripe={stripe}
                        elements={elements}
                    />
                    :
                    (<>
                        <DeleteCardModal showModal={showModal} closeModal={closeModal} confirmDelete={handleDeleteCard} />
                        <Card loadingCards={loadingCards} isMobile={isMobile} animate={shouldAnimate} setShowCardForm={setShowCardForm} cards={cards} setCardSelected={setCardSelected} />
                    </>)
                }
            </div>
        </div>
    )
}
// modal to remove credit card
interface DeleteCardModalProps {
    showModal: boolean;
    closeModal: () => void;
    confirmDelete: () => void;
}

const DeleteCardModal: React.FC<DeleteCardModalProps> = ({ showModal, closeModal, confirmDelete }) => {
    const styles: { [key: string]: React.CSSProperties } = {
        modalOverlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            opacity: 0,
            transition: 'opacity 0.3s',
        },
        modalContent: {
            backgroundColor: 'white',
            padding: '20px',
            maxWidth: '500px',
            width: '80%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '10px',
            transform: 'translateY(50px)',
            opacity: 0,
            transition: 'transform 0.3s, opacity 0.3s',
        },
        closeButton: {
            position: 'absolute',
            right: '10px',
            top: '10px',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            fontSize: '20px',
        },
        confirmButton: {
            marginTop: '20px',
            padding: '10px 20px',
            backgroundColor: '#ff0000',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
        },
    };

    return (
        <>
            {showModal && (
                <div style={{ ...styles.modalOverlay, opacity: showModal ? 1 : 0 }}>
                    <div style={{ ...styles.modalContent, opacity: showModal ? 1 : 0, transform: showModal ? 'translateY(0)' : 'translateY(50px)' }}>
                        <button style={styles.closeButton} onClick={closeModal}>
                            X
                        </button>
                        <h2 style={{textAlign: 'center'}}>Click confirm to delete this card</h2>
                        <div style={{ ...authStyles.commonStyles.center, ...authStyles.commonStyles.buttonContent, width: '50%' }}>
                            <button className="buttonBlack" onClick={confirmDelete}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

interface CardProps {
    isMobile: boolean;
    animate: boolean;
    setShowCardForm: (value: boolean) => void;
    cards: any[];
    setCardSelected: (value: any) => void;
    loadingCards: boolean;
}
// styles
const cardStyles: CSSProperties = {
    width: '300px',
    height: '80px',
    borderRadius: '15px',
    margin: '20px',
    background: 'linear-gradient(135deg, #024059 0%, #5f7480 100%)',
    color: 'white',
    padding: '20px',
    position: 'relative',
    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
    transition: 'transform 0.3s ease-in-out, background 0.3s ease-in-out',
    cursor: 'pointer',
};
const hoveredCardStyles: CSSProperties = {
    transform: 'scale(1.05)',
    background: 'linear-gradient(135deg, #5f7480 0%, #024059 100%)',
};
// component
const Card: React.FC<CardProps> = ({ isMobile, animate, setShowCardForm, cards, setCardSelected, loadingCards }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

    // styles
    const scrollableContentStyles = {
        ...authStyles.commonStyles.scrollableContent,
        paddingTop: isMobile ? 0 : authStyles.commonStyles.scrollableContent.paddingTop,
    };

    const formPartStyles = {
        ...authStyles.commonStyles.formPart,
        justifyContent: isMobile ? 'flex-start' : authStyles.commonStyles.formPart.justifyContent,
        transform: isMobile ? 'translateX(0%)' : (animate ? 'translateX(0%)' : 'translateX(-100%)'),
        paddingBottom: isMobile ? '2rem' : 0,
    };



    return (
        <div style={formPartStyles}>
            <div style={scrollableContentStyles}>
                <h2 style={authStyles.commonStyles.center}>Cards</h2>
                {!loadingCards && cards.length > 0 ?
                    (<>
                        <p>Click on a card to delete it</p>
                        <div style={{ ...authStyles.commonStyles.center, ...authStyles.commonStyles.buttonContent, width: isMobile ? '50%':'20%', marginBottom: '20px' }}>
                            <button type="button" className="buttonBlack" onClick={() => setShowCardForm(true)}>Add Card</button>
                        </div>
                        <div style={{overflow: 'auto', maxHeight: '350px', margin: '20px'}}>
                        {
                            cards.map((card: any, index: number) => {
                                return (
                                    <div
                                        key={index}
                                        style={{ ...cardStyles, ...(hoveredIndex === index ? hoveredCardStyles : {}) }}
                                        onMouseEnter={() => setHoveredIndex(index)}
                                        onMouseLeave={() => setHoveredIndex(null)}
                                        onClick={() => setCardSelected(card)}
                                    >
                                        <div style={{ fontSize: '18px', marginBottom: '10px' }}>{card.brand}</div>
                                        <div style={{ position: 'absolute', bottom: '20px', left: '20px' }}>xxxx xxxx xxxx {card.last4}</div>
                                        <div style={{ position: 'absolute', bottom: '20px', right: '20px' }}>{card.exp_month}/{card.exp_year}</div>
                                    </div>
                                )
                            })
                        }
                        </div>
                    </>) : loadingCards ?
                    (<>
                        <h3>Loading cards...</h3>
                        <p>Please wait a moment</p>
                    </>) :
                    (<>
                        <p style={{ textAlign: 'center', width: '80%' }}>No cards added yet,<br />please click on the card image to add a new card</p>
                        <div
                            style={{ ...cardStyles, height: '180px', ...(isHovered ? hoveredCardStyles : {}) }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            onClick={() => setShowCardForm(true)}
                        >
                            <div style={{ fontSize: '18px', marginBottom: '10px' }}>Full Name</div>
                            <div style={{ position: 'absolute', bottom: '20px', left: '20px' }}>0000 0000 0000 0000</div>
                            <div style={{ position: 'absolute', bottom: '20px', right: '20px' }}>DD/MM</div>
                        </div>
                    </>)
                }
            </div>
        </div>
    )
}

interface CardFormProps {
    isMobile: boolean;
    animate: boolean;
    setShowCardForm: (value: boolean) => void;
    setLoading: (value: boolean) => void;
    stripeId: string;
    stripe: any;
    elements: any;
}

const CardForm: React.FC<CardFormProps> = ({ isMobile, animate, setShowCardForm, setLoading, stripeId, stripe, elements }) => {
    // styles
    const styles: { [key: string]: CSSProperties } = {
        // modal
        modalOverlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            opacity: 0,
            transition: 'opacity 0.3s',
        },
        modalContent: {
            backgroundColor: 'white',
            padding: '20px',
            maxWidth: '500px',
            width: '80%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '10px',
            transform: 'translateY(50px)',
            opacity: 0,
            transition: 'transform 0.3s, opacity 0.3s',
        },
        closeButton: {
            position: 'absolute',
            right: '10px',
            top: '10px',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            fontSize: '20px'
        },
        modalImage: {
            maxWidth: '50%',
            height: 'auto',
        },
        container: {
            width: '80%',
            padding: '20px',
            boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
            borderRadius: '8px',
            margin: '0 auto',
            backgroundColor: 'white',
        },
        label: {
            fontSize: '14px',
            color: '#555',
            marginBottom: '8px',
            display: 'block',
        },
        cardElementWrapper: {
            border: '1px solid #dcdcdc',
            borderRadius: '4px',
            padding: '8px 12px',
            marginBottom: '20px',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'center',
        },
        cardsMainContainer: {
            maxHeight: '300px',
            width: '70%',
            overflowY: 'auto',
            marginBottom: '20px',
            marginTop: '20px',

        },
        cardsContainer: {
            display: 'flex',
            flexDirection: 'row',
            width: 'auto',
            justifyContent: 'space-around',
            alignItems: 'center',
            border: '1px solid #dcdcdc',
            borderRadius: '4px',
            marginBottom: '10px',
            cursor: 'pointer',
            marginRight: '10px',
            marginLeft: '10px',
        }
    };
    const inputStyles = {
        ...authStyles.commonStyles.input,
        width: isMobile ? '90%' : '96%',
        fontSize: isMobile ? '20px' : authStyles.commonStyles.input.fontSize,
    };
    const formPartStyles = {
        ...authStyles.commonStyles.formPart,
        justifyContent: isMobile ? 'flex-start' : authStyles.commonStyles.formPart.justifyContent,
        transform: isMobile ? 'translateX(0%)' : (animate ? 'translateX(0%)' : 'translateX(-100%)'),
        paddingBottom: isMobile ? '2rem' : 0,
    };
    // states
    const [formData, setFormData] = useState<any>({
        fullName: '',
    });
    // attach payment method to customer
    const attachPaymentMethodToCustomer = async (paymentMethodId: string, customerId: string) => {
        try {
            setLoading(true);
            const data = {
                "payment_method_id": paymentMethodId,
                "customer_id": customerId
            }
            const paymentMethod = await fluxRestApi.attachPaymentMethodToCustomer(data);
            if (paymentMethod) {
                setLoading(false);
                return;
            } else {
                throw new Error('Error attaching payment method');

            }
        } catch (error) {
            setLoading(false);
            console.error('Error attaching payment method:', error);
            throw error;
        }
    };

    // create payment method
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!formData?.fullName) {
            showToast('Please enter your full name.', 'error');
            return;
        }
        // Validate the name for letters, spaces, hyphens, and apostrophes
        if (!/^[a-zA-Z-' ]+$/.test(formData?.fullName)) {
            showToast('Please enter a valid name. Only letters, hyphens, apostrophes, and spaces are allowed.', 'error');
            return;
        }

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            console.log("Stripe.js has not yet loaded.");
            showToast('Something went wrong. Please try again later.', 'error');
            return;
        }

        try {
            setLoading(true);
            const cardNumberElement = elements.getElement(CardNumberElement);
            const cardExpiryElement = elements.getElement(CardExpiryElement);
            const cardCvcElement = elements.getElement(CardCvcElement);

            if (cardNumberElement && cardExpiryElement && cardCvcElement) {
                const { error, paymentMethod } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardNumberElement,
                    billing_details: {
                        name: formData.fullName,
                    },
                });

                if (error) {
                    console.error(error);
                    showToast('There was an error with your card details. Please check and try again.', 'error');
                    // Handle this error in a user-friendly manner
                    setLoading(false);
                } else {
                    console.log('PaymentMethod', paymentMethod);
                    setLoading(false);
                    await attachPaymentMethodToCustomer(paymentMethod?.id, stripeId);
                    setShowCardForm(false);
                    showToast('Card added successfully.', 'success');
                }
            } else {
                console.log("Card elements are not fully loaded or there's an error.");
                showToast('Something went wrong. Please try again.', 'error');
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
            showToast('Something went wrong. Please try again.', 'error');
        }
    };


    return (
        <div style={formPartStyles}>
            <h2 style={authStyles.commonStyles.center}>Add Card</h2>
            <form name="CreditCardForm" style={authStyles.commonStyles.formLogin} onSubmit={handleSubmit}>
                <div style={{ width: isMobile ? '90%' : '80%', padding: '10px' }}>
                    <label style={{ ...styles.label, display: 'flex', flexDirection: 'column' }}>
                        Full Name:
                        <input
                            type="text"
                            name="fullName"
                            value={formData.fullName}
                            onChange={(e) => setFormData({ ...formData, fullName: e.target.value })}
                            required
                            style={inputStyles}
                            placeholder="Full Name" autoComplete="cc-name"
                        />
                    </label>
                    <label style={styles.label}>
                        Card Number:
                        <div style={styles.cardElementWrapper}>
                            <CardNumberElement options={{ style: { base: { fontSize: isMobile ? '20px' : '16px', color: '#333' } } }} />
                        </div>
                    </label>

                    <label style={styles.label}>
                        Expiration Date:
                        <div style={styles.cardElementWrapper}>
                            <CardExpiryElement options={{ style: { base: { fontSize: isMobile ? '20px' : '16px', color: '#333' } } }} />
                        </div>
                    </label>

                    <label style={styles.label}>
                        CVC:
                        <div style={styles.cardElementWrapper}>
                            <CardCvcElement options={{ style: { base: { fontSize: isMobile ? '20px' : '16px', color: '#333' } } }} />
                        </div>
                    </label>
                </div>
                <div style={{ ...authStyles.commonStyles.center, ...authStyles.commonStyles.buttonContent }}>
                    <button type="submit" className="buttonBlack">Add Card & Confirm</button>
                </div>
            </form>
            <div style={{ ...authStyles.commonStyles.center, ...authStyles.commonStyles.buttonContent }}>
                <button
                    type="button"
                    className="buttonBlack"
                    onClick={() => setShowCardForm(false)}
                    >Go Cards
                </button>
            </div>
        </div>
    );
}

export default Payment;