import React, { useState, useEffect, useCallback } from 'react';
import Menu_Header from '../images/Flux_Header.png';
import { useNavigate } from 'react-router-dom';
import point_green  from '../images/point_green.png';
import point_red from '../images/point_red.png';
import point_yellow from '../images/point_yellow_alt.png';
// hooks
import { useIsMobile } from '../hooks/useIsMobile';
import Loader from '../components/Loader';
import showToast from '../utils/toastHelpers';
// context
import { useAuth } from '../Context/AuthContext';
// services
import fluxRestApi from '../services/FluxRestApi';

const Account: React.FC = () => {
    const navigate = useNavigate();
    const isMobile = useIsMobile();
    const { contactData } = useAuth();

    // initial states

    const initialStateText = {
        contact: 'BEGIN',
        lisence: 'BEGIN',
        payment: 'BEGIN',
        agreements: 'BEGIN',
        insurance: 'BEGIN',
    }

    const initialStateColor = {
        contact: point_red,
        lisence: point_red,
        payment: point_red,
        agreements: point_red,
        insurance: point_red,
    }
    // states
    const [loading,setLoading] = useState(true);
    const [textContent, setTextContent] = useState<{
        contact: string;
        lisence: string;
        payment: string;
        agreements: string;
        insurance: string;
    }>(initialStateText);
    const [pointColor, setPointColor] = useState<{
        contact: string;
        lisence: string;
        payment: string;
        insurance: string;
        agreements: string;
    }>(initialStateColor);
    const [lisenceMessage, setLisenceMessage] = useState('');

    // check the status
    const handleNavigation = (path: string) => {
        try {
            setLoading(true);
            if (path === '/workingon') return showToast('This feature is still under development', 'info');
            navigate(path);
        } catch (error: any) {
            showToast(error?.message, 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleLicense = () => {
        if (lisenceMessage !== "") {
            showToast(lisenceMessage, 'info')
        } else {
            handleNavigation('/license')
        }
    };

    const handleInsurance = () => {
        if (contactData?.contact?.insurance_status === 'submitted') {
            showToast('Your insurance has been submitted', 'info')
        } else if (contactData?.contact?.insurance_status === 'in_review') {
            showToast('Your insurance is under review. Please wait for the final decision from the compliance team.', 'info')
        } else {
            handleNavigation('/insurance');
        }
    };

    const checkPaymentStatus = useCallback(async (stripeId: string) => {
        try {
            const response = await fluxRestApi.getPaymentMethods(stripeId);
            if (response?.data?.payment_methods?.length > 0) {
                setTextContent(prev => ({
                    ...prev,
                    payment: 'APPROVED',
                }))
                setPointColor(prev => ({
                    ...prev,
                    payment: point_green,
                }))
            } else {
                setTextContent(prev => ({
                    ...prev,
                    payment: 'BEGIN',
                }))
                setPointColor(prev => ({
                    ...prev,
                    payment: point_red,
                }))
            }
        } catch (error) {
            console.error("There was an error while trying to get your payment information, please try again", error);
        }
    }, []);

    //effects

    // give time to load the data
    useEffect(() => {
        // Show the loading for 1 second and then render the page
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        // Cleanup the timer on component unmount
        return () => clearTimeout(timer);
    }, []);

    // contact logic
    useEffect(() => {
        if (contactData && contactData.contact.first_name !== '-') {
            setTextContent(prev => ({
                ...prev,
                contact: 'UPDATE',
            }))
            setPointColor(prev => ({
                ...prev,
                contact: point_yellow,
            }))
        }
    }, [contactData]);

    // agreements logic
    useEffect(() => {
        if(contactData?.contact?.contact_privacyPolicy === 'Agree' && contactData?.contact?.contact_agreeTerms === 'Agree') {
            setTextContent(prev => ({
                ...prev,
                agreements: 'APPROVED',
            }))
            setPointColor(prev => ({
                ...prev,
                agreements: point_green,
            }))
        }
    }, [contactData]);

    // license logic
    useEffect(() => {
        if(contactData?.contact?.idscan_status.toLocaleLowerCase() === 'completed') {
            setTextContent(prev => ({
                ...prev,
                lisence: 'COMPLETED',
            }))
            setPointColor(prev => ({
                ...prev,
                lisence: point_green,
            }))
            setLisenceMessage("Your License has been completed")
        } else if(contactData?.contact?.idscan_status.toLocaleLowerCase() === 'pending') {
            setTextContent(prev => ({
                ...prev,
                lisence: 'PENDING',
            }))
            setPointColor(prev => ({
                ...prev,
                lisence: point_yellow,
            }))
            setLisenceMessage("Your License is under review. Please wait for the final decision from the compliance team.")
        } else {
            setTextContent(prev => ({
                ...prev,
                lisence: 'BEGIN',
            }))
            setPointColor(prev => ({
                ...prev,
                lisence: point_red,
            }))
            setLisenceMessage("")
        }
    }, [contactData, lisenceMessage]);

    // check the payment status
    useEffect(() => {
        if (contactData?.contact?.stripe_id) {
            checkPaymentStatus(contactData.contact.stripe_id);
        }
    }, [contactData, checkPaymentStatus]);

    // check the insurance status
    useEffect(() => {
        if (!contactData?.contact?.insurance_status) {
            setTextContent(prev => ({
                ...prev,
                insurance: 'BEGIN',
            }))
            setPointColor(prev => ({
                ...prev,
                insurance: point_red,
            }))
        } else if (contactData?.contact?.insurance_status === 'submitted') {
            setTextContent(prev => ({
                ...prev,
                insurance: 'SUBMITTED',
            }))
            setPointColor(prev => ({
                ...prev,
                insurance: point_green,
            }))
        } else if (contactData?.contact?.insurance_status === 'no_insurance') {
            setTextContent(prev => ({
                ...prev,
                insurance: 'NO INSURANCE',
            }))
            setPointColor(prev => ({
                ...prev,
                insurance: point_yellow,
            }))
        } else if (contactData?.contact?.insurance_status === 'in_review') {
            setTextContent(prev => ({
                ...prev,
                insurance: 'IN REVIEW',
            }))
            setPointColor(prev => ({
                ...prev,
                insurance: point_yellow,
            }))
        }
    }, [contactData]);


    return (
        <div style={{ ...styles.container, padding: isMobile ? '20px' : '0px' }}>
            <Loader loading={loading} />
            <div style={styles.header}>
                <img src={Menu_Header} alt="Flux Header" />
            </div>

            {/* CONTACT */}
            <div style={styles.itemList}>
                <span style={{ ...styles.itemLink, ...styles.leftText }}>CONTACT</span>
                <div style={styles.imageContainer}>
                    <img src={pointColor.contact} style={styles.image} alt="Contact" />
                </div>
                <span
                    onClick={() => handleNavigation('/contact')}
                    style={{ ...styles.itemLink, ...styles.pressableLink }}
                >
                    {textContent.contact}
                </span>
            </div>

            {/* LICENSE */}
            <div style={styles.itemList}>
                <span style={{ ...styles.itemLink, ...styles.leftText }}>LICENSE</span>
                <div style={styles.imageContainer}>
                    <img src={pointColor.lisence} style={styles.image} alt="License" />
                </div>
                <span
                    onClick={() => handleLicense()}
                    style={{ ...styles.itemLink, ...styles.pressableLink }}
                >
                    {textContent.lisence}
                </span>
            </div>

            {/* PAYMENT */}
            <div style={styles.itemList}>
                <span style={{ ...styles.itemLink, ...styles.leftText }}>PAYMENT</span>
                <div style={styles.imageContainer}>
                    <img src={pointColor.payment} style={styles.image} alt="Payment" />
                </div>
                <span
                    onClick={() => handleNavigation('/payment')}
                    style={{ ...styles.itemLink, ...styles.pressableLink }}
                >
                    {textContent.payment}
                </span>
            </div>

            {/* AGREEMENTS */}
            <div style={styles.itemList}>
                <span style={{ ...styles.itemLink, ...styles.leftText }}>AGREEMENTS</span>
                <div style={styles.imageContainer}>
                    <img src={pointColor.agreements} style={styles.image} alt="Register" />
                </div>
                <span
                    onClick={() => handleNavigation('/agreements')}
                    style={{ ...styles.itemLink, ...styles.pressableLink }}
                >
                    {textContent.agreements}
                </span>
            </div>

            {/* INSURANCE */}
            <div style={styles.itemList}>
                <span style={{ ...styles.itemLink, ...styles.leftText }}>INSURANCE</span>
                <div style={styles.imageContainer}>
                    <img src={pointColor.insurance} style={styles.image} alt="Register" />
                </div>
                <span
                    onClick={handleInsurance}
                    style={{ ...styles.itemLink, ...styles.pressableLink }}
                >
                    {textContent.insurance}
                </span>
            </div>

            {/* ON DEMAND */}
            <div style={styles.itemList}>
                <span style={{ ...styles.itemLink, ...styles.leftText }}>ON DEMAND</span>
                <div style={styles.imageContainer}>
                    <img src={point_red} style={styles.image} alt="On Demand" />
                </div>
                <span
                    onClick={() => handleNavigation('/workingon')}
                    style={{ ...styles.itemLink, ...styles.pressableLink }}
                >
                    COMING SOON
                </span>
            </div>

            {/* CHARGING */}
            <div style={styles.itemList}>
                <span style={{ ...styles.itemLink, ...styles.leftText }}>CHARGING</span>
                <div style={styles.imageContainer}>
                    <img src={point_red} style={styles.image} alt="Charging" />
                </div>
                <span
                    onClick={() => handleNavigation('/workingon')}
                    style={{ ...styles.itemLink, ...styles.pressableLink }}
                >
                    COMING SOON
                </span>
            </div>

        </div>
    );
}

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'space-between',
        height: '100vh',
        overflowX: 'hidden',
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginTop: '35px',
        marginBottom: '50px'
    },
    itemList: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '5px',
        marginBottom: '5px'
    },
    itemLink: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: 'black'
    },
    imageContainer: {
        width: '25%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    image: {
        width: '35px',
        height: '35px',
        objectFit: 'contain'
    },
    pressableLink: {
        textDecoration: 'underline',
        textAlign: 'right',
        width: '37.5%',
        maxWidth: '150px',
        cursor: 'pointer'
    },
    leftText: {
        width: '37.5%',
        maxWidth: '150px'
    }
};

export default Account;
