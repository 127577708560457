// it is a helper function to check if the email is valid or not used in the login page && signup page
const isValidEmail = (email: string): boolean => {
    const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
}

const isValidPassword = (password: string): boolean => {
    if (password === "") return false;
    const pattern = /^(?=.*\d)(?=.*[!@#$%^&*()_+-=[\]{}|\\:';"<>,.?/~`])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return pattern.test(password);
}

const isValidPhoneNumber = (phoneNumber: string): boolean => {
    if (phoneNumber === "") return false;
    const pattern = /^\d{10}$/;
    return pattern.test(phoneNumber);
}

export const authHelpers = {
    isValidEmail: isValidEmail,
    isValidPassword: isValidPassword,
    isValidPhoneNumber: isValidPhoneNumber
}