import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authStyles from '../styles/authStyles';
import showToast from '../utils/toastHelpers';
// context
import { useAuth } from "../Context/AuthContext";

interface AgreementsFormProps {
    isMobile: boolean;
    animate: boolean;
    setLoading: (value: boolean) => void;
    renderInAnotherPage?: boolean;
}

const AgreementsForm: React.FC<AgreementsFormProps> = ({ isMobile, animate, setLoading, renderInAnotherPage = false }) => {
    const { contactData, approveAgreements } = useAuth();
    const navigate = useNavigate();

    const initialAgreementsState = {
        contact_privacyPolicy: false,
        contact_agreeTerms: false,
    }
    //states
    const [AgreementsData, setAgreementsData] = useState(initialAgreementsState);
    const [currentContactData, setCurrentContactData] = useState<any>(null);

    // funtions
    const handleUpdate = async () => {
        if (!AgreementsData.contact_privacyPolicy || !AgreementsData.contact_agreeTerms) {
            return showToast('Please accept our agreements to complete your register', 'error');
        }
        if (!currentContactData) {
            return showToast('Please try again', 'error');
        }
        // update agreements
        try {
            setLoading(true);
            const data = {
                hubspot_id: contactData.contact.id,
                contact_privacyPolicy: 'Agree',
                contact_agreeTerms:'Agree',
            }
            const response = await approveAgreements( currentContactData, data );
            if (response.success) {
                showToast('Agreements updated successfully', 'success');
                if (!renderInAnotherPage) {
                    navigate('/?book=car');
                }
            } else {
                showToast(response.message, 'error');
            }
        } catch (error: any) {
            console.error(error);
            showToast('Sorry,There was an error while trying to upload the data, please try again', 'error');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (contactData?.contact) {
            setAgreementsData({
                contact_privacyPolicy: contactData.contact.contact_privacyPolicy === 'Agree' ? true : false,
                contact_agreeTerms: contactData.contact.contact_agreeTerms === 'Agree' ? true : false,
            });
            setCurrentContactData(contactData);
        }
    }, [contactData]);

    return (
        <div style={{ ...authStyles.commonStyles.formPart, transform: isMobile ? 'translateX(0%)' : (animate ? 'translateX(0%)' : 'translateX(-100%)')}}>
            <div style={authStyles.commonStyles.scrollableContent}>
                <h2 style={authStyles.commonStyles.center}>Agreements</h2>
                <p style={{...authStyles.commonStyles.center, width: '80%', marginBottom: '30px'}}>
                    Hey there! 👋 Before proceeding, please take a moment to review our agreements by clicking on the links. Once you're comfortable, go ahead and confirm by ticking the checkboxes. We appreciate your understanding!
                </p>
                <form id='contact-info' onSubmit={(e) => e.preventDefault()} style={authStyles.commonStyles.formLogin}>
                    {/* Privacy Policy Checkbox */}
                    <div style={{...authStyles.commonStyles.inputWrapper, width: isMobile ? '80%' : '60%'}}>
                        <input
                            type="checkbox"
                            checked={AgreementsData.contact_privacyPolicy}
                            onChange={() => setAgreementsData(prev => ({ ...prev, contact_privacyPolicy: !prev.contact_privacyPolicy }))}
                            style={authStyles.commonStyles.checkbox}
                        />
                        <label htmlFor="contact_privacyPolicy" style={authStyles.commonStyles.rigth}>I accept the <a href="https://www.fluxev.city/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></label>
                    </div>

                   {/* Terms & Conditions Checkbox */}
                    <div style={{...authStyles.commonStyles.inputWrapper, width: isMobile ? '80%' : '60%'}}>
                        <input
                            type="checkbox"
                            id="contact_agreeTerms"
                            checked={AgreementsData.contact_agreeTerms}
                            onChange={() => setAgreementsData(prev => ({ ...prev, contact_agreeTerms: !prev.contact_agreeTerms }))}
                            style={authStyles.commonStyles.checkbox}
                        />
                        <label htmlFor="contact_agreeTerms" style={authStyles.commonStyles.rigth}>I accept the <a href="https://www.fluxev.city/tos" target="_blank" rel="noopener noreferrer">Terms & Conditions</a></label>
                    </div>

                    <div style={{ ...authStyles.commonStyles.center, ...authStyles.commonStyles.buttonContent, margin: '20px' }}>
                        <button type="submit" className="buttonBlack" onClick={handleUpdate}>Update</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AgreementsForm;