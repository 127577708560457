import { useState, useEffect } from 'react';

export const useIsMobile = (breakpoint: number = 768): boolean => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= breakpoint);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= breakpoint);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [breakpoint]);

    return isMobile;
};

// for animations on desktop
export const useAnimateOnDesktop = (isMobile: boolean) => {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        // if mobile, don't animate
        if (isMobile) return;
        const timer = setTimeout(() => {
            setAnimate(true);
        }, 0);
        return () => clearTimeout(timer);
    }, [isMobile]);

    return animate;
};
