import React, { useState, ChangeEvent, useEffect, useRef } from 'react';
import styles from '../../styles/time-picker-styles.module.css';
import showToast from '../../utils/toastHelpers';

interface TimePickerMobileProps {
    onTimeChange: (time: string) => void;
    name: string;
    initialTime: string;
    disabled?: boolean;
}

const TimePickerMobile: React.FC<TimePickerMobileProps> = ({ onTimeChange, name, initialTime, disabled = false }) => {
    const sliderRef = useRef<HTMLInputElement>(null);


    // Total minutes in a day / step - 1 to end at 23:55
    const totalSteps = (24 * 60) / 5;

    // Convert the initial time to a number of 5-minute intervals from midnight
    const convertTimeToValue = (time: string) => {
        try {
            let [timePart, period] = time.split(' ');
            let [hours, minutes] = timePart.split(':').map(Number);

            // Adjust hours based on AM/PM
            if (period === 'PM' && hours < 12) hours += 12;
            if (period === 'AM' && hours === 12) hours = 0;

            // Round minutes to the nearest 5
            minutes = Math.round(minutes / 5) * 5;
            if (minutes === 60) {
                minutes = 0;
                hours = (hours + 1) % 24; // Increment hour and wrap around if needed
            }

            const totalMinutes = hours * 60 + minutes;
            return Math.round(totalMinutes / 5);
        } catch (e) {
            console.log(e);
            return 24 * 12; // Return 12:00 PM if an error occurs
        }
    };

    // State to hold the current value
    const [value, setValue] = useState(convertTimeToValue(initialTime));

    // Handle change of the input
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (disabled) {
            return;
        }
        const newValue = parseInt(e.target.value, 10);
        setValue(newValue);

        const percentage = (newValue / (totalSteps - 1)) * 100;
        if (sliderRef.current) {
            sliderRef.current.style.background = `linear-gradient(to right, #C7F100 0%, #C7F100 ${percentage}%, #ddd ${percentage}%, #ddd 100%)`;
        }

        const minutesSinceMidnight = newValue * 5;
        const hours = Math.floor(minutesSinceMidnight / 60);
        const minutes = minutesSinceMidnight % 60;
        onTimeChange(formatTime(hours, minutes)); // Call the callback function with the new time
    };

    // Convert current value to time
    const minutesSinceMidnight = value * 5;
    const hours = Math.floor(minutesSinceMidnight / 60);
    const minutes = minutesSinceMidnight % 60;

    // Format time for display
    const formatTime = (hours: number, minutes: number) => {
        const hours12 = hours % 12 || 12; // Convert 0 to 12 for 12 AM
        const amPm = hours < 12 ? "AM" : "PM";
        return `${hours12.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${amPm}`;
    };

    useEffect(() => {
        const newValue = convertTimeToValue(initialTime);
        setValue(newValue);

        const percentage = (newValue / (totalSteps - 1)) * 100;
        if (sliderRef.current) {
            sliderRef.current.style.background = `linear-gradient(to right, #C7F100 0%, #C7F100 ${percentage}%, #ddd ${percentage}%, #ddd 100%)`;
        }
    }, [initialTime, totalSteps]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto' }}>
            <div style={{ padding: '1rem', maxWidth: '250px'}}>
                <label style={{fontSize: 18}}>
                    {name}: {formatTime(hours, minutes)}
                    <input
                        ref={sliderRef}
                        type="range"
                        value={value}
                        onChange={handleChange}
                        min="0"
                        max={totalSteps - 1} // Adjust for steps
                        step="1"
                        style={{ width: '100%' }}
                        className={styles.slider}
                        onClick={() => disabled && showToast("cannot be changed", "info")}
                    />
                </label>
            </div>
        </div>
    );
};

export default TimePickerMobile;
