// firebase.ts
import { initializeApp } from '@firebase/app';
import { getDatabase } from '@firebase/database';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize the app
const app = initializeApp(firebaseConfig);

// Get database instances for each URL
const rtdbAvailableData = getDatabase(app, process.env.REACT_APP_FIREBASE_AVAILABLE_DATABASE_URL);
const rtdbFleetCars = getDatabase(app, process.env.REACT_APP_FIREBASE_FLEET_CARS_DATABASE_URL);
const rtdbTwilioData = getDatabase(app, process.env.REACT_APP_FIREBASE_TWILIO_DATABASE_URL);
const rtdbStations = getDatabase(app, process.env.REACT_APP_FIREBASE_STATIONS_DATASET_URL);

export { app, rtdbAvailableData, rtdbFleetCars, rtdbTwilioData, rtdbStations };
