import React, { useState, useEffect, CSSProperties, useCallback, useRef, useMemo } from 'react';
import showToast from '../utils/toastHelpers';
import fluxRestApi from '../services/FluxRestApi';
import { useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';
// Hooks
import { useIsMobile } from "../hooks/useIsMobile";
// Components
import Loader from '../components/Loader';
import FullScreenModal from  "../components/insurance/FullScreenModal";

// Get the token from the URL query parameters
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const secretKey = process.env.REACT_APP_SECRET_KEY || '';

const VerifyMyInsuranceMobileFluxApp: React.FC = () => {
    const isMobile = useIsMobile();

    // Params
    const query = useQuery();
    const token = query.get('token');

    // refs
    const widgetRef = useRef<HTMLElement | null>(null);

    // Memoized contact data to prevent unnecessary recalculations and re-renders
    const [contactData, setContactData] = useState<any>(null);
    const currentContactData = useMemo(() => contactData?.contact, [contactData]);
    // states
    const [loading, setLoading] = useState(false);
    const [dataRequest, setDataRequest] = useState<any>(null);
    const [publicToken, setPublicToken] = useState<any>(null);
    const [showWidget, setShowWidget] = useState(false);
    const [insuranceStatus, setInsuranceStatus] = useState<string | null>(null);
    const [email, setEmail] = useState<string>('');
    const [userId, setUserId] = useState<string>('');
    const [errorFetchingContactData, setErrorFetchingContactData] = useState<boolean>(false);
    const [isThankYouMessage, setIsThankYouMessage] = useState<boolean>(false);

    const updateFluxProperties = useCallback(async (data: any) => {
        try {
            if (!contactData?.contact?.id) {
                throw new Error('Contact ID is missing');
            }
            const response = await fluxRestApi.updatePartialFluxProperties(contactData.contact.id, data);
            if (response?.data?.contact) {
                console.log('Contact properties updated successfully');
                return response.data;
            } else {
                throw new Error('Error updating contact properties');
            }
        } catch (error) {
            console.error('Error updating contact properties:', error);
            return null;
        }
    }, [contactData]);

    const decodeToken = useCallback((token: any, secretKey: any) => {
        try {
            const bytes = CryptoJS.AES.decrypt(token, secretKey);
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

            if (!decryptedData.exp) {
                console.error('Token is missing an expiration date');
                return null;
            }

            if (decryptedData.exp && new Date().getTime() > decryptedData.exp) {
                console.error('Token has expired');
                return null;
            }

            return decryptedData;
        } catch (error) {
            console.error('Error decoding token:', error);
            return null;
        }
    }, []);

    const fetchData = useCallback(async () => {
        try {
            if (!email || !userId) {
                throw new Error('Email or userId is missing');
            }
            const response = await fluxRestApi.getContactDataByEmail(email);
            if (response?.data?.contact) {
                if (response.data.contact?.id === userId && response.data.contact?.email === email) {
                    setContactData(response.data);
                    setInsuranceStatus(response?.data?.contact?.insurance_status || '');
                    console.log('Contact data fetched successfully');
                } else {
                    throw new Error('Contact data does not match the user');
                }
            }
        } catch (error) {
            setErrorFetchingContactData(true);
            console.log("There was an error while trying to get the contact data");
        }
    }, [email, userId]);

    const createUserMeasureOne = async (contact: any) => {
        try {
            const body = {
                first_name: contact.first_name,
                last_name: contact.last_name,
                email: contact.email,
            };
            const response = await fluxRestApi.createIndividualMeasureOne(body);
            if (response?.data?.id) {
                const NewData = {
                    insurance_id: response.data.id,
                };
                const updateIndividualId = await updateFluxProperties(NewData);
                if (updateIndividualId?.contact) {
                    console.log("Individual ID updated successfully");
                }
                return response.data.id;
            } else {
                showToast("There was an error, please try again", "error");
                return null;
            }
        } catch (error: any) {
            console.error("There was an error while trying to create the user", error);
            showToast("There was an error, please try again", "error");
            return null;
        }
    };

    const createPublicToken = async (individualId: string) => {
        try {
            const data = {
                individual_id: individualId,
            };
            const response = await fluxRestApi.createPublicTokenMeasureOne(data);
            if (response?.data) {
                return response.data;
            } else {
                throw new Error("Error creating public token");
            }
        } catch (error) {
            console.error("Error creating public token:", error);
            throw new Error("Error creating public token");
        }
    };

    const handleValidation = async () => {
        try {
            if (currentContactData?.insurance_status === "submitted") {
                showToast("Insurance verification is already submitted", "info");
                return;
            }
            if (currentContactData?.insurance_status === "in_review") {
                showToast("Insurance verification is in review, please wait until the verification is completed", "info");
                return;
            }

            setLoading(true);
            let insuranceId = currentContactData?.insurance_id;
            if (!insuranceId) {
                const response = await createUserMeasureOne(currentContactData);
                if (!response) return;
                insuranceId = response;
            }
            const bodyDataRequest = {
                individual_id: insuranceId,
                type: "AUTO_INSURANCE_DETAILS",
            };
            const dataRequestResponse = await fluxRestApi.createDataRequestMeasureOne(bodyDataRequest);
            if (dataRequestResponse?.data?.data_request_id) {
                setDataRequest(dataRequestResponse.data.data_request_id);
                const publicToken = await createPublicToken(insuranceId);
                if (publicToken) {
                    setPublicToken(publicToken);
                    setLoading(false);
                    setShowWidget(true); // Show the modal with the widget
                } else {
                    throw new Error("Error creating public token");
                }
            } else {
                throw new Error("Error creating data request");
            }
        } catch (error) {
            setLoading(false);
            setDataRequest(null);
            console.error("Error creating user in measure one:", error);
            showToast("There was an error, please try again", "error");
        }
    };

    const handleIdontHaveInsurance = async () => {
        try {
            if (currentContactData?.insurance_status === "no_insurance") {
                showToast("Insurance status is already set to 'I don't have insurance'", "info");
                setIsThankYouMessage(true);
                return;
            }
            setLoading(true);
            const body = {
                insurance_status: "no_insurance",
            };
            const response = await updateFluxProperties(body);
            if (response?.contact) {
                setLoading(false);
                showToast("Insurance status updated successfully", "success");
                setInsuranceStatus("no_insurance");
                setIsThankYouMessage(true);
            } else {
                throw new Error("Error updating insurance status");
            }
        } catch (error) {
            setLoading(false);
            console.error("Error creating user in measure one:", error);
            showToast("There was an error, please try again", "error");
        }
    };

    const removeHtmlStyles = useCallback(() => {
        const htmlElement = document.documentElement;
        if (htmlElement) {
            htmlElement.removeAttribute("style");
        }
    }, []);

    const closeModal = useCallback(() => {
        setShowWidget(false);
        removeHtmlStyles();
        if (widgetRef.current) {
            widgetRef.current.remove();
        }
    }, [removeHtmlStyles]);

    const updateInsuranceStatus = useCallback(async () => {
        try {
            setLoading(true);
            const body = {
                insurance_status: "in_review",
            };
            const response = await updateFluxProperties(body);
            if (response?.contact) {
                setLoading(false);
                showToast("Insurance status updated successfully", "success");
                setInsuranceStatus("in_review");
            } else {
                throw new Error("Error updating insurance status");
            }
        } catch (error) {
            setLoading(false);
            console.error("Error creating user in measure one:", error);
            showToast("I'm sorry, there was an error, please try again", "error");
            setTimeout(() => {
                window.close();
            }, 2000);
        }
    }, [updateFluxProperties]);

    // Hide the top bar
    useEffect(() => {
        let display = "";
        let topPadding = "";
        const topBar = document.getElementById('TopBar');
        const mainComponent = document.getElementById('app-routes-component');
        if (topBar && mainComponent) {
            display = topBar.style.display;
            topBar.style.display = 'none';
            topPadding = mainComponent.style.paddingTop;
            mainComponent.style.paddingTop = '0px';
        }
        // Cleanup event listener on unmount
        return () => {
            if (topBar && mainComponent) {
                topBar.style.display = display;
                mainComponent.style.paddingTop = topPadding;
            }
        };
    }, []);

    // Fetch contact data if email and userId are present
    useEffect(() => {
        if (email && userId && !contactData) {
            fetchData();
        }
    }, [email, userId, contactData, fetchData]);

    // Decode the token and extract the email and userId
    useEffect(() => {
        if(token) {
            const decodedToken = decodeToken(token, secretKey);
            if (decodedToken) {
                setEmail(decodedToken.email);
                setUserId(decodedToken.userId);
            }
        }
    }, [token, decodeToken]);

    // display the widget
    useEffect(() => {
        if (publicToken && dataRequest && insuranceStatus !== "in_review" && insuranceStatus !== "submitted") {
            // Remove any existing widget before creating a new one
            if (widgetRef.current) {
                widgetRef.current.remove();
            }

            // Create and configure the m1-link widget
            const m1LinkElement = document.createElement("m1-link");
            const config = {
                access_key: publicToken.access_token,
                host_name: "api-stg.measureone.com",
                datarequest_id: dataRequest,
                branding: {
                    styles: {
                        primary_dark: "#186793",
                        primary_light: "#2e9ccb",
                        secondary_color: "#ffffff",
                        min_height: "700px",
                    },
                },
                options: {
                    display_profile: false,
                },
            };

            m1LinkElement.setAttribute("config", JSON.stringify(config));

            // Once the datasource is connected, remove the widget and show a thank you message
            m1LinkElement.addEventListener("datasourceConnected", () => {
                console.log("Datasource connected:");
                updateInsuranceStatus();
                closeModal();
                setIsThankYouMessage(true);
            });

            m1LinkElement.addEventListener("currentProgress", (event: any) => {
                if (event?.detail?.data?.progress === 100) {
                    console.log("Verification completed:");
                    showToast("The verification process has been completed", "success");
                    updateInsuranceStatus();
                    closeModal();
                    setIsThankYouMessage(true);
                }
            });

            m1LinkElement.addEventListener("tokenExpired", () => {
                console.log("Token Expired");
                showToast("The token has expired, please try again", "error");
                closeModal();
            });

            m1LinkElement.addEventListener("exitRequested", () => {
                console.log("Exit Requested");
                showToast("The verification process has been cancelled", "info");
                closeModal();
            });

            // Append the widget to the modal container and store the reference
            const container = document.getElementById("m1-link-container");
            if (container) {
                container.appendChild(m1LinkElement);
                widgetRef.current = m1LinkElement;
            }
        }
    }, [publicToken, dataRequest, updateInsuranceStatus, insuranceStatus, closeModal]);

    const styles: { [key: string]: CSSProperties } = {
        container: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "20px",
            height: "100vh",
            backgroundColor: "#f0f2f5",
            overflowY: "auto",
            overflowX: "hidden",
        },
        backButton: {
            position: "absolute",
            top: "20px",
            left: "20px",
            border: "none",
            padding: '10px',
            cursor: "pointer",
            backgroundColor: 'transparent',
            fontSize: "1em",
            color: 'black',
        },
        buttonsContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
            marginTop: "20px",
        },
        WidgetContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            paddingBottom: "77px",
        },
        m1LinkContainer: {
            width: "auto",
            backgroundColor: "#ffffff",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            height: "auto",
            marginBottom: "130px",
        },
        InsuranceContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
            maxWidth: "500px",
            height: "400px",
            margin: "20px 0",
            backgroundColor: "#ffffff",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            position: "relative",
        },
        instructions: {
            marginTop: "20px",
            fontSize: "1em",
            color: "#555",
            padding: "20px",
            textAlign: "center",
        },
        button: {
            width: "40%",
            padding: "10px",
            marginTop: "10px",
            marginBottom: "10px",
            border: "none",
            borderRadius: "5px",
            fontSize: isMobile ? "0.7em" : "1em",
            cursor: "pointer",
            backgroundColor: "#C7F100",
            color: "black",
            transition: "background-color 0.3s ease",
        },
        buttonHover: {
            backgroundColor: "#b2d700",
        },
    };


    // If the email and userId are not present in the URL, show an error message
    if (!email || !userId || errorFetchingContactData) {
        return (
            <div style={styles.container}>
                <h3>Error</h3>
                <div style={styles.instructions}>
                    <p>Sorry, the verification process cannot be started. Please contact support for further assistance.</p>
                </div>
            </div>
        );
    }
    interface ButtonsNavigationProps {
        text: string;
        click: () => void;
    }
    const ButtonsNavigation: React.FC<ButtonsNavigationProps> = ({ text, click }) => {
        const [isHovered, setIsHovered] = useState(false);

        const handleMouseEnter = useCallback(() => setIsHovered(true), []);
        const handleMouseLeave = useCallback(() => setIsHovered(false), []);
        const handleClick = useCallback(() => click(), [click]);

        return (
            <button
                style={{ ...styles.button, ...(isHovered ? styles.buttonHover : {}) }}
                onClick={handleClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {text}
            </button>
        );
    };

    return (
        <div style={styles.container}>
            <Loader loading={loading} />
            {isThankYouMessage && (
                <div style={styles.InsuranceContainer}>
                    <div style={styles.instructions}>
                        <h3 style={{ color: "black" }}>Thank you</h3>
                        <p> Please, go back to the app</p>
                    </div>
                </div>
            )}
            {(insuranceStatus === "submitted" || insuranceStatus === "in_review") && !isThankYouMessage && (
                <div style={styles.InsuranceContainer}>
                    <div style={styles.instructions}>
                        <h3 style={{ color: "black" }}>Your insurance is {insuranceStatus === "submitted" ? "submitted" : "under review"}</h3>
                        {insuranceStatus === "in_review" && <p> Please, go back to the flux app. We will let you know when the verification result is submitted</p>}
                        {insuranceStatus === "submitted" && <p> Thanks, nothing to do here</p>}
                    </div>
                    <div style={styles.buttonsContainer}>
                        <ButtonsNavigation text="Close Window" click={() => window.close()} />
                    </div>
                </div>
            )}
            {!showWidget && insuranceStatus !== "in_review" && insuranceStatus !== "submitted" && !isThankYouMessage && (
                <div style={styles.InsuranceContainer}>
                    {currentContactData ? (
                        <>
                            <button style={styles.backButton} onClick={() => window.close()}>
                                X
                            </button>
                            <div style={styles.instructions}>
                                <h3 style={{ color: "black" }}>Insurance verification</h3>
                                <p>please click on the button "Start Validation" to submit your insurance details</p>
                            </div>
                            <div style={styles.buttonsContainer}>
                                <ButtonsNavigation text="Start Validation" click={handleValidation} />
                                <ButtonsNavigation text=" I don't have insurance" click={handleIdontHaveInsurance} />
                            </div>
                        </>
                    ) : (
                        <p style={{ color: "black" }}>loading...</p>
                    )}
                </div>
            )}
            <FullScreenModal show={showWidget} onClose={closeModal}>
                <div id="m1-link-container" style={{ width: "100%", height: "100%"}} />
            </FullScreenModal>
        </div>
    );
};

export default VerifyMyInsuranceMobileFluxApp;
