// BarContext.tsx
import React, { createContext, useState, useContext } from 'react';

interface BarContextType {
    isBarOpen: boolean;
    openBar: () => void;
    closeBar: () => void;
    toggleBar: () => void;
}

const BarContext = createContext<BarContextType>({
    isBarOpen: false,
    openBar: () => { },
    closeBar: () => { },
    toggleBar: () => { },
});

export const BarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isBarOpen, setBarOpen] = useState(false);

    const openBar = () => setBarOpen(true);
    const closeBar = () => setBarOpen(false);
    const toggleBar = () => setBarOpen((prev) => !prev);

    return (
        <BarContext.Provider value={{ isBarOpen, openBar, closeBar, toggleBar }}>
            {children}
        </BarContext.Provider>
    );
};

export const useBar = () => useContext(BarContext);
