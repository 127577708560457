import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
// assets
import noImage from '../../images/no-image-available.png';
import carIcon from '../../images/icons/car-icon.png';
import moneyIcon from '../../images/fees.png';
import addressIcon from '../../images/fa-solid_directions.png';
import placeHolder from '../../images/place-holder.png';
// styles
import styles from '../../styles/stationCarsModal.module.css';

interface StationCarsModalProps {
    isOpen: boolean;
    onClose: () => void;
    selectedFeature: any;
    isMobile: boolean;
    handleBookNow: (arg: any) => void;
}

const StationCarsModal = ({ isOpen, onClose, selectedFeature, isMobile, handleBookNow }: StationCarsModalProps) => {
    if (!isOpen || !selectedFeature) return null;

    const cars = selectedFeature.properties.cars;
    const station = selectedFeature.properties;

    const handleBook = (car: any) => {
        onClose();
        handleBookNow(car);
    }

    return (
        <div className={styles.modalOverlay} onClick={onClose}>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                <button className={styles.closeButton} onClick={onClose}>X</button>
                <div className={styles.carGrid}>
                    {cars.map((car: any) => (
                        <div key={car.VIN || 'No VIN'} className={isMobile ? styles.cardMobile : styles.card}>
                            <LazyLoadImage
                                src={`https://fiat.b-cdn.net/${car.ID}.jpeg`}
                                alt={car.name}
                                effect="blur"
                                placeholderSrc={placeHolder}
                                className={styles.carImage}
                                onError={(e) => {
                                    e.currentTarget.src = noImage;
                                }}
                            />
                            <div className={styles.content}>
                                <h3 className={styles.carType}>{`${car?.Make || 'Make not found'} ${car?.Model || 'Model not found'} - ${car?.Color || 'Color not found'}`}</h3>
                                <div className={styles.IconContainer}>
                                    <LazyLoadImage
                                        src={carIcon}
                                        alt="Station Icon"
                                        effect="blur"
                                        placeholderSrc={placeHolder}
                                        className={styles.icon}
                                    />
                                    <span className={styles.dataCar}>{station?.flux_name || 'No Station'}</span>
                                </div>
                                <div className={styles.IconContainer}>
                                    <LazyLoadImage
                                        src={addressIcon}
                                        alt="Location Icon"
                                        effect="blur"
                                        placeholderSrc={placeHolder}
                                        className={styles.icon}
                                    />
                                    <span className={styles.dataCar}>{station?.address || 'No address'}</span>
                                </div>
                                <div className={styles.IconContainer}>
                                    <LazyLoadImage
                                        src={moneyIcon}
                                        alt="Money Icon"
                                        effect="blur"
                                        placeholderSrc={placeHolder}
                                        className={styles.icon}
                                    />
                                    <span className={styles.dataCar}>${car?.bundeeProperties?.price_per_hr || '(No price found)'}/day</span>
                                </div>
                                {/* <div className={styles.seeDetails} onClick={() => console.log('See Details', car)}>
                                    See Details
                                </div> */}
                                <div className={isMobile ? styles.tripDetailsMobile : styles.tripDetails}>
                                    <button className={styles.buttonBook} onClick={() => handleBook(car)}>Book Now</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default StationCarsModal;
