// src/utils/getCID.ts
export const getCID = () => {
    const name = "trakdesk_cid=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i].trim();
        if (c.indexOf(name) === 0) {
            return JSON.parse(c.substring(name.length, c.length)).cid;
        }
    }
    return null;
};
