import React, { CSSProperties, useState, useEffect, useMemo, useRef } from 'react';
// import showToast from '../utils/toastHelpers';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
// hooks
import { useIsMobile } from '../hooks/useIsMobile';
// context
import { useAuth } from '../Context/AuthContext';
// assests
import point_green_url from '../images/point_green.png';
import point_red_url from '../images/point_red.png';
import point_yellow_alt_url from '../images/point_yellow_alt.png';
import point_station_WithCar_url from '../images/icons/car_station.png';
import car_icon from '../images/icons/car-icon.png';
import station_icon from '../images/icons/station-icon.png';
// components




const renderLoading = (status: Status): React.ReactElement => {
    switch (status) {
        case Status.LOADING:
            return <h1>Loading...</h1>;
        case Status.FAILURE:
            return <h1>Error loading maps</h1>;
        default:
            return <></>;
    }
};


interface MapProps {
    initialLatitude: number;
    initialLongitude: number;
    initialZoom: number;
    width?: string | number;
    height?: string | number;
    dataset: any;
    onFeatureClick?: (feature: any) => void;
    switchContainer: 'car' | 'station';
    setSwitchContainer: (mode: 'car' | 'station') => void;
    selectedFeature: any;
}


const NewMap: React.FC<MapProps> = ({ initialLatitude, initialLongitude, initialZoom, width, height, dataset, onFeatureClick, switchContainer, setSwitchContainer, selectedFeature }) => {
    // context
    const { user } = useAuth();
    // states
    const center = useMemo(() => ({ lat: initialLatitude, lng: initialLongitude }), [initialLatitude, initialLongitude]);

    // Function to determine which icon to use based on the feature's status
    const determineIcon = (status: string) => {
        switch (status) {
            case 'active':
                return point_green_url;
            case 'unknown':
                return point_yellow_alt_url;
            case 'down / off':
                return point_red_url;
            default:
                return point_yellow_alt_url; // A default icon if status is not recognized
        }
    };

    return (
        <>
            <Wrapper apiKey={process.env.REACT_APP_GOOGLE_API_KEY || ''} render={renderLoading}>
                <MapComponent
                    center={center}
                    zoom={initialZoom}
                    onFeatureClick={onFeatureClick}
                    dataset={dataset}
                    determineIcon={determineIcon}
                    width={width}
                    height={height}
                    user={user}
                    setSwitchContainer={setSwitchContainer}
                    switchContainer={switchContainer}
                    selectedFeature={selectedFeature}
                />
            </Wrapper>
        </>
    )
}

const MapComponent = ({ center, zoom, onFeatureClick, dataset, determineIcon, width, height, user, setSwitchContainer, switchContainer, selectedFeature }: any) => {
    const ref = useRef<HTMLDivElement>(null);
    const markerRefs = useRef<google.maps.marker.AdvancedMarkerElement[]>([]);
    // states
    const [map, setMap] = useState<google.maps.Map>();
    // hooks
    const isMobile = useIsMobile();


    // funtions
    const handleSwitchContainer = (mode: 'car' | 'station') => {
        // if (!user) return showToast('Please login to use this feature', 'error');
        setSwitchContainer(mode);
        // showToast(mode, "success");
    };
    //effects
    useEffect(() => {

        if (ref.current && !map) {
            const newMap = new window.google.maps.Map(ref.current, {
                center,
                zoom,
                disableDefaultUI: false,
                zoomControl: true,
                mapTypeControl: false,
                mapId: '6d5ee41397cfdef1',
            });
            setMap(newMap);

        }
        if (map) {
             // if the marker is not clicked then onFeatureClick will be null
            map.addListener('click', () => {
                onFeatureClick && onFeatureClick(null);
            });
        }
    }, [ref, map, center, zoom, onFeatureClick]);

    useEffect(() => {
        if (!map || !dataset) return;

       // Function to clear all existing markers
        const clearMarkers = () => {
            markerRefs.current.forEach(marker => {
                if (marker.map) { // Check if the marker is still on the map
                    marker.map = null; // Remove the marker from the map
                }
            });
            markerRefs.current = [];
        };

        const loadMarkers = async () => {
            clearMarkers();

            const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

            dataset.features.forEach((feature: any) => {
                if (switchContainer === 'station') {
                    const Img = document.createElement('img');
                    Img.src =  determineIcon(feature.properties.status);
                    Img.style.width = '25px';

                    const marker = new AdvancedMarkerElement({
                        position: { lat: feature.geometry.coordinates[1], lng: feature.geometry.coordinates[0] },
                        map: map,
                        title: feature.properties.flux_name,
                        content: Img

                    });

                    marker.addListener('click', () => {
                        onFeatureClick && onFeatureClick(feature);
                    });
                    markerRefs.current.push(marker);
                }
                else if  (switchContainer === 'car' && feature?.properties?.numCars > 0) {
                    const Img = document.createElement('img');
                    Img.src = point_station_WithCar_url;
                    Img.style.width = '55px';

                    const marker = new AdvancedMarkerElement({
                        position: { lat: feature.geometry.coordinates[1], lng: feature.geometry.coordinates[0] },
                        map: map,
                        title: feature.properties.flux_name,
                        content: Img

                    });

                    marker.addListener('click', () => {
                        onFeatureClick && onFeatureClick(feature);
                    });
                    markerRefs.current.push(marker);
                }
            });
        };

        loadMarkers();

    }, [map, dataset, determineIcon, onFeatureClick, switchContainer]);

    return (
        <>
            <div ref={ref} style={{ width, height }} />
            <SwitchContainer
                mode={switchContainer}
                handleSwitchContainer={handleSwitchContainer}
                isMobile={isMobile}
                selectedFeature={selectedFeature}
            />
        </>
    );
};



interface SwitchContainerProps {
    mode: 'car' | 'station';
    handleSwitchContainer: (mode: 'car' | 'station') => void;
    isMobile: boolean;
    selectedFeature: any;
}

const SwitchContainer: React.FC<(SwitchContainerProps)> = ({
    mode, handleSwitchContainer, isMobile, selectedFeature
}) => {

    // styles
    const styles: { [key: string]: CSSProperties } = {
        containerSwitch: {
            position: 'absolute',
            right: isMobile ? '2%' : '6%',
            top: '90px',
            display: 'flex',
            width:  isMobile ? 60 : 70,
            height: isMobile ? 30 : 40,
            backgroundColor: '#D8D8D8',
            boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.35)',
            borderRadius: 10,
            zIndex: selectedFeature ? 0 : 1000,
        },
        imageContainer: {
            width:  isMobile ? 30 : 40,
            height: isMobile ? 30: 40,
            boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.35)',
            borderRadius: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: mode === 'car' ? 'white' : 'transparent',
            cursor: 'pointer',
        },
        imageContainerStation: {
            backgroundColor: mode === 'station' ? 'white' : 'transparent',
        },
        image: {
            objectFit: 'contain',
        },
    };
    return (
        !selectedFeature ?
            <div style={styles.containerSwitch}>
                <div
                    style={styles.imageContainer}
                    onClick={() => handleSwitchContainer('car')}
                >
                    <img src={car_icon} alt="Car" style={styles.image} />
                </div>
                <div
                    style={{ ...styles.imageContainer, ...styles.imageContainerStation }}
                    onClick={() => handleSwitchContainer('station')}
                >
                    <img src={station_icon} alt="Station" style={styles.image} />
                </div>
            </div>
            : null
    )
}

export default React.memo(NewMap);