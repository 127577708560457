import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
// asssets
import noImage from '../images/no-image-available.png';
import carIcon from '../images/icons/car-icon.png';
import moneyIcon from '../images/fees.png';
import addressIcon from '../images/fa-solid_directions.png'
import placeHolder from '../images/place-holder.png';
import styles from '../styles/vehiclesStyles.module.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
// Utils
import showToast from '../utils/toastHelpers';
// services
import bundeeRestApi from '../services/BundeeRestApi';
// Components
import Loader from '../components/Loader';
import CarDetailsModal from '../components/vehicles/CarDetailsModal';
import VehiclesViewOnMap from '../components/vehicles/VehiclesViewOnMap';
import StationCarsModal from '../components/vehicles/StationCarsModal';
// hooks
import { useIsMobile } from '../hooks/useIsMobile';
// Firebase
import { ref, get } from '@firebase/database';
import { rtdbFleetCars, rtdbStations } from '../firebase/firebase';

const hostId = process.env.REACT_APP_BUNDEE_HOST_ID

const Vehicles: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useIsMobile();

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const queryParams = new URLSearchParams(location.search);
    const startDate = queryParams.get('startDate');
    const endDate = queryParams.get('endDate');
    const pickUpTime = queryParams.get('pickUpTime');
    const returnTime = queryParams.get('returnTime');

    const [loading, setLoading] = useState(false);
    const [fleetCarsIndexed, setFleetCarsIndexed] = useState<any>({});
    const [availableCars, setAvailableCars] = useState<any>([]);
    const [selectedCar, setSelectedCar] = useState<any>(null);
    const [error, setError] = useState('');
    const [showViewOnMap, setShowViewOnMap] = useState(false);
    const [stationsRTDB, setStationsRTDB] = useState<any>([]);
    const [dataset, setDataset] = useState<any>(null);
    const [selectedFeature, setSelectedFeature] = useState<any>(null);
    const [loadingBooking, setLoadingBooking] = useState(false);

    // Funtions

    const formatDateTime = (date: string, time: string) => {
        return moment.utc(`${date} ${time}`, 'YYYY-MM-DD hh:mm:ss').toISOString();
    };
    const formatDate = (date: string, time: string) => {
        return moment(`${date} ${time}`, 'YYYY-MM-DD hh:mm:ss').format('dddd, MMMM Do YYYY, h:mm A');
    };
    const handleChooseDates = () => {
        navigate('/booking');
    };

    const handleViewOnMap = () => {
        setShowViewOnMap((prev) => !prev);
    }

    // funtions
    const handleFeatureClick = (feature: any) => {
        setSelectedFeature(feature);
    };

    const handleBookNow = async(car: any) => {
        if (!startDate || !endDate || !pickUpTime || !returnTime) {
            showToast('Please choose the date and time to continue', 'error');
            navigate('/booking');
            return;
        }

        const isAfter7pm = handleIsAfter7pm();
        if (isAfter7pm) {
            const message ='Pick-up time cannot be earlier than tomorrow at 12 PM'
            showToast(message, 'error');
            navigate('/booking');
            return;
        }

        const isBefore8am = handleIsBefore8am();
        if (isBefore8am) {
            const message = 'Pick-up time cannot be earlier than today at 12 PM';
            showToast(message, 'error');
            navigate('/booking');
            return;
        }

        const isBeforeMinTime = handleIfPickUpTimeIsBeforeMinTime();
        if (isBeforeMinTime) {
            showToast('Pick-up time cannot be before than Minimun Time', 'error');
            navigate('/booking');
            return;
        };

        try {
            setLoadingBooking(true);
            // validate the availability of the car before to send the user to the booking page
            // console.log(car);
            const response = await bundeeRestApi.getAvailabilityDatesByVehicleId(car?.bundeeProperties?.id);
                if (response?.data?.errorCode === '0') {
                    const unAvailableDates = response.data?.unAvailabilityDate?.map((date: any) => moment.utc(date).tz(userTimezone).toISOString());
                    // check if the selected dates are available
                    const selectedStartDateTime =  moment.tz(`${startDate} ${pickUpTime}`, 'YYYY-MM-DD h:mm:ss', userTimezone).utc().toISOString();
                    const selectedEndDateTime = moment.tz(`${endDate} ${returnTime}`, 'YYYY-MM-DD h:mm:ss', userTimezone).utc().toISOString();

                    // console.log(response.data, 'response.data');
                    // console.log(unAvailableDates, 'unAvailableDates');
                    // console.log(selectedStartDateTime, 'selectedStartDateTime');
                    // console.log(selectedEndDateTime, 'selectedEndDateTime');

                    const isAvailable = unAvailableDates.every((date: any) => {
                        return moment.utc(date).isBefore(moment.utc(selectedStartDateTime)) || moment.utc(date).isAfter(moment.utc(selectedEndDateTime));
                    });

                    if (isAvailable) {
                        setLoadingBooking(false);
                        showToast('The selected dates and car are available', 'success');
                        return navigate(`/booking/confirm?startDate=${startDate}&endDate=${endDate}&pickUpTime=${pickUpTime}&returnTime=${returnTime}&carId=${car?.bundeeProperties?.id}`);
                    } else {
                        setLoadingBooking(false);
                        showToast('The selected dates are not available', 'error');
                        return;
                    }

                } else {
                    setLoadingBooking(false);
                    showToast('Something went wrong, please try again', 'error');
                    return;
                }
        } catch (error) {
            console.error(error);
            showToast('An error occurred', 'error');
            setLoadingBooking(false);
        }

    };

    const handleIsAfter7pm = useCallback(() => {
        const selectedStartDateTime = moment(`${startDate} ${pickUpTime}`, 'YYYY-MM-DD HH:mm:ss');

        const now = moment();
        const isAfter7pm = now.hour() >= 19;

        if (!isAfter7pm) return false;

        // Set the minimum allowed pick-up date and time
        const minAllowedDateTime = moment().add(1, 'day').startOf('day').add(12, 'hours') // Tomorrow at 12 PM

        // Validate the selected pick-up time
        if (selectedStartDateTime.isBefore(minAllowedDateTime)) {
            return true
        };
        return false
    }, [startDate, pickUpTime]);

    const handleIsBefore8am = useCallback(() => {
        const selectedStartDateTime = moment(`${startDate} ${pickUpTime}`, 'YYYY-MM-DD HH:mm:ss');

        const now = moment();
        const isBefore8am = now.hour() < 8;

        if (!isBefore8am) return false;

        // Set the minimum allowed pick-up date and time
        const minAllowedDateTime = moment().startOf('day').add(12, 'hours') // Today at 12 PM

        // Validate the selected pick-up time
        if (selectedStartDateTime.isBefore(minAllowedDateTime)) {
            return true
        };
        return false
    }, [startDate, pickUpTime]);

    const handleIfPickUpTimeIsBeforeMinTime = useCallback(() => {
        const currentMinDateTime = moment().add(3, 'hours').add(10, 'minutes');
        const selectedStartDateTime = moment(`${startDate} ${pickUpTime}`, 'YYYY-MM-DD HH:mm:ss');

        if (selectedStartDateTime.isSame(currentMinDateTime, 'day') && selectedStartDateTime.isBefore(currentMinDateTime.subtract(10, 'minutes'))) {
            return true;
        }
        return false;
    }, [startDate, pickUpTime]);


    // Effects

    useEffect(() => {
        const getStationsFromRTDB = async () => {
            try {
                const stationsRef = ref(rtdbStations);
                const snapshot = await get(stationsRef);
                if (snapshot.exists()) {
                    return snapshot.val().features;
                } else {
                    console.error('No stations found');
                    return [];
                }
            } catch (error) {
                console.error(error);
                return [];
            }
        };

        const getCarsFromRTDB = async () => {
            const carsRef = ref(rtdbFleetCars);
            try {
                setLoading(true);
                const snapshot = await get(carsRef);
                const stations = await getStationsFromRTDB();
                if (snapshot.exists() && stations.length > 0) {
                    // available cars
                    const data = snapshot.val();
                    const activeCars = data.filter((car: any) => car.BundeeActive);
                    const indexedCars = activeCars.reduce((acc: any, car: any) => {
                        const station = stations.find((s: any) => s.properties.places_id === car.places_id);
                        if (station) {
                            acc[car.VIN] = { ...car, stationProperties: station.properties };
                        }
                        return acc;
                    }, {});
                    setFleetCarsIndexed(indexedCars);
                    setStationsRTDB(stations);

                    // console.log('Fleet Cars:', indexedCars);
                    setError('');
                } else {
                    setError('No cars found');
                }
                setLoading(false);
            } catch (error) {
                console.error(error);
                setError('An error occurred getting the cars');
                setLoading(false);
            }
        };

        const fetchData = async () => {
            try {
                if (!startDate || !endDate || !pickUpTime || !returnTime) {
                    showToast('Please choose the date and time to continue', 'error');
                    navigate('/booking');
                    return;
                }

                setLoading(true);
                const startTs = formatDateTime(startDate, pickUpTime);
                const endTs = formatDateTime(endDate, returnTime);
                const body = {
                    lat: '30.1953',
                    lng: '-97.6667',
                    startTs: startTs,
                    endTS: endTs,
                    hostid: hostId,
                    pickupTime: pickUpTime,
                    dropTime: returnTime,
                    isAirport: false,
                };
                const response = await bundeeRestApi.searchVehiclesByDates(body);
                // console.log(response?.data);
                if (response?.data?.vehicleAllDetails?.length > 0) {
                    const availableCars = response?.data?.vehicleAllDetails.map((car: any) => {
                        if (fleetCarsIndexed[car?.vin]) {
                            fleetCarsIndexed[car?.vin].bundeeProperties = car;
                            return fleetCarsIndexed[car?.vin];
                        } else {
                            return null;
                        }
                    }).filter((car: any) => car !== null);
                    setAvailableCars(availableCars);

                    // Create dataset
                    const updatedStations = stationsRTDB.map((station: any) => {
                        const carsAtStation = availableCars.filter((car: any) => car.places_id === station.properties.places_id);
                        return {
                            ...station,
                            properties: {
                                ...station.properties,
                                numCars: carsAtStation.length,
                                cars: carsAtStation,
                            },
                        };
                    }).filter((station: any) => station.properties.numCars > 0);
                    setDataset(updatedStations?.length > 0 ? updatedStations : null);
                    //console.log('udeatedStations:', updatedStations);

                    setError('');
                } else if (response?.data?.errorCode === '1' || response?.data?.errorCode === null) {
                    // setError('');
                    setError(response?.data?.errorMessage || 'An error occurred');
                } else if (response?.data?.errorCode === '0' && response?.data?.errorMessage === "Vehicles Not Available at this Location") {
                    // setError('');
                    setError('Apologies, but no cars are available within your selected date range');
                }
                setLoading(false);
            } catch (error) {
                console.error(error);
                setError('An error occurred');
                showToast('An error occurred', 'error');
                setLoading(false);
            }
        };

        if (!startDate || !endDate || !pickUpTime || !returnTime) {
            showToast('Please choose the date and time to continue', 'error');
            navigate('/booking');
            return;
        }

        const isAfter7pm = handleIsAfter7pm();
        if (isAfter7pm) {
            const message ='Pick-up time cannot be earlier than tomorrow at 12 PM'
            showToast(message, 'error');
            navigate('/booking');
            return;
        }

        const isBefore8am = handleIsBefore8am();
        if (isBefore8am) {
            const message = 'Pick-up time cannot be earlier than today at 12 PM';
            showToast(message, 'error');
            navigate('/booking');
            return;
        }

        const isBeforeMinTime = handleIfPickUpTimeIsBeforeMinTime();
        if (isBeforeMinTime) {
            showToast('Pick-up time cannot be before than Minimun Time', 'error');
            navigate('/booking');
            return;
        };

        if (Object.keys(fleetCarsIndexed).length === 0 && stationsRTDB?.length === 0) {
            getCarsFromRTDB();
        } else {
            fetchData();
        }

    }, [startDate, endDate, pickUpTime, returnTime, navigate, fleetCarsIndexed, stationsRTDB, handleIsAfter7pm, handleIfPickUpTimeIsBeforeMinTime, handleIsBefore8am]);

    return (
        <div className={styles.container}>
            {loading ? (
                <Loader loading={loading} />
            ) : (
                <>
                    <Loader loading={loadingBooking} />
                    <h1 className={styles.heading}>Available Cars</h1>
                    <div className={styles.dateContainer}>
                        <div className={styles.dateText}>{`From: ${formatDate(startDate || '', pickUpTime || '')}`}</div>
                        <div className={styles.dateText}>{`To: ${formatDate(endDate || '', returnTime || '')}`}</div>
                    </div>
                    <div className={styles.buttonsContainer} >
                        <button className={isMobile ? styles.buttonMobile : styles.button} onClick={handleChooseDates}>Choose different dates</button>
                        {(dataset?.length > 0) && <button className={isMobile ? styles.buttonMobile : styles.button} onClick={handleViewOnMap}>{showViewOnMap ? 'Cars View' : 'Map View'}</button>}
                    </div>
                    {error ?
                        <div className={styles.dateContainer}>
                            <h2 className={styles.dateText}>
                                {error || 'An error occurred'}
                            </h2>
                        </div> :

                        showViewOnMap ?
                        <div className={styles.mapContainer}>
                            <VehiclesViewOnMap
                                initialLatitude={30.2585795}
                                initialLongitude={-97.749079}
                                initialZoom={11}
                                width={'80%'}
                                height={'100%'}
                                dataset={dataset}
                                onFeatureClick={handleFeatureClick}
                            />
                        </div>
                        :
                        <div className={isMobile ? styles.carGridMobile : styles.carGrid}>
                            {availableCars.map((car: any) => {

                                return (
                                    <div key={car.VIN || 'No VIN'} className={isMobile ? styles.cardMobile : styles.card}>
                                        <LazyLoadImage
                                            src={`https://fiat.b-cdn.net/${car.ID}.jpeg`}
                                            alt={car.name}
                                            effect="blur"
                                            placeholderSrc={placeHolder}
                                            className={styles.carImage}
                                            onError={(e) => {
                                                e.currentTarget.src = noImage;
                                            }}
                                        />
                                        <div className={styles.content}>
                                            <h3 className={styles.carType}>{`${car?.Make || 'Make no found'} ${car?.Model || 'Model no found'} - ${car?.Color || 'Color no found'}`}</h3>
                                            <div className={styles.IconContainer}>
                                                <LazyLoadImage
                                                    src={carIcon}
                                                    alt="Station Icon"
                                                    effect="blur"
                                                    placeholderSrc={placeHolder}
                                                    className={styles.icon}
                                                />
                                                <span className={styles.dataCar}>{car?.Station || 'No Station'}</span>
                                            </div>
                                            <div className={styles.IconContainer}>
                                                <LazyLoadImage
                                                    src={addressIcon}
                                                    alt="Location Icon"
                                                    effect="blur"
                                                    placeholderSrc={placeHolder}
                                                    className={styles.icon}
                                                />
                                                <span className={styles.dataCar}>{car?.stationProperties?.address || 'No address'}</span>
                                            </div>
                                            <div className={styles.IconContainer}>
                                                <LazyLoadImage
                                                    src={moneyIcon}
                                                    alt="Money Icon"
                                                    effect="blur"
                                                    placeholderSrc={placeHolder}
                                                    className={styles.icon}
                                                />
                                                <span className={styles.dataCar}>${car?.bundeeProperties?.price_per_hr || '(No price found)'}/day</span>
                                            </div>
                                            <button className={styles.seeDetails} onClick={() => setSelectedCar(car)}>
                                                See Details
                                            </button>
                                            <div className={isMobile ? styles.tripDetailsMobile : styles.tripDetails}>
                                                <button className={styles.buttonBook} onClick={()=> handleBookNow(car)}>Book Now</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                </>
            )}
            <CarDetailsModal car={selectedCar} isOpen={!!selectedCar} onClose={() => setSelectedCar(null)} />
            <StationCarsModal isOpen={!!selectedFeature} onClose={() => setSelectedFeature(null)} selectedFeature={selectedFeature} isMobile={isMobile} handleBookNow={handleBookNow} />
        </div>
    );
};

export default Vehicles;
