import React, { CSSProperties } from 'react';
import { useLocation } from 'react-router-dom';
import { useIsMobile } from '../hooks/useIsMobile';

const MobileApp: React.FC = () => {
    const isMobile = useIsMobile();
    const location = useLocation();

    const styles: { [key: string]: CSSProperties } = {
        page: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100vw',
            height: '100vh',
            backgroundColor: '#f8f8f8',
        },
        card: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '90%',
            maxWidth: isMobile ? '300px' :'400px',
            padding: '20px',
            backgroundColor: 'white',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            marginTop: '20px',
        },
        header: {
            width: '100%',
            padding: '20px 0',
            backgroundColor: 'white',
            color: 'black',
            textAlign: 'center',
        },
        headerTitle: {
            fontSize: '24px',
            margin: 0,
        },
        headerSubtitle: {
            fontSize: '16px',
            padding: '0 20px',
            margin: '10px 0',
            fontWeight: 'normal',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '30px',
        },
        button: {
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#C7F100',
            color: 'black',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            margin: '0 10px',
        },
        buttonDownload: {
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#dfdfdf',
            color: 'black',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            margin: '0 10px',
        },
    };

    const handleOpenApp = () => {
        const newPath = location.pathname.replace('/mobile-app', 'fluxev://') + location.search;
        window.location.href = newPath;
    };

    const handleDownloadApp = () => {
        window.location.href = 'https://www.fluxev.city/download';
    }

    return (
        <div style={styles.page}>
            <div style={styles.card}>
                <header style={styles.header}>
                    <h1 style={styles.headerTitle}>Open the Flux App</h1>
                    <h2 style={styles.headerSubtitle}>It seems like the automatic redirect was canceled. You can manually open the app using the button below:</h2>
                </header>
                <div style={styles.buttonContainer}>
                    <button style={styles.buttonDownload} onClick={handleDownloadApp}>Download Flux App</button>
                    <button style={styles.button} onClick={handleOpenApp}>Open Flux App</button>
                </div>
            </div>
        </div>
    );
};

export default MobileApp;
