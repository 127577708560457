import React, { CSSProperties, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
// assets
import carIcon from "../images/icons/car-icon.png";
import calenderIcon from "../images/icons/calender.png";
import defaultCar from "../images/banners/reservations-banner.jpg";
import statusIcon from "../images/icons/status-icon.png";
// hooks
import { useIsMobile } from '../hooks/useIsMobile';
// components
import Loader from "../components/Loader";
import TabSelector from "../components/TabSelector";
import ImageModal from "../components/trips/ImageModal";
// services
import bundeeRestApi from "../services/BundeeRestApi";
// firebase
import { rtdbFleetCars } from '../firebase/firebase';
import { ref, off, onValue } from '@firebase/database';
// others
import moment from 'moment-timezone';
// context
import { useAuth } from '../Context/AuthContext';

const Trips: React.FC = () => {
    const navigate = useNavigate();
    // context
    const { contactData } = useAuth();
    // states
    const isMobile = useIsMobile();
    const [loading, setLoading] = useState(true);
    const [tripsData, setTripsData] = useState<any>([]);
    const [carData, setCarData] = useState<any>(null);
    const [updatedTripsData, setUpdatedTripsData] = useState<any>([]);
    const [error, setError] = useState<any>(null);
    const [currentContactData, setCurrentContactData] = useState<any>(null);
    // states tab trips status
    const [activeTab, setActiveTab] = useState<string>('all');
    const tabs = [
        { label: 'Requested', value: 'requested' },
        { label: 'Upcoming', value: 'upcoming' },
        { label: 'Started', value: 'started' },
        { label: 'Completed', value: 'completed' },
        { label: 'Cancelled', value: 'cancelled' },
        { label: 'Rejected', value: 'rejected' },
        {label: 'All Trips', value: 'all'}
    ];
    // states tab trip time
    const [activeTimeTab, setActiveTimeTab] = useState<string>('all');
    const timeTabs = [
        { label: 'All', value: 'all' },
        { label: 'Today', value: 'today' },
        { label: 'This Week', value: 'thisWeek' },
        { label: 'This Month', value: 'thisMonth' },
    ];


    // styles
    const styles: { [key: string]: CSSProperties } = {
        container: {
            padding: '10px 20px 70px',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
        },
        header: {
            borderBottom: '2px solid black',
            flexShrink: 0,
            flexGrow: 0,
            height: 'auto',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'start',
            alignItems: 'center',
            marginBottom: '10px',
        },
        hederFilter: {
            display: 'flex',
            height: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        card: {
            border: '1px solid #e1e1e8',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            padding: '20px',
            marginBottom: '20px',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#fff',
            transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        },
        dateTab: {
            backgroundColor: '#d5ff05',
            padding: '5px 10px',
            borderRadius: '5px',
            fontSize: '11px',
        },
        tripsContainer: {
            flexGrow: 1,
            padding: '0px 20px',
            overflowY: 'auto',
            overflowX: 'hidden',
            marginBottom: '133px',
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignContent: 'center',
            gap: '10px',
        },
        carImage: {
            width: '100px',
            height: 'auto',
            marginTop: '10px',
            alignSelf: 'center',
        },
        carType: {
            margin: '0px',
        },
        carDetails: {
            margin: '0px',
            color: 'grey',
        },
        tripDetails: {
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
        },
        icon: {
            width: '20px',
        },
        button: {
            width: '50%',
            alignSelf: 'center',
        },
        errorContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh',
            backgroundColor: '#f8f8f8',
            textAlign: 'center',
            padding: '20px'
        },
        refreshButton: {
            marginTop: '20px',
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#333',
            color: 'white',
            border: 'none',
            borderRadius: '15px',
            cursor: 'pointer',
        },
    };
    // funtions
    const handleTripDetailsClick = (details: any) => {
        navigate(`/trips/${details.tripObject.tripid}`);
    };

    // effects
    useEffect(() => {
        if (currentContactData) {
            const getTripsData = async () => {
                setLoading(true);

                const statusMap: { [key: string]: string } = {
                    'requested': 'REREQ',
                    'upcoming': 'REAPP',
                    'started': 'TRSTR',
                    'completed': 'TRCOM',
                    'cancelled': 'RECAN',
                    'rejected': 'REREJ',
                    'all': 'all',
                };

                let startTime = '';
                let endTime = '';

                if (activeTimeTab === 'today') {
                    startTime = startTime = moment().startOf('day').toISOString();
                    endTime = moment().endOf('day').toISOString();
                } else if (activeTimeTab === 'thisWeek') {
                    startTime = moment().startOf('isoWeek').toISOString();
                    endTime = moment().endOf('isoWeek').toISOString();
                } else if (activeTimeTab === 'thisMonth') {
                    startTime = moment().startOf('month').toISOString();
                    endTime = moment().endOf('month').toISOString();
                }

                const uservalue = activeTimeTab === 'all' ? 'userId' : 'userIdbetweendays';
                try {
                    const response = await bundeeRestApi.getAllTripDetailsFromValue(uservalue, currentContactData?.contact?.bundee_user_id, startTime, endTime);

                    if (response?.data?.errorCode === '0') {
                        const activeTrips = response?.data?.activetripresponse || [];
                        if (statusMap[activeTab] !== 'all') {
                            const filteredTrips = activeTrips.filter((trip: any) => trip?.statusCode === statusMap[activeTab]);
                            setTripsData(filteredTrips);
                        } else {
                            setTripsData(activeTrips);
                        }
                        setLoading(false);
                    } else if (response?.data?.errorCode === '1' && response?.data?.errorMessage === "Trip Data is Empty...") {
                        setTripsData([]);
                        setLoading(false);
                    } else {
                        setTripsData([]);
                        setLoading(false);
                        setError('Failed to get trip data');
                    }
                } catch (e) {
                    console.error('Failed to get trip data:', e);
                    setTripsData([]);
                    setLoading(false);
                    setError(e);
                }
            };
            getTripsData();
        }
    }, [activeTab, currentContactData, activeTimeTab]);


    useEffect(() => {
        const carDataRef = ref(rtdbFleetCars, '/');

        onValue(carDataRef, (carDataSnapshot) => {
            const carData = carDataSnapshot.val();
            if (Array.isArray(carData)) {
                const filteredCarsByVehicleId = carData.filter((car: any) => (car?.vehicleid !== undefined && car?.vehicleid !== null));
                setCarData(filteredCarsByVehicleId);
            } else {
                setCarData([]);
                console.error('carData is not an array');
            }
        }, (error) => {
            console.log('useApiStations', error);
        });

        return () => {
            off(carDataRef);
        };
    }, []);

    useEffect(() => {
        if (carData && tripsData) {
            try {
                setLoading(true);
                const carLookup = carData.reduce((acc: any, car: any) => {
                    if (car?.vehicleid) {
                        acc[car.vehicleid] = car;
                    }
                    return acc;
                }, {});

                const updatedTripsData = tripsData
                    .filter((trip: any) => carLookup[trip?.vehicleId])
                    .map((trip: any) => {
                        const car = carLookup[trip?.vehicleId];
                        const carImage = `https://fiat.b-cdn.net/${car.ID}.jpeg` || defaultCar;
                        const carType = `${car?.Make} ${car?.Model}` || '';
                        const carDetails = 'Some detail' || '';
                        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                        const startTime = moment.tz(trip?.starttime, userTimezone).format('ddd, MMM DD - h:mm a');
                        const endTime = moment.tz(trip?.endtime, userTimezone).format('ddd, MMM DD - h:mm a');
                        const date = moment.tz(trip?.starttime, userTimezone).format('MMM DD, YYYY');
                        const location = car?.Station || '';
                        const tripObject = trip;

                        return {
                            ...trip,
                            carImage,
                            carType,
                            carDetails,
                            startTime,
                            endTime,
                            date,
                            location,
                            tripObject,
                            carObject: car,
                        };
                    })
                    .sort((a: any, b: any) => {
                        return moment(a.starttime).isBefore(moment(b.starttime)) ? 1 : -1;
                    });

                setUpdatedTripsData(updatedTripsData);
                setLoading(false);
            } catch (e: any) {
                console.error('Failed to update trips data:', e);
                setUpdatedTripsData([]);
                setLoading(false);
            }
        }
    }, [carData, tripsData]);

    useEffect(() => {
        if (contactData) {
            setCurrentContactData(contactData);
        }
    }, [contactData]);

    return (
        <>
            {error ?
                (<div style={styles.errorContainer}>
                    <h2>Oops! Something went wrong.</h2>
                    <p>Sorry, You could try to reload the page.</p>
                    <button onClick={() => window.location.reload()} style={styles.refreshButton}>Re load</button>
                </div>) :
                (
                    <div style={styles.container}>
                        <Loader loading={loading} />
                        <div style={styles.header}>
                            <h1 >Trips</h1>
                            <div style={styles.hederFilter}>
                                <TabSelector activeTab={activeTab} tabs={tabs} onTabChange={setActiveTab} />
                                <TabSelector activeTab={activeTimeTab} tabs={timeTabs} onTabChange={setActiveTimeTab} />
                            </div>
                        </div>
                        <div style={styles.tripsContainer}>
                            {updatedTripsData.length === 0 ? (
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <h2>No {`${activeTab}`} trips found.</h2>
                                    <p>Sorry, You don't have any {`${activeTab}`} trips.</p>
                                </div>
                            ) : updatedTripsData.map((trip: any, index: any) => {
                                return (
                                    <TripCard key={`${index} + ${trip.tripid}`}  {...trip} loading={loading} styles={styles} handleTripDetailsClick={handleTripDetailsClick} isMobile={isMobile} />
                                );
                            }
                            )}
                        </div>
                    </div>
                )
            }
        </>
    );
};


interface TripCardProps {
    date: string;
    location: string;
    startTime: string;
    endTime: string;
    carImage: string;
    carType: string;
    carDetails: string;
    styles: { [key: string]: CSSProperties };
    loading: boolean;
    carObject: any;
    tripObject: any;
    handleTripDetailsClick: (details: any) => void;
    isMobile: boolean;
}

const TripCard: React.FC<TripCardProps> = ({ date, location, startTime, endTime, carImage, carType, carDetails, styles, loading, handleTripDetailsClick, carObject, tripObject, isMobile }) => {
    const [image, setImage] = useState(carImage);
    const [showImageModal, setShowImageModal] = useState(false);
    const data = {
        carObject,
        tripObject,
    };

    const toggleImageModal = () => setShowImageModal(!showImageModal);

    const newStyles: { [key: string]: CSSProperties } = {
        card: {
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            width: '100%',
            height: isMobile ? 'auto' : '350px',
            backgroundColor: '#FFFFFF',
            border: '1px solid #E1E1E8',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            borderRadius: '8px',
            overflow: 'hidden',
            marginBottom: '20px',
        },
        carImage: {
            width: isMobile ? '100%' : '40%',
            height: isMobile ? '200px' : '100%',
            objectFit: 'cover',
            cursor: 'pointer',
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            padding: '20px',
            width: isMobile ? '100%' : '60%',
        },
        dateTab: {
            fontSize: isMobile ? '12px' : '16px',
            fontWeight: 'bold',
        },
        carType: {
            fontSize: isMobile ? '14px' : '18px',
            fontWeight: 'bold',
            margin: '5px 0',
        },
        carDetails: {
            fontSize: '14px',
            color: '#666',
        },
        tripDetails: {
            display: 'flex',
            alignItems: 'center',
            fontSize: isMobile ? '12px' : '16px',
            gap: '10px',
            width: '80%',
            padding: '5px 0',
        },
        button: {
            marginTop: '20px',
            padding: '10px 20px',
            fontSize: '16px',
            color: '#FFFFFF',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            alignSelf: 'center', // Center button for mobile
        },
        icon: {
            width: '20px',
            height: '20px',
        },
    };


    return (
        <>
            {loading ? null : (
                <div style={newStyles.card}>
                    <img
                        key={tripObject?.tripid + 'image'}
                        src={image}
                        alt="Car"
                        style={newStyles.carImage}
                        onClick={toggleImageModal}
                        onError={() => setImage(defaultCar)}
                    />
                    {showImageModal && !isMobile && <ImageModal src={image} onClose={toggleImageModal} />}
                    <div style={newStyles.content}>
                        <div style={newStyles.dateTab}>
                            <h2>{date}</h2>
                        </div>
                        <h3 style={newStyles.carType}>{carType}</h3>
                        <div style={newStyles.tripDetails}>
                            <img src={statusIcon} alt="Status Icon" style={newStyles.icon} />
                            <span>{tripObject?.status || 'No Found'}</span>
                        </div>
                        <div style={newStyles.tripDetails}>
                            <img src={carIcon} alt="Location Icon" style={newStyles.icon} />
                            <span>{location}</span>
                        </div>
                        <div style={newStyles.tripDetails}>
                            <img src={calenderIcon} alt="Calendar Icon" style={{ ...newStyles.icon }} />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span>{startTime}</span>
                                <span>{endTime}</span>
                            </div>
                        </div>
                        <div style={newStyles.tripDetails}>
                            <span>Trip Id: {tripObject?.tripid || 'No Found'}</span>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <button style={{ width: '50%' }} className="buttonBlack" onClick={() => handleTripDetailsClick(data)}>
                                Check trip details
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};




export default Trips;
