// ChatModal.tsx
import React from 'react';
import TripChat from './TripChat';
import styles from './TripChatModal.module.css';

interface ChatModalProps {
    isOpen: boolean;
    onClose: () => void;
    tripId: string;
    currentContactData: any;
    tripDetails: any;
}

const TripChatModal: React.FC<ChatModalProps> = ({ isOpen, onClose, tripId, currentContactData, tripDetails }) => {
    if (!isOpen) return null;

    return (
        <div className={styles['trip-modal-overlay']}>
            <div className={styles['trip-modal-content']}>
                <button className={styles['trip-close-button']} onClick={onClose}>Close</button>
                <TripChat
                    isOpen={isOpen}
                    tripId={tripId}
                    currentContactData={currentContactData}
                    tripDetails={tripDetails}
                />
            </div>
        </div>
    );
};

export default TripChatModal;
