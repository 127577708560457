import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Services
import fluxRestApi from '../services/FluxRestApi';
import bundeeRestApi from '../services/BundeeRestApi';
// Components
import Loader from '../components/Loader';
// Context
import { useAuth } from '../Context/AuthContext';
// Utils
import showToast from '../utils/toastHelpers';
// Styles
import styles from '../styles/verifyMyPhone.module.css';

const VerifyMyPhone: React.FC = () => {
    const navigate = useNavigate();
    const { contactData } = useAuth();

    const [phoneNumber, setPhoneNumber] = useState(
        contactData?.contact?.phone ? contactData.contact.phone.replace(/^\+1/, '') : ''
    );
    const [otp, setOtp] = useState('');
    const [errors, setErrors] = useState<{ phoneNumber?: string; otp?: string }>({});
    const [loading, setLoading] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [verificationCode, setVerificationCode] = useState<any | null>(null);

    const handleSendOtp = async () => {
        setErrors({});
        if (!phoneNumber.trim()) {
            setErrors({ phoneNumber: 'Phone number is required.' });
            return;
        }
        if (!/^\d{10}$/.test(phoneNumber)) {
            setErrors({ phoneNumber: 'Phone number must be 10 digits.' });
            return;
        }

        try {
            setLoading(true);

            // Check if the phone number is already registered
            if (contactData?.contact?.phone !== `+1${phoneNumber}`) {
                const isPhoneNumberRegistered = await fluxRestApi.checkIfUserExistsByPhone(`1${phoneNumber}`);
                if (isPhoneNumberRegistered?.data?.user_exists) {
                    setErrors({ phoneNumber: 'Phone number is already in use.' });
                    setLoading(false);
                    return;
                }
                if (isPhoneNumberRegistered === null) {
                    setErrors({ phoneNumber: 'Failed to check phone number.' });
                    setLoading(false);
                    return;
                }
            }

            const data = { phone: `+1${phoneNumber}` };
            const code = await fluxRestApi.getVerificationCode(data);
            if (!code?.data) {
                setErrors({ phoneNumber: 'Unable to send a verification code to this number.' });
                setLoading(false);
                return;
            }

            setVerificationCode(code.data);
            setOtpSent(true);
            showToast('OTP has been sent to your phone number.', 'success');
            setLoading(false);
        } catch (error) {
            showToast('Failed to send OTP.', 'error');
            setLoading(false);
        }
    };

    const handleVerifyOtp = async () => {
        setErrors({});
        if (!otp.trim()) {
            setErrors({ otp: 'OTP is required.' });
            return;
        }

        if (otp !== verificationCode) {
            showToast('Invalid OTP.', 'error');
            return;
        }

        try {
            setLoading(true);
            const updateHubspotData: { verified_phone: boolean; phone?: string } = {
                verified_phone: true,
            };
            if (contactData?.contact?.phone !== `+1${phoneNumber}`) {
                updateHubspotData.phone = `+1${phoneNumber}`;
            }
            const updateVerifiedPhone = await fluxRestApi.updateStatusAccount(contactData.contact.id, updateHubspotData);
            if (!updateVerifiedPhone?.data?.contact) {
                throw new Error('Failed to verify phone number');
            }
            const updateVerifiedPhoneBundee = await bundeeRestApi.isPhoneVerified({
                mobilePhone: `+1${phoneNumber}`,
                isPhoneVarified: true,
                iduser: contactData.contact.bundee_user_id,
            });
            if (updateVerifiedPhoneBundee?.data?.errorCode !== '0') {
                throw new Error('Failed to verify phone number');
            }

            showToast('Phone number verified successfully!', 'success');
            navigate(-1);
            setLoading(false);
        } catch (error) {
            showToast('Failed to verify OTP.', 'error');
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!contactData) {
            setLoading(true);
        } else {
            setLoading(false);
            setPhoneNumber(contactData?.contact?.phone ? contactData.contact.phone.replace(/^\+1/, '') : '');
        }
    }, [contactData]);

    return (
        <div className={styles.verifyPhoneContainer}>
            <Loader loading={loading} />
            <h1 className={styles.title}>Verify Phone Number</h1>
            <p className={styles.subtitle}>Enter your phone number to receive an OTP.</p>

            <label className={styles.label}>Phone Number</label>
            <div className={styles.phoneInputContainer}>
                <div className={styles.flagBox} onClick={() => showToast('Currently, only US numbers are supported.', 'info')}>
                    <img src={require('../images/flag-usa.png')} alt="USA Flag" className={styles.flagIcon} />
                    <span className={styles.plusOne}>+1</span>
                </div>
                <input
                    type="tel"
                    placeholder="Enter phone number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className={styles.phoneInput}
                />
            </div>
            {errors.phoneNumber && <div className={styles.errorText}>{errors.phoneNumber}</div>}

            {otpSent && (
                <>
                    <label className={styles.label}>OTP</label>
                    <div className={styles.phoneInputContainer}>
                    <input
                        type="text"
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        className={styles.phoneInput}
                    />
                    </div>
                    {errors.otp && <div className={styles.errorText}>{errors.otp}</div>}
                </>
            )}

            {!otpSent ? (
                <button
                    onClick={handleSendOtp}
                    disabled={loading}
                    className={`${styles.submitButton} ${loading ? styles.submitButtonDisabled : ''}`}
                >
                    {loading ? 'Sending OTP...' : 'Send OTP'}
                </button>
            ) : (
                <button
                    onClick={handleVerifyOtp}
                    disabled={loading}
                    className={`${styles.submitButton} ${loading ? styles.submitButtonDisabled : ''}`}
                >
                    {loading ? 'Verifying...' : 'Verify OTP'}
                </button>
            )}
        </div>
    );
};

export default VerifyMyPhone;
