import React from 'react';
// mobile Picker
import { DayPicker } from 'react-day-picker';
import '../../styles/react-day-picker.css';

interface DateRangePickerMobileProps {
    range: any;
    setRange: any;
    disabledDays: any;
}

const DateRangePickerMobile: React.FC<DateRangePickerMobileProps> = ({ range, setRange, disabledDays }) => {
    return (
        <DayPicker
            id="date-range"
            mode="range"
            defaultMonth={new Date()}
            selected={range}
            // footer={footer}
            onSelect={setRange}
            numberOfMonths={2}
            disabled={disabledDays}
        />
    );
};

export default DateRangePickerMobile;