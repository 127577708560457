import React, { useState, useEffect, CSSProperties } from 'react';
import IDVC from '@idscan/idvc2';
import '@idscan/idvc2/dist/css/idvc.css';
import License from '../images/modal-license.png';
import showToast from '../utils/toastHelpers';
import fluxRestApi from '../services/FluxRestApi';

const Idscan: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const [isButtonHovered, setIsButtonHovered] = useState<boolean>(false);
  const [isProcessStarted, setIsProcessStarted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isThankYou, setIsThankYou] = useState<boolean>(false);

  const handleButtonMouseEnter = () => setIsButtonHovered(true);
  const handleButtonMouseLeave = () => setIsButtonHovered(false);

  const handleCancel = () => {
    window.location.reload();
  };

  const sendNotificationToSupportUsers = async (result: any) => {
    try {
      const data = {
        "title": `Idscan - license verification`,
        "body": `${result?.document?.fullName || 'Full name: N/A'} requestId:${result.requestId} has completed the IDscan license verification process.`,
        "send_sms": true,
        "send_email": true,
      }
      const response = await fluxRestApi.sendNotificationToSupportUsers(data.title, data.body, data.send_sms, data.send_email);
      if (response !== null) {
        console.log("Notification sent successfully to support users");
      }
    } catch (error) {
      console.log("There was an error while trying to send a notification to support users");
    }
  }

  const startIDVCProcess = () => {
    setIsProcessStarted(true);

    const licenseKey = process.env.REACT_APP_LICENSE_KEY || '';
    const secretKey = process.env.REACT_APP_SECRET_KEY || '';

    if (!licenseKey || !secretKey) {
      setError('License key or secret key is missing. Please check your environment variables.');
      return;
    }


    const idvcInstance = new IDVC({
      el: 'videoCapturingEl',
      licenseKey,
      networkUrl: 'networks/',
      chunkPublicPath: '/networks/',
      resizeUploadedImage: 1200,
      fixFrontOrientAfterUpload: false,
      autoContinue: true,
      isShowDocumentTypeSelect: false,
      useCDN: false,
      isShowGuidelinesButton: false,
      showSubmitBtn: false,
      language: 'en',
      realFaceMode: 'auto',
      processingImageFormat: 'jpeg',
      documentTypes: [
        {
          type: 'ID',
          steps: [
            {
              type: 'front',
              name: 'Document Front',
              mode: { uploader: false, video: true },
            },
            {
              type: 'pdf',
              name: 'Document PDF417 Barcode',
              mode: { uploader: false, video: true },
            },
            {
              type: 'face',
              name: 'Face',
              mode: { uploader: false, video: true },
            },
          ],
        },
      ],
      onChange(data: any) {
        // console.log('on change', data);
      },
      onCameraError(data: any) {
        console.error('Camera error:', data);
        showToast('An error occurred while accessing the camera. Please reload the page.');
      },
      onReset(data: any) {
        // console.log('on reset', data);
      },
      onRetakeHook(data: any) {
        // console.log('retake hook', data);
      },
      clickGuidlines() {
        console.log('click Guidelines');
      },
      submit: async (data: any) => {
        idvcInstance.showSpinner(true);
        setIsLoading(true);
        let frontStep, pdfStep, faceStep;
        let frontImage, backImage, faceImage;
        let captureMethod;
        let rawTrackString;

        try {
          switch (data.documentType) {
            case 1: // Drivers License and Identification Card
              frontStep = data.steps.find((item: any) => item.type === 'front');
              pdfStep = data.steps.find((item: any) => item.type === 'pdf');
              faceStep = data.steps.find((item: any) => item.type === 'face');

              frontImage = frontStep.img.split(/:image\/(jpeg|png);base64,/)[2];
              backImage = pdfStep.img.split(/:image\/(jpeg|png);base64,/)[2];
              faceImage = faceStep.img.split(/:image\/(jpeg|png);base64,/)[2];

              rawTrackString = pdfStep && pdfStep.trackString ? pdfStep.trackString : '';

              captureMethod = JSON.stringify(+frontStep.isAuto) + JSON.stringify(+pdfStep.isAuto) + JSON.stringify(+faceStep.isAuto);
              break;
          }

          const trackStringArray = rawTrackString.split('.');
          let trackString = trackStringArray[0];
          let barcodeParams = trackStringArray[1];

          let request = {
            frontImageBase64: frontImage,
            backOrSecondImageBase64: backImage,
            faceImageBase64: faceImage,
            documentType: data.documentType,
            trackString: {
              data: trackString,
              barcodeParams: barcodeParams,
            },
            overriddenSettings: {
              isOCREnabled: true,
              isBackOrSecondImageProcessingEnabled: true,
              isFaceMatchEnabled: true,
            },
            metadata: {
              captureMethod: captureMethod,
            },
          };

          const response = await fetch('https://dvs2.idware.net/api/v4/verify', {
            method: 'POST',
            headers: {
              Authorization: 'Bearer ' + secretKey,
              'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify(request),
          });

          const result = await response.json();
          if (!result?.requestId) {
            throw new Error('Invalid response from the server');
          }
          await sendNotificationToSupportUsers(result);
          idvcInstance.showSpinner(false);
          // console.log('data', result);
          setIsLoading(false);
          setIsThankYou(true);
          // hide the video capturing element
          setIsThankYou(true);
          showToast('Completed, we will let you know the results soon.', 'success');
          document.getElementById('videoCapturingEl')!.style.display = 'none';
        } catch (err) {
          idvcInstance.showSpinner(false);
          setIsLoading(false);
          console.error('Fetch error:', err);
          alert('An error occurred while processing your request. Please try again.');
        }
      },
    });

    // Add error handling for chunk loading
    window.addEventListener('error', (e) => {
      if (e.message.includes('Loading chunk')) {
        setError('An error occurred while loading the application. Please reload the page.');
      }
    });
  };

  useEffect(() => {
    return () => {
      // Cleanup event listener on unmount
      window.removeEventListener('error', () => {});
    };
  }, []);

  const styles: { [key: string]: CSSProperties } = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '20px',
      height: '100vh', // Ensures the container takes the full viewport height
      backgroundColor: '#f0f2f5',
      overflowY: isProcessStarted ? 'auto' : 'hidden',
      overflowX: 'hidden',
    },
    videoContainer: {
      width: '100%',
      maxWidth: '500px',
      height: isProcessStarted ? '100%' : '300px', // Adjust height based on process state
      margin: '20px 0',
      backgroundColor: '#ffffff',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      position: 'relative',
    },
    imageLicense: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '10px',
      display: isProcessStarted ? 'none' : 'block',
    },
    button: {
      width: '60%',
      padding: '10px',
      marginTop: '10px',
      marginBottom: '10px',
      border: 'none',
      borderRadius: '5px',
      fontSize: '1em',
      cursor: 'pointer',
      backgroundColor: '#C7F100',
      color: 'black',
      transition: 'background-color 0.3s ease',
    },
    buttonHover: {
      backgroundColor: '#b2d700',
    },
    cancelButton: {
      padding: '10px 10px',
      marginTop: '10px',
      marginBottom: '10px',
      border: 'none',
      borderRadius: '5px',
      fontSize: '1em',
      cursor: 'pointer',
      backgroundColor: '#ff4d4d',
      color: 'white',
      transition: 'background-color 0.3s ease',
      position: 'absolute',
      top: '10px',
      right: '10px',
    },
    cancelButtonHover: {
      backgroundColor: '#ff1a1a',
    },
    error: {
      color: 'red',
      marginTop: '10px',
    },
    instructions: {
      marginTop: '20px',
      fontSize: '1em',
      color: '#555',
      textAlign: 'center',
    },
    loading: {
      fontSize: '1.5em',
      color: '#555',
      marginTop: '20px',
    },
    thankYou: {
      fontSize: '18px',
      backgroundColor: '#C7F100',
      color: 'black',
      borderRadius: '5px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      padding: '20px',
      marginTop: '10px',
    },
  };

  return (
    <div style={styles.container}>
      {!isProcessStarted && (
        <>
          <h3>License Verification</h3>
          <div style={styles.instructions}>
            <p>You are able to start the validation process. Grab your license and activate the permissions for the camera.</p>
          </div>
        </>
      )}
      <div id="videoCapturingEl" style={styles.videoContainer}>
        <img src={License} alt="license" style={styles.imageLicense} />
        {isProcessStarted && (
          <button
            onClick={handleCancel}
            onMouseEnter={handleButtonMouseEnter}
            onMouseLeave={handleButtonMouseLeave}
            style={{ ...styles.cancelButton, ...(isButtonHovered && styles.cancelButtonHover) }}
          >
            X
          </button>
        )}
      </div>
      {!isProcessStarted && !isLoading && !isThankYou && (
        <button
          onClick={startIDVCProcess}
          onMouseEnter={handleButtonMouseEnter}
          onMouseLeave={handleButtonMouseLeave}
          style={{ ...styles.button, ...(isButtonHovered && styles.buttonHover) }}
        >
          Start License Validation
        </button>
      )}
      {isLoading && <div style={styles.loading}>Loading...</div>}
      {isThankYou && <div style={styles.thankYou}>Completed, we will let you know the results soon.</div>}
      {error && <div style={styles.error}>{error}</div>}
    </div>
  );
};

export default Idscan;
