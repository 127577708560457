import { CSSProperties } from 'react';
// authStyles.ts is a file that contains the styles for diferent components of the App. Login, Signup, Contact Form, etc. escentially components that are using input fields.
const commonStyles: { [key: string]: CSSProperties } = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: '100vh',
        overflowX: 'hidden',
    },
    formPart: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        transform: 'translateX(-100%)',
        transition: 'transform 0.5s ease-in-out',
        overflowY: 'auto',
        maxHeight: '100vh'
    },
    center: {
        textAlign: 'center',
    },
    left: {
        textAlign: 'center',
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    rigth: {
        textAlign: 'right',
        cursor: 'pointer',
        textDecoration: 'none'
    },
    input: {
        width: '60%',
        padding: '10px',
        margin: '10px 0',
        borderRadius: '5px',
        border: '1px solid #ddd',
        fontSize: '16px',
    },
    buttonContent: {
        width: '60%',
    },
    inputWrapper: {
        position: 'relative',
        width: '60%',
        display: 'flex',
        alignItems: 'center',
    },
    eyeIcon: {
        position: 'absolute',
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
    },
    formLogin: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    scrollableContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        paddingTop: '100px',
        paddingBottom: '100px'
    },
    checkbox: {
        marginRight: '10px',
    },
    link: {
        color: '#0077cc',
        textDecoration: 'none',
    }
};

const dynamicStyles = {
    imagePart: (isMobile: boolean): CSSProperties => ({
        flex: 1,
        backgroundColor: '#eee',
        display: isMobile ? 'none' : 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transform: 'translateX(-100%)',
        transition: 'transform 0.5s ease-in-out'
    }),image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        display: 'block'
    }
}
const authStyles = {
    commonStyles: commonStyles,
    dynamicStyles: dynamicStyles
};

export default authStyles;