import React from 'react';

const ImageModal: React.FC<{ src: string, onClose: () => void }> = ({ src, onClose }) => (
    <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 2000,
    }}>
        <div style={{
            position: 'relative',
            margin: 'auto',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            boxShadow: '0 2px 4px rgba(0,0,0,0.5)',
            padding: '20px',
            zIndex: 2001,
        }}>
            <img src={src} alt="Car" style={{ maxWidth: '100%', maxHeight: '80vh' }} />
            <button onClick={onClose} style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                cursor: 'pointer',
            }}>X</button>
        </div>
    </div>
);

export default ImageModal;