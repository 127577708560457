import React, { useEffect, useState, useRef, useCallback } from 'react';
// assets
import styles from '../../styles/modalHelpNewUserStyles.module.css';
import authStyles from '../../styles/authStyles';
import switchImage from '../../images/icons/switch.png';
import CarStationImage from '../../images/icons/car_station.png';
import ChargersPoint from '../../images/icons/chargers-point.png';
import Logo from '../../images/Flux_Header.png';
import Map from '../../images/mapscreenshot.png';
import CarBolt from '../../images/yellow-bolt.jpeg';

interface ModalProps {
    showModal: boolean;
    closeModal: () => void;
    isMobile?: boolean;
}

const ModalHelpNewUser: React.FC<ModalProps> = ({ showModal, closeModal, isMobile }) => {
    const [animationClass, setAnimationClass] = useState('');
    const [index, setIndex] = useState(0);
    const modalRef = useRef<HTMLDivElement>(null);

    const data = [{
        title: 'Switch Cars/Stations',
        paragraph: 'Hello! I\'m happy to help clarify your options for finding cars and charging stations on the map.',
        paragraph2: 'To switch between the two, you can simply click on the icons for either cars or charging stations',
    }, {
        title: 'Charging station',
        paragraph: 'Please note that on the map, stations with the switch selected will display different icons. The green icon indicates that the station is available for charging your electric car.',
        paragraph2: 'The yellow icon means that a handler is likely to be available to load your car.',
        paragraph3: 'However, the red icon indicates that no handlers are available to load your car.',
        paragraph4: 'Remember that you can click on each station to get more information about it and even update its status.',
    }, {
        title: 'Cars',
        paragraph: 'To see all the stations that have cars for rent, simply select the "Cars" option on the switch. Once you\'ve found your preferred station, you\'ll be presented with a list of available cars to choose from. Simply select the one you want to rent and you\'re good to go!'

    }]

    // functions
    const handleConfirm = useCallback(() => {
        if (index < 2) {
            setIndex(prevIndex => prevIndex + 1);
        } else {
            closeModal();
            setIndex(0);
        }
    }, [index, closeModal]);

    // Effect to toggle animation classes based on showModal
    useEffect(() => {
        if (showModal) {
            setAnimationClass('modalEnter'); // Apply unfolding animation when modal is opened
        } else {
            setAnimationClass('modalExit');
        }
    }, [showModal]);

    // Effect to handle clicks outside the modal
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                handleConfirm();
            }
        };

        if (showModal) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showModal, handleConfirm]);



    return (
        <>
            {showModal && index === 0 && (
                <div className={styles.modalOverlay} style={{ opacity: showModal ? 1 : 0 }}>
                    <div ref={modalRef} className={`${styles.modalContent} ${animationClass}`} style={{ opacity: showModal ? 1 : 0 }}>
                        {!isMobile && <img src={Logo} alt="Banner" style={{ width: '100%', height: 100, objectFit: 'contain', padding: 10 }} />}
                        <h2 className={styles.title}>{data[0].title}</h2>
                        <img src={switchImage} alt="it is a example" className={styles.modalImage} />
                        <p className={styles.paragraph}>{data[0].paragraph}</p>
                        <p className={styles.paragraph}>{data[0].paragraph2}</p>
                        <div style={{ ...authStyles.commonStyles.buttonContent, width: '40%' }}>
                            <button onClick={handleConfirm} className="buttonBlack">
                                Go it!
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showModal && index === 1 && (
                <div className={styles.modalOverlay} style={{ opacity: showModal ? 1 : 0 }}>
                    <div ref={modalRef} className={`${styles.modalContent} ${animationClass}`} style={{ opacity: showModal ? 1 : 0 }}>
                        {!isMobile && <img src={Map} alt="Banner" style={{ width: '100%', height: 100, objectFit: 'cover', borderRadius: '10px 10px 0 0' }} />}
                        <h2 className={styles.title}>{data[1].title}</h2>
                        <img src={ChargersPoint} alt="it is a example" style={{ height: '40px', width: 'auto' }} />
                        <p className={styles.paragraph}>{data[1].paragraph}</p>
                        <p className={styles.paragraph}>{data[1].paragraph2}</p>
                        <p className={styles.paragraph}>{data[1].paragraph3}</p>
                        <p className={styles.paragraph}>{data[1].paragraph4}</p>
                        <div style={{ ...authStyles.commonStyles.buttonContent, width: '40%' }}>
                            <button onClick={handleConfirm} className="buttonBlack">
                                Go it!
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showModal && index === 2 && (
                <div className={styles.modalOverlay} style={{ opacity: showModal ? 1 : 0 }}>
                    <div ref={modalRef} className={`${styles.modalContent} ${animationClass}`} style={{ opacity: showModal ? 1 : 0 }}>
                        {!isMobile && <img src={CarBolt} alt="Banner" style={{ width: '100%', height: 150, objectFit: 'cover', borderRadius: '10px 10px 0 0' }} />}
                        <h2 className={styles.title}>{data[2].title}</h2>
                        <img src={CarStationImage} alt="it is a example" style={{ height: '40px', width: 'auto' }} />
                        <p className={styles.paragraph}>{data[2].paragraph}</p>
                        <div style={{ ...authStyles.commonStyles.buttonContent, width: '55%' }}>
                            <button onClick={handleConfirm} className="buttonBlack">
                                Go it!
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ModalHelpNewUser;