import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';

const FLUX_API_URL = process.env.REACT_APP_FLUX_API_URL;
const FLUX_API_USERNAME = process.env.REACT_APP_FLUX_API_USERNAME;
const FLUX_API_PASSWORD = process.env.REACT_APP_FLUX_API_PASSWORD;


const apiAuthenticated: AxiosInstance = axios.create({
  baseURL: `${FLUX_API_URL}`,
  timeout: 30000,
  headers: {
    'Authorization': 'Basic ' + btoa(`${FLUX_API_USERNAME}:${FLUX_API_PASSWORD}`),
    'Content-Type': 'application/json',
  }
});

const MAX_RETRIES = 10;
const SLEEP_TIME = 500;

class FluxRestApi {
  getApiStations = async (lat: number, lon: number): Promise<any> => {
    let ret: AxiosResponse<any> | null = null;
    for (let retry = 0; retry < MAX_RETRIES; retry++) {
      try {
        ret = await apiAuthenticated.get(`stations/api/get?lat=${lat}&lon=${lon}`);
        if (ret?.data) return ret;
        else throw new Error('No data');
      } catch (error: any) {
        // console.log(error?.message, 'getApiStations');
        if (error?.message !== "Network Error" || retry === MAX_RETRIES - 1) {
          // If it's the last retry or the error is not a network error, throw an error
          throw new Error('Failed after all retries or not a network error.');
        }
        await new Promise(resolve => setTimeout(resolve, SLEEP_TIME));
      }
    }
    throw new Error('Failed after all retries.');
  };

  getContactDataByEmail = async (email: string) => {
    try {
      const response = await apiAuthenticated.get(`api/contact/by-email/?email=${email}`);
      if (response.status === 200) return response;
      else return null;
    } catch (e: any) {
      if (e?.message === 'Request failed with status code 404') return e?.message
      console.log('getContactDataByEmail', e);
      return null;
    }
  };

  checkIfUserExistsByEmail = async (email: string) => {
    try {
      const response = await apiAuthenticated.get(`api/contact/by-email/?email=${email}&sign_up=True`);
      if (response.status === 200) return response;
      else return null;
    } catch (e) {
      console.log('checkIfUserExistsByEmail', e);
      return null;
    }
  };

  updateAvailableData = async (availabilityID: string) => {
    try {
      const response = await apiAuthenticated.get(`stations/update-rtdb/?availabilityID=${availabilityID}`);
      if (response?.data?.message === 'Updated RTDB with data from TomTom API.') return true;
      else return null;
    } catch (e) {
      console.log('updateAvailableData', e);
      return null;
    }
  };

  getContactDataByPhone = async (phone: string) => {
    try {
      const response = await apiAuthenticated.get(`api/contact/by-phone/?phone=${phone}`);
      if (response.status === 200) return response;
      else return null;
    } catch (e) {
      console.log('getContactDataByPhone', e);
      return null;
    }
  };

  checkIfUserExistsByPhone = async (phone: string) => {
    try {
      const response = await apiAuthenticated.get(`api/contact/by-phone/?phone=${phone}&sign_up=True`);
      if (response.status === 200) return response;
      else return null;
    } catch (e) {
      console.log('getContactDataByPhone', e);
      return null;
    }
  };


  createUser = async (email: string, password: string, phone_number: string, marketingSms: boolean, accountUpdateSms: boolean, firstName?: string, lastName?: string, booking?: boolean, verified_phone?: boolean) => {
    try {
      const data = {
        email: email,
        password: password,
        phone_number: phone_number,
        account_update_sms: accountUpdateSms,
        marketing_sms: marketingSms,
        ...(firstName && { first_name: firstName }),
        ...(lastName && { last_name: lastName }),
        ...(booking && { is_created_from_booking: booking }),
        ...(verified_phone && { verified_phone: verified_phone }),
      };
      const response = await apiAuthenticated.post('api/contact/', data);
      if (response.status === 200) return response;
      else return null;
    }
    catch (e) {
      console.log('createUser', e);
      return null;
    }
  };

  getVerificationCode = async (data: any) => {
    try {
      let url = 'twilio/get_verification_code/' + data.phone;
      if (data.email) {
        url = url + '?email=' + data.email;
      }
      const response = await apiAuthenticated.get(url);
      if (response.status === 200) return response;
      else return null;
    } catch (e) {
      console.log('getVerificationCode', e);
      return null;
    }
  };

  updateContactData = async (id: string, data: any) => {
    try {
      const response = await apiAuthenticated.put(`api/contact/${id}/`, data);
      if (response.status === 200) return response;
      else return null;
    } catch (e) {
      console.log('updateContactData', e);
      return null;
    }
  };

  approveAgreements = async (data: any) => {
    try {
      const response = await apiAuthenticated.post(`api/contact/approve-agreements/`, data);
      if (response.status === 200) return response;
      else return null;
    } catch (e) {
      console.log('approveAgreements', e);
      return null;
    }
  };

  fetchStatusPersona = async (emailContac: string, personaInquiryId: string) => {
    try {
      const response = await apiAuthenticated.get(`api/contacts/persona/?persona_inquiry_id=${personaInquiryId}&email=${emailContac}`);
      if (response.status === 200) return response;
    } catch (e: any) {
      console.error('error fetchStatusPersona', e);
      return null;
    }
  };

  getUserCards = async (stripe_id: string) => {
    try {
      const response = await apiAuthenticated.get(`api/cards/?stripe_id=${stripe_id}`);
      return response;
    } catch (e: any) {
      return e?.response;
    }
  };

  updateBundeeUserId = async (id: string, bundee_user_id: string) => {
    try {
      const response = await apiAuthenticated.patch(`api/contact/${id}/`, { bundee_user_id: bundee_user_id });
      if (response.status === 200) return response;
      else return null;
    } catch (e: any) {
      console.log('updateBundeeUserId', e);
      return null;
    }
  };

  createTicket = async (data: any) => {
    try {
      const response = await apiAuthenticated.post(`api/contact/create-ticket/`, data);
      if (response.status === 200) return response;
      else return null;
    } catch (e) {
      console.log('createTicket', e);
      return null;
    }
  };

  addPhotoCreateTicket = async (file: any, otherData: any) => {
    try {
      const formData = new FormData();
      formData.append('picture', file);
      for (const key in otherData) {
        formData.append(key, otherData[key]);
      }

      if(!formData) throw new Error('error creating formData');

      const config: AxiosRequestConfig = {
        method: 'post',
        url: `${FLUX_API_URL}api/contact/add-photo/`,
        responseType: 'json',
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Basic ' + btoa(`${FLUX_API_USERNAME}:${FLUX_API_PASSWORD}`),
          // if backend supports u can use gzip request encoding
          // "Content-Encoding": "gzip",
        },
        transformRequest: (data, headers) => {
          // !!! override data to return formData
          // since axios converts that to string
          return formData;
        },
        onUploadProgress: progressEvent => {
          // !!! progressEvent.lengthComputable always false
          // so we can't calculate progress percentage
          // console.log(progressEvent);
        },
        data: formData,
      };

      const response = await apiAuthenticated.request(config);
      if (response?.data?.tikect_id) return response;
      else return null;
    } catch (e) {
      console.log('addPhotoCreateTicket', e);
      return null;
    }
  };

  attachPaymentMethodToCustomer = async (data: any) => {
    try {
      const response = await apiAuthenticated.post(`api/attach-payment-method/`, data);
      if (response.status === 200) return response;
      else return null;
    } catch (e) {
      console.log('attachPaymentMethodToCustomer', e);
      return null;
    }
  };

  getPaymentMethods = async (stripe_id: string) => {
    try {
      const response = await apiAuthenticated.get(`api/payment-method/?customer=${stripe_id}`);
      if (response.status === 200) return response;
      else return null;
    } catch (e: any) {
      console.log('getPaymentMethods', e);
      return null;
    }
  };

  detachPaymentMethod = async (data: any) => {
    try {
      const response = await apiAuthenticated.post(`api/detach-payment-method/`, data);
      if (response.status === 200) return response;
      else return null;
    } catch (e: any) {
      console.log('deattachPaymentMethod', e);
      return null;
    }
  };

  createPdfFromDriversDataAndSignature = async (file: any, driversData: any) => {
    try {
      const formData = new FormData();
      formData.append('image', file);
      for (const key in driversData) {
        formData.append(key, driversData[key]);
      }

      if(!formData) throw new Error('error creating formData');

      const config: AxiosRequestConfig = {
        method: 'post',
        url: `${FLUX_API_URL}api/create-rental-agreement-pdf/`,
        responseType: 'blob',
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Basic ' + btoa(`${FLUX_API_USERNAME}:${FLUX_API_PASSWORD}`),
          // if backend supports u can use gzip request encoding
          // "Content-Encoding": "gzip",
        },
        transformRequest: (data, headers) => {
          // !!! override data to return formData
          // since axios converts that to string
          return formData;
        },
        onUploadProgress: progressEvent => {
          // !!! progressEvent.lengthComputable always false
          // so we can't calculate progress percentage
          // console.log(progressEvent);
        },
        data: formData,
      };

      const response = await apiAuthenticated.request(config);
      if (response?.data) return response;
      else return null;
    } catch (e) {
      console.log('createPdfFromDriversDataAndSignature', e);
      return null;
    }
  };

  savePDFOnTheServer = async (file: any, data: any) => {
    try {
      const formData = new FormData();
      formData.append('agreement_pdf', file);
      for (const key in data) {
        formData.append(key, data[key]);
      }

      if(!formData) throw new Error('error creating formData');

      const config: AxiosRequestConfig = {
        method: 'post',
        url: `${FLUX_API_URL}api/rental-agreements/`,
        responseType: 'json',
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Basic ' + btoa(`${FLUX_API_USERNAME}:${FLUX_API_PASSWORD}`),
          // if backend supports u can use gzip request encoding
          // "Content-Encoding": "gzip",
        },
        transformRequest: (data, headers) => {
          // !!! override data to return formData
          // since axios converts that to string
          return formData;
        },
        onUploadProgress: progressEvent => {
          // !!! progressEvent.lengthComputable always false
          // so we can't calculate progress percentage
          // console.log(progressEvent);
        },
        data: formData,
      };

      const response = await apiAuthenticated.request(config);
      if (response?.data) return response;
      else return null;
    } catch (e) {
      console.log('savePDFOnTheServer', e);
      return null;
    }
  };

  getRentalAgreement = async (params: any) => {
    try {
      let response = null;
      if (!params?.id) throw new Error('No id');
      const queryParams = params?.trip_id ? `hubspot_id=${params.id}&trip_id=${params.trip_id}` : `hubspot_id=${params.id}`;
      try {
        response = await apiAuthenticated.get(`api/rental-agreements/?${queryParams}`);
      } catch (e) {
        console.log('getRentalAgreement', e);
      }
      if (response?.data) return response;
      else return null;
    } catch (e: any) {
      console.log('getRentalAgreement', e);
      return null;
    }
  };

  updateStatusAccount = async (id: string, data: any) => {
    try {
      const response = await apiAuthenticated.patch(`api/contact/${id}/`, data);
      if (response.status === 200) return response;
      else return null;
    } catch (e: any) {
      console.log('updateStatusAccount', e);
      return null;
    }
  };

  resetPassword = async (data: any) => {
    try {
      const response = await apiAuthenticated.post(`api/contact/reset-password/`, data);
      if (response.status === 200) return response;
      else return null;
    } catch (e: any) {
      console.log('resetPassword', e);
      return null;
    }
  };

  cancelPaymentIntent = async (payment_intent: string) => {
    try {
      const response = await apiAuthenticated.delete(`api/cancel-payment-intent/${payment_intent}/`);
      if (response.status === 200) return response;
      else return null;
    } catch (e: any) {
      console.log('cancelPaymentIntent', e);
      return null;
    }
  };

  createPaymentIntent = async (data: any): Promise<any> => {
    try {
      const response = await apiAuthenticated.post("api/create-payment-intent/", data);
      // if the payment intent is created successfully
      if (response.status === 201) return response.data;
      else return null;
    } catch (e: any) {
      console.log('createPaymentIntent', e);
      if (e?.response?.status === 400) return e?.response?.data;
      return null;
    }
  };

  sendNotificationToSupportUsers = async (title: string, body: string, send_sms: boolean, send_email: boolean) => {
    try {
      const response = await apiAuthenticated.post(`twilio/send_notification_to_support_users/`, {
        title: title,
        body: body,
        send_sms: send_sms,
        send_email: send_email,
      });
      // console.log('response', response);
      if (response.status === 202) return response;
      else return null;
    } catch (e: any) {
      console.log('sendNotificationToSupportUsers', e);
      return null;
    }
  };

  getTwilioToken = async (uid: string) => {
    try {
      const response = await apiAuthenticated.post('twilio/generate_token/', {
        uid: uid
      });
      if (response.status === 200) return response;
      else return null;
    }
    catch (e) {
      console.log('getTwilioToken', e);
      return null;
    }
  };


  removeAConversation = async (uid: string) => {
    try {
      const response = await apiAuthenticated.post('twilio/delete_conversation/', {
        uid: uid
      });
      if (response.status === 200) return response;
      else return null;
    }
    catch (e) {
      console.log('removeAConversation', e);
      return null;
    }
  };

  addSupportToConversation = async (conversation_sid: string) => {
    try {
      const response = await apiAuthenticated.post('twilio/add_support_to_conversation/', {
        conversation_sid: conversation_sid
      });
      if (response.status === 200) return response;
      else return null;
    }
    catch (e) {
      console.log('addSupportToConversation', e);
      return null;
    }
  };

  getChatBundeeToken = async (uid: string, identityId: string, serviceId: string) => {
    try {
      const body = {
        uid: uid,
        identityId: identityId,
        serviceId: serviceId
      };
      const response = await apiAuthenticated.post(`twilio/genereate_twilio_bundee_token/`, body);
      if (response.status === 200) return response;
      else return null;
    } catch (e: any) {
      console.log('getChatBundeeToken', e);
      return null;
    }
  };

  createSetupIntent = async (data: any) => {
    try {
      const response = await apiAuthenticated.post(`api/create-setup-intent/`, data);
      if (response.status === 201) return response;
      else return null;
    } catch (e: any) {
      console.log('createSetupIntent', e);
      return null;
    }
  };

  verifyLicense = async (data: any) => {
    try {
      const response = await apiAuthenticated.post(`api/idscan/verify_license/`, data, {
        timeout: 300000 // 5 minutes
      });
      if (response.status === 200) return response;
      else return null;
    } catch (e: any) {
      console.log('verifyLicense', e);
      return null;
    }
  };

  updatePartialFluxProperties= async (id: string, data: any) => {
    try {
      const response = await apiAuthenticated.patch(`api/contact/${id}/`, data);
      if (response.status === 200) return response;
      else return null;
    } catch (e: any) {
      console.log('updatePartialFluxProperties', e);
      return null;
    }
  };

  fetchIDScanInfo = async (id: string) => {
    try {
      const response = await apiAuthenticated.get(`api/idscan/get_license_result/${id}/`, {
        timeout: 120000 // 2 minutes
      });
      if (response.status === 200) return response;
      else return null;
    } catch (e: any) {
      console.log('fetchIDScanInfo', e);
      return null;
    }
  };

  createIndividualMeasureOne = async (data: any) => {
    try {
      const response = await apiAuthenticated.post(`api/measureone/create-user`, data);
      if (response.status === 200) return response;
      else return null;
    } catch (e: any) {
      console.log('createIndividualMeasureOne', e);
      return null;
    }
  };

  createDataRequestMeasureOne = async (data: any) => {
    try {
      const response = await apiAuthenticated.post(`api/measureone/create-insurance-data-request`, data);
      if (response.status === 200) return response;
      else return null;
    } catch (e: any) {
      console.log('createDataRequestMeasureOne', e);
      return null;
    }
  };

  createPublicTokenMeasureOne = async (data: any) => {
    try {
      const response = await apiAuthenticated.post(`api/measureone/generate-public-token`, data);
      if (response.status === 200) return response;
      else return null;
    } catch (e: any) {
      console.log('createPublicToken', e);
      return null;
    }
  };

}

const fluxRestApi = new FluxRestApi();
export default fluxRestApi;
