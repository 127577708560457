import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
// hooks
// assests
import point_green_url from '../../images/point_green.png';
import point_red_url from '../../images/point_red.png';
import point_yellow_alt_url from '../../images/point_yellow_alt.png';
import point_station_WithCar_url from '../../images/icons/car_station.png';
// components




const renderLoading = (status: Status): React.ReactElement => {
    switch (status) {
        case Status.LOADING:
            return <h1>Loading...</h1>;
        case Status.FAILURE:
            return <h1>Error loading maps</h1>;
        default:
            return <></>;
    }
};


interface MapProps {
    initialLatitude: number;
    initialLongitude: number;
    initialZoom: number;
    width?: string | number;
    height?: string | number;
    dataset: any;
    onFeatureClick?: (feature: any) => void;
    gestureHandling?: string;
}


const VehiclesViewOnMap: React.FC<MapProps> = ({ initialLatitude, initialLongitude, initialZoom, width, height, dataset, onFeatureClick, gestureHandling }) => {
    // states
    const center = useMemo(() => ({ lat: initialLatitude, lng: initialLongitude }), [initialLatitude, initialLongitude]);

    // Function to determine which icon to use based on the feature's status
    const determineIcon = (status: string) => {
        switch (status) {
            case 'active':
                return point_green_url;
            case 'unknown':
                return point_yellow_alt_url;
            case 'down / off':
                return point_red_url;
            default:
                return point_yellow_alt_url; // A default icon if status is not recognized
        }
    };

    return (
        <>
            <Wrapper apiKey={process.env.REACT_APP_GOOGLE_API_KEY || ''} render={renderLoading}>
                <MapComponent
                    center={center}
                    zoom={initialZoom}
                    onFeatureClick={onFeatureClick}
                    dataset={dataset}
                    determineIcon={determineIcon}
                    width={width}
                    height={height}
                    gestureHandling={gestureHandling}
                />
            </Wrapper>
        </>
    )
}

const MapComponent = ({ center, zoom, onFeatureClick, dataset, determineIcon, width, height, gestureHandling }: any) => {
    const ref = useRef<HTMLDivElement>(null);
    const markerRefs = useRef<google.maps.marker.AdvancedMarkerElement[]>([]);
    // states
    const [map, setMap] = useState<google.maps.Map>();

    //effects
    useEffect(() => {

        if (ref.current && !map) {
            const newMap = new window.google.maps.Map(ref.current, {
                center,
                zoom,
                disableDefaultUI: false,
                zoomControl: true,
                mapTypeControl: false,
                mapId: '6d5ee41397cfdef1',
                scrollwheel: false,
                gestureHandling: gestureHandling ? gestureHandling : 'auto',
            });
            setMap(newMap);

        }
        if (map) {
             // if the marker is not clicked then onFeatureClick will be null
            map.addListener('click', () => {
                onFeatureClick && onFeatureClick(null);
            });
        }
    }, [ref, map, center, zoom, onFeatureClick, gestureHandling]);

    useEffect(() => {
        if (!map || !dataset) return;

       // Function to clear all existing markers
        const clearMarkers = () => {
            markerRefs.current.forEach(marker => {
                if (marker.map) { // Check if the marker is still on the map
                    marker.map = null; // Remove the marker from the map
                }
            });
            markerRefs.current = [];
        };

        const loadMarkers = async () => {
            clearMarkers();

            const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

            dataset.forEach((feature: any) => {
                if (feature?.properties?.numCars > 0) {
                    const Img = document.createElement('img');
                    Img.src = point_station_WithCar_url;
                    Img.style.width = '45px';

                    const marker = new AdvancedMarkerElement({
                        position: { lat: feature.geometry.coordinates[1], lng: feature.geometry.coordinates[0] },
                        map: map,
                        title: feature.properties.flux_name,
                        content: Img

                    });

                    marker.addListener('click', () => {
                        onFeatureClick && onFeatureClick(feature);
                    });
                    markerRefs.current.push(marker);
                }
            });
        };

        loadMarkers();

    }, [map, dataset, determineIcon, onFeatureClick]);

    return (
        <>
            <div ref={ref} style={{ width, height }} />
        </>
    );
};

export default React.memo(VehiclesViewOnMap);