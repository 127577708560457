import React, { useState, useEffect } from 'react';
//assets
import stylesBooking from '../../../styles/bookingStyles.module.css';
import placeHolder from '../../../images/place-holder.png';
import logo from '../../../images/logo.jpg';
// utils
import showToast from '../../../utils/toastHelpers';
// services
import bundeeRestApi from '../../../services/BundeeRestApi';
import fluxRestApi from '../../../services/FluxRestApi';
// others
import { DateRange } from 'react-day-picker';
import moment from 'moment-timezone';
// components
import { LazyLoadImage } from 'react-lazy-load-image-component';
import TimePickerMobile from '../../../components/picker/TimePickerMobile';
import DateRangePickerMobile from '../../../components/picker/DateRangePickerMobile';


interface DatePickerExtensionProps {
    isChangingTrip: boolean;
    setIsChangingTrip: (isChangingTrip: boolean) => void;
    details: any;
    userTimezone: string;
    currentContactData: any;
    setLoadingExtension: (loadingExtension: boolean) => void;
    setTotalAmount: (totalAmount: number) => void;
    setTotalAmountNew: (totalAmountNew: number) => void;
    setModalConfirmExtension: (modalConfirmExtension: boolean) => void;
    confirmExtension: boolean;
    setConfirmExtension: (confirmExtension: boolean) => void;
    setTripObject: (tripObject: any) => void;
    selectedCard: string;
}

const DatePickerExtension: React.FC<DatePickerExtensionProps> = ({ selectedCard, setIsChangingTrip, details, userTimezone, isChangingTrip, currentContactData, setLoadingExtension, setModalConfirmExtension, setTotalAmountNew, setTotalAmount, confirmExtension, setConfirmExtension, setTripObject }) => {
    const start = moment.tz(details.tripObject.starttime, userTimezone);
    const end = moment.tz(details.tripObject.endtime, userTimezone);
    const startTime = start.format('hh:mm A');
    const endTime = end.format('hh:mm A');


    // states
    const [selectedDates, setSelectedDates] = useState<DateRange | undefined>({
        from: start.toDate(),
        to: end.toDate(),
    });

    const [pickUpTime, setPickUpTime] = useState(startTime);
    const [returnTime, setReturnTime] = useState(endTime);
    const [disabledDates, setDisabledDates] = useState<any>(null);
    const [priceForm, setPriceForm] = useState({} as any);
    // Functions
    const handleDateChange = (range: DateRange) => {
        const startSelected = start
        const endSelectedTo = moment(range?.to || end).tz(userTimezone).format('YYYY-MM-DD');
        const endSelected = moment.tz(`${endSelectedTo} ${returnTime}`, 'YYYY-MM-DD hh:mm A', userTimezone);
        // we need if the range is not including a disabled date
        const checkDisabledDates = disabledDates?.find((date: any) => {
            return moment(date, userTimezone).isBetween(startSelected, endSelected, undefined, '[]')
        });
        if (checkDisabledDates) {
            handleClearDates();
            return showToast('The date range includes a disabled date', 'error');
        }
        // check also that range?.to can't be < than end
        if (endSelected.isBefore(end)) {
            handleClearDates();
            return showToast('You can only extend the trip to a later date', 'error');
        }
        setSelectedDates({
            from: start.toDate(),
            to: range?.to || end.toDate(),
        });
    };

    const handleClearDates = () => {
        setSelectedDates({
            from: start.toDate(),
            to: end.toDate(),
        });
    };

    const handleClearTimes = () => {
        setPickUpTime(startTime);
        setReturnTime(endTime);
    };

    const formatTime = (time: string) => {
        return moment(time, 'hh:mm A').format('HH:mm:ss');
    };

    const formatDate = (date: any) => {
        return moment(date).format('YYYY-MM-DD');
    };

    const getPricesForExtension = async (vehicleid: any, startTime: any, newEndTime: any) => {
        const data = {
            vehicleid: vehicleid,
            startTime: startTime,
            endTime: newEndTime,
            tripId: details.tripObject.tripid,
        };
        const response = await bundeeRestApi.calculatePrices(data);
        if (response?.data?.errorCode === '0' && response?.data?.priceCalculatedList[0]) {
            const pricesForm = {
                tripamount: response?.data?.priceCalculatedList[0]?.tripAmount,
                taxAmount: response?.data?.priceCalculatedList[0]?.taxAmount,
                tripTaxAmount: response?.data?.priceCalculatedList[0]?.tripTaxAmount,
                totalamount: response?.data?.priceCalculatedList[0]?.totalAmount,
                authorizationamount: response?.data?.priceCalculatedList[0]?.authAmount,
                authorizationpercentage: response?.data?.priceCalculatedList[0]?.authPercentage,
                perDayAmount: response?.data?.priceCalculatedList[0]?.pricePerDay,
                totalDays: response?.data?.priceCalculatedList[0]?.numberOfDays,
                discountPercentage: response?.data?.priceCalculatedList[0]?.discountPercentage,
                discountAmount: response?.data?.priceCalculatedList[0]?.discountAmount,
                tripDiscountedAmount: response?.data?.priceCalculatedList[0]?.tripDiscountedAmount,
                upCharges: response?.data?.priceCalculatedList[0]?.upcharges,
                deliveryCost: response?.data?.priceCalculatedList[0]?.delivery,
                tripFee: response?.data?.priceCalculatedList[0]?.tripFee,
                charges: response?.data?.priceCalculatedList[0]?.charges,
                taxPercentage: response?.data?.priceCalculatedList[0]?.taxPercentage,
                numberOfDaysDiscount: response?.data?.priceCalculatedList[0]?.numberOfDaysDiscount,
                registrationRecoveryFee: response?.data?.priceCalculatedList[0]?.registrationRecoveryFee,
                registrationFee: response?.data?.priceCalculatedList[0]?.registrationFee,
                averageRentalDays: response?.data?.priceCalculatedList[0]?.averageRentalDays,
                concessionFee: response?.data?.priceCalculatedList[0]?.concessionFee,
                concessionPercentage: response?.data?.priceCalculatedList[0]?.concessionPercentage,
                concessionCalculated: response?.data?.priceCalculatedList[0]?.concessionCalculated,
                tripFeeAmount: response?.data?.priceCalculatedList[0]?.tripFeeAmount,
                Statesurchargetax: response?.data?.priceCalculatedList[0]?.stateSurchargeTax,
                Statesurchargeamount: response?.data?.priceCalculatedList[0]?.stateSurchargeAmount,
            };
            return pricesForm;
        } else {
            throw new Error(response?.data?.errorMessage || 'Error');
        }
    };

    const handleExtend = async () => {
        if (!currentContactData?.contact) {
            return showToast('Failed to get user data, please reload the page', 'error');
        }
        if (!details?.tripObject?.tripPaymentTokens[0]?.tripTaxAmount.toFixed(2)) {
            return showToast('Failed to get the total amount of the trip', 'error');
        };
        if (!selectedDates?.to || !selectedDates?.from) {
            return showToast('Please select the dates', 'error');
        }
        // reductions aren't allowed
        if (selectedDates?.from && (selectedDates?.from < start.toDate())) {
            return showToast('You can only extend the trip to a later date', 'error');
        }
        // if the selected return date is the same as the current one we need to validate the time return is not a reduction
        if (formatDate(selectedDates?.to) && (formatDate(selectedDates?.to) === formatDate(end)) && (formatTime(returnTime) < formatTime(endTime))) {
            // clear times
            handleClearTimes();
            return showToast('You can only extend the trip to a later time', 'error');
        }
        // if dates and times are the same as the current ones is nothing to extend
        if (formatDate(selectedDates?.from) === formatDate(start) && formatTime(pickUpTime) === formatTime(startTime) && formatDate(selectedDates?.to) === formatDate(end) && formatTime(returnTime) === formatTime(endTime)) {
            return showToast('You have not made any changes', 'error');
        }

        if (!details?.tripObject?.tripid) {
            return showToast('Something went wrong with the trip id', 'error');
        }

        // double check if the range dates is not including a disabled date
        const startSelected = start;
        const endSelectedTo = moment(selectedDates.to).tz(userTimezone).format('YYYY-MM-DD');
        const endSelected = moment.tz(`${endSelectedTo} ${returnTime}`, 'YYYY-MM-DD hh:mm A', userTimezone);
        // const checkDisabledDates = disabledDates?.find((date: any) => {
        //     return moment(date, userTimezone).isBetween(startSelected, endSelected, undefined, '[]')
        // });
        // if (checkDisabledDates) {
        //     handleClearDates();
        //     handleClearTimes();
        //     return showToast('The date range includes a disabled date', 'error');
        // }
        try {
            setLoadingExtension(true);
            const pricesForm = await getPricesForExtension(details.carObject.vehicleid, startSelected.utc().toISOString(), endSelected.utc().toISOString());
            if (pricesForm?.tripTaxAmount) {
                setPriceForm(pricesForm);
                setTotalAmount(details.tripObject.tripPaymentTokens[0].tripTaxAmount.toFixed(2));
                setTotalAmountNew(pricesForm.tripTaxAmount.toFixed(2));
                setModalConfirmExtension(true);
                setLoadingExtension(false);
            } else {
                setLoadingExtension(false);
                showToast('Failed to calculate prices for the extension, please try again', 'error');
                setModalConfirmExtension(false);
                setTotalAmount(0);
                setTotalAmountNew(0);
                setPriceForm({});
                return;
            }
        } catch (error) {
            console.log('error', error);
            showToast('Failed to calculate prices for the extension, please try again', 'error');
            setLoadingExtension(false);
            setModalConfirmExtension(false);
            setTotalAmount(0);
            setTotalAmountNew(0);
            setPriceForm({});
        }

    };

    const handleCancel = () => {
        setIsChangingTrip(false);
    };

    // effect
    useEffect(() => {
        const getDisableDates = async (vehicleid: any) => {
            try {
                const response = await bundeeRestApi.getAvailabilityDatesByVehicleId(vehicleid);
                if (response?.data?.errorCode === '0') {
                    const dates = response.data?.unAvailabilityDate || [];
                    const disableDates = dates.map((date: any) => {
                        const unAvailableDate = moment.utc(date).tz(userTimezone)
                        const startDate = start.utc()
                        const endDate = end.utc()
                        if (!unAvailableDate.isBetween(startDate, endDate, undefined, '[]')) {
                            return moment.tz(date, userTimezone).toDate();
                        }
                        return null;
                    }).filter((date: any) => date !== null);
                    disableDates.push({
                        before: start.toDate(),
                    })
                    setDisabledDates(disableDates);
                } else {
                    console.log('response', response);
                    showToast('Failed to get disable dates', 'error');
                    setIsChangingTrip(false);
                }
            } catch (error) {
                console.log('error', error);
                setIsChangingTrip(false);
                showToast('Failed to get disable dates', 'error');
            }
        }

        if (isChangingTrip && details?.carObject?.vehicleid && userTimezone && start && end && !disabledDates) {
            getDisableDates(details.carObject.vehicleid);
        }
    }, [isChangingTrip, details?.carObject?.vehicleid, userTimezone, start, end, disabledDates, setIsChangingTrip]);

    //confirm extension
    useEffect(() => {
        // sent notification to support
        const sendNotificationToSupport = async (id: any, infoUser: string, carInfo: string, startTime: string, endTime: string) => {
            try {
                const data = {
                    "title": `TripId:${id}`,
                    "body": `New Extension: ${id} | From: ${infoUser} | Car: ${carInfo} | StartTime: ${startTime} | EndTime: ${endTime} | Device: Web App`,
                    "send_sms": true,
                    "send_email": true,
                }
                const response = await fluxRestApi.sendNotificationToSupportUsers(data.title, data.body, data.send_sms, data.send_email);
                if (response !== null) {
                    console.log('Notification sent successfully');
                } else {
                    console.log('Something went wrong with the notification');
                }
            } catch (error) {
                console.log('Something went wrong with the notification');
            }
        };

        const handleConfirmExtension = async () => {
            if (!selectedDates?.to || !returnTime || !start) {
                throw new Error('Something went wrong with the dates and times');
            }
            if (!details?.tripObject?.tripid) {
                throw new Error('Something went wrong with the  of the trip');
            }
            if (!priceForm?.tripTaxAmount) {
                throw new Error('Something went wrong with the prices');
            }
            if (!currentContactData?.contact) {
                throw new Error('Something went wrong with the user data');
            }
            if (!userTimezone) {
                throw new Error('Something went wrong with the user timezone');
            }
            if (!returnTime) {
                throw new Error('Something went wrong with the return time');
            }
            try {
                setModalConfirmExtension(false);
                setLoadingExtension(true);
                setConfirmExtension(false);

                const startSelected = start;
                const endSelectedTo = moment(selectedDates?.to).tz(userTimezone).format('YYYY-MM-DD');
                const endSelected = moment.tz(`${endSelectedTo} ${returnTime}`, 'YYYY-MM-DD hh:mm A', userTimezone);
                const data = {
                    "tripid": details.tripObject.tripid,
                    "userId": details.tripObject.userid,
                    "startTime": startSelected.utc().toISOString(),
                    "endTime": endSelected.utc().toISOString(),
                    "deductionfrequencyconfigid": 1,
                    "paymentauthorizationconfigid": 0,
                    "authorizationamount": priceForm.authorizationamount,
                    "authorizationpercentage": priceForm.authorizationpercentage,
                    "perDayAmount": priceForm.perDayAmount,
                    "totalDays": priceForm.totalDays.toString(),
                    "taxAmount": priceForm.taxAmount,
                    "tripTaxAmount": priceForm.tripTaxAmount,
                    "totalamount": priceForm.totalamount,
                    "tripamount": priceForm.tripamount,
                    "paymentMethodIDToken": selectedCard,
                    "changedBy": "USER",
                    "channelName": "Flux",
                    "comments": "",
                    "pickupTime": startSelected.utc().toISOString(),
                    "dropTime": endSelected.utc().toISOString(),
                    "discountPercentage": priceForm.discountPercentage,
                    "discountAmount": priceForm.discountAmount,
                    "tripDiscountedAmount": priceForm.tripDiscountedAmount,
                    "upCharges": priceForm.upCharges,
                    "deliveryCost": priceForm.deliveryCost,
                    "tripFee": priceForm.tripFee,
                    "charges": priceForm.charges,
                    "taxPercentage": priceForm.taxPercentage,
                    "numberOfDaysDiscount": priceForm.numberOfDaysDiscount,
                    "concessionCalculated": priceForm.concessionCalculated,
                    "concessionFee": priceForm.concessionFee,
                    "concessionPercentage": priceForm.concessionPercentage,
                    "Statesurchargeamount": priceForm.Statesurchargeamount,
                    "Statesurchargetax": priceForm.Statesurchargetax,
                    "registrationFee": priceForm.registrationFee,
                    "tripFeeAmount": priceForm.tripFeeAmount,
                    "isPaymentChanged": true,
                    "averageRentalDays": priceForm.averageRentalDays,
                    "registrationRecoveryFee": priceForm.registrationRecoveryFee,
                    "extreaMilageCost": 0,
                };
                const response = await bundeeRestApi.createExtension(data);
                if (response?.data?.errorCode === '0') {
                    // confirm extension
                    showToast('Extension confirmed', 'success');
                    setIsChangingTrip(false);
                    setDisabledDates(null);
                    setTripObject(null);
                    setLoadingExtension(false);

                    // send notification to support
                    const readAbleStartTime = moment.tz(startSelected.utc().toISOString(), userTimezone).format('MMM D, YYYY - h:mm A');
                    const readAbleEndTime = moment.tz(endSelected.utc().toISOString(), userTimezone).format('MMM D, YYYY - h:mm A');
                    const carInfoNotification = `${details?.carObject?.Make || ''} ${details?.carObject?.Model || ''} ${details?.carObject?.Color || ''} - FluxID: ${details?.carObject?.ID || ''} Plate: ${details?.carObject?.Plate || ''}`;
                    const userInfoNotification = `${currentContactData?.contact?.first_name || currentContactData?.contact?.email} ${currentContactData?.contact?.last_name || ''}`;
                    await sendNotificationToSupport(response?.data?.activeTrips[0]?.tripid, userInfoNotification, carInfoNotification, readAbleStartTime, readAbleEndTime);
                    window.location.reload();
                } else {
                    throw new Error(response?.data?.errorMessage || 'Failed to confirm extension');
                }

            } catch {
                showToast('Failed to confirm extension', 'error');
                setLoadingExtension(false);
                setConfirmExtension(false);
                setModalConfirmExtension(false);
            }
        };

        if (confirmExtension) {
            handleConfirmExtension();
        }

    }, [selectedCard, confirmExtension, setIsChangingTrip, setModalConfirmExtension, setConfirmExtension, setTripObject, setLoadingExtension, details, priceForm, returnTime, selectedDates, start, userTimezone, currentContactData]);

    return (
        <>
            <div className={stylesBooking.scrollableContent}>
                <button onClick={handleCancel} className={stylesBooking.cancelButton}>X</button>
                <div className={stylesBooking.header}>
                    <LazyLoadImage
                        src={logo}
                        alt="flux-logo"
                        effect="blur"
                        placeholderSrc={placeHolder}
                        className={stylesBooking.logo}
                    />
                    <p className={stylesBooking.subtitle}>Choose your preferred date and time to Extend</p>
                    <p className={stylesBooking.subtitle}>Click on the X on the top to Cancel</p>
                </div>
                {disabledDates ? (
                    <div className={stylesBooking.pickerContainer}>
                        <DateRangePickerMobile
                            range={selectedDates}
                            setRange={handleDateChange}
                            disabledDays={disabledDates}
                        />
                        <button onClick={handleClearDates} className={stylesBooking.clearButton}>Clear Dates</button>
                        <TimePickerMobile
                            onTimeChange={() => { }}
                            name="Pick Up Time"
                            initialTime={pickUpTime}
                            disabled={true}
                        />
                        <TimePickerMobile
                            onTimeChange={(time: string) => setReturnTime(time)}
                            name="Return Time"
                            initialTime={returnTime}
                        />
                        <button onClick={handleClearTimes} className={stylesBooking.clearButton}>Clear Times</button>
                        <button type="button" className={stylesBooking.searchButton} onClick={handleExtend}>Extend</button>
                    </div>) : (
                    <div className={stylesBooking.pickerContainer}>
                        <p>Loading...</p>
                    </div>
                )}
            </div>
        </>
    );
};

export default DatePickerExtension;