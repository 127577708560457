import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//assets
import SplashScreen from '../images/banners/reservations-banner.jpg';
import authStyles from '../styles/authStyles';
// hooks
import { useIsMobile, useAnimateOnDesktop } from '../hooks/useIsMobile';
// utils
import showToast from '../utils/toastHelpers';
import { authHelpers } from '../utils/authHelpers';
//componets
import Loader from '../components/Loader';
import SplashImage from '../components/auth/SplashImage';
// context
import { useAuth } from '../Context/AuthContext';
import fluxRestApi from '../services/FluxRestApi';

// Reminder log in & sign up share the same styles

const Login: React.FC = () => {
    const isMobile = useIsMobile();
    const shouldAnimate = useAnimateOnDesktop(isMobile);
    const navigate = useNavigate();
    // context
    const { authSignIn, ResendEmailConfirmation } = useAuth();

    // states
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [showConfirmEmail, setShowConfirmEmail] = useState(false);
    const [nextResendAt, setNextResendAt] = useState<Date | null>(null);
    const [countdown, setCountdown] = useState<number | null>(null);
    const [firstcountdown, setfirstCountdown] = useState<number | null>(null);
    const [resetPassword, setResetPassword] = useState(false);

    // funtions
    const handleLogin = async () => {
        // Check if email is valid
        if (!authHelpers.isValidEmail(email)) {
            showToast("Invalid Email!", "error");
            return;
        }
        // Check for password
        if (password === '') {
            showToast("Password is empty!", "error");
            return;
        }

        // If everything's fine, you can proceed with your login logic
        setLoading(true);
        try {
            const { success, message } = await authSignIn(email, password);
            if (success) {
                showToast(message, "success");
                navigate('/?book=car');
            } else {
                if (message === "Your account is not verified, please, check your email!") {
                    if(firstcountdown === null) {
                        const nextTime = new Date();
                        nextTime.setSeconds(nextTime.getSeconds() + 60);
                        setNextResendAt(nextTime);
                        setfirstCountdown(1);
                        setShowConfirmEmail(true);
                    } else {
                        setShowConfirmEmail(true);
                    }
                }
                showToast(message, "error");
            }
        } catch (error: any) {
            if (error.message === '"Your account is not verified, please, check your email!"') {
                if(firstcountdown === null) {
                    const nextTime = new Date();
                    nextTime.setSeconds(nextTime.getSeconds() + 60);
                    setNextResendAt(nextTime);
                    setfirstCountdown(1);
                    setShowConfirmEmail(true);
                } else {
                    setShowConfirmEmail(true);
                }
            }
            showToast(error.message, "error");
        } finally {
            setLoading(false);
        }
    }

    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        handleLogin();
    }

    const handleResendEmail = async () => {
        setLoading(true);
        try {
            const { success, message } = await ResendEmailConfirmation(email, password);
            if (success) {
                const nextTime = new Date();
                nextTime.setSeconds(nextTime.getSeconds() + 60);
                setNextResendAt(nextTime);
                showToast(message, "success");
                setShowConfirmEmail(false);
            } else {
                showToast(message, "error");
            }
        } catch (error: any) {
            showToast(error.message, "error");
        } finally {
            setLoading(false);
        }
    };

    const handleResetPassword = async () => {
        setLoading(true);
        try {
            // validate email
            if (!authHelpers.isValidEmail(email)) {
                showToast("Invalid Email!", "error");
                return;
            }

            // send reset password email
            const data ={
                email: email
            }
            const response = await fluxRestApi.resetPassword(data);
            if (response?.status === 200) {
                showToast("Please check your email to reset your password!", "success");
                setResetPassword(false);
                setEmail('');
            } else {
                showToast("Sorry, there was an error, please try again later!", "error");
            }
            setLoading(false);
        } catch (error: any) {
            showToast("Sorry, there was an error, please try again later!", "error");
            console.log(error);
            setLoading(false);
        }
    }


    useEffect(() => {
        if (nextResendAt) {
            const intervalId = setInterval(() => {
                const now = new Date();
                const secondsLeft = (nextResendAt.getTime() - now.getTime()) / 1000;
                console.log(`Seconds left: ${secondsLeft}`);
                if (secondsLeft <= 0) {
                    clearInterval(intervalId);
                    setNextResendAt(null);
                    setCountdown(null);
                } else {
                    setCountdown(Math.ceil(secondsLeft));
                }
            }, 1000);

            return () => {
                console.log('Countdown cleared'); // Log for debugging
                clearInterval(intervalId);
            }
        }
    }, [nextResendAt]);

    return (
        <div style={authStyles.commonStyles.container}>
            <Loader loading={loading} />
            <SplashImage isMobile={isMobile} animate={shouldAnimate}  SplashScreen={SplashScreen}/>
            {showConfirmEmail ? (
                <ConfirmEmail
                    isMobile={isMobile}
                    animate={shouldAnimate}
                    handleResendEmail={handleResendEmail}
                    setShowConfirmEmail={setShowConfirmEmail}
                    countdown={countdown}
                />
            ) : (
                <LoginForm
                    isMobile={isMobile}
                    email={email}
                    setEmail={setEmail}
                    password={password}
                    setPassword={setPassword}
                    animate={shouldAnimate}
                    handleFormSubmit={handleFormSubmit}
                    isPasswordVisible={isPasswordVisible}
                    setIsPasswordVisible={setIsPasswordVisible}
                    resetPassword={resetPassword}
                    setResetPassword={setResetPassword}
                    handleResetPassword={handleResetPassword}
                />
            )}
        </div>
    );
}

interface LoginFormProps {
    email: string;
    setEmail: (value: string) => void;
    password: string;
    setPassword: (value: string) => void;
    animate: boolean;
    isMobile: boolean;
    handleFormSubmit: (e: React.FormEvent) => void;
    isPasswordVisible: boolean;
    setIsPasswordVisible: (value: boolean) => void;
    resetPassword: boolean;
    setResetPassword: (value: boolean) => void;
    handleResetPassword: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({
    email, setEmail, password, setPassword, animate, isMobile, handleFormSubmit, isPasswordVisible, setIsPasswordVisible, resetPassword, setResetPassword, handleResetPassword
}) => {
    const navigate = useNavigate();
    return (
        <>
        {!resetPassword && (<>
            <div style={{...authStyles.commonStyles.formPart,justifyContent: isMobile ? 'flex-start' : 'center' ,transform: isMobile ? 'translateX(0%)' : (animate ? 'translateX(0%)' : 'translateX(-100%)') }}>
                <h2 style={isMobile ? {...authStyles.commonStyles.center, fontSize: 40} : authStyles.commonStyles.center}>Log In</h2>
                <form onSubmit={handleFormSubmit} style={authStyles.commonStyles.formLogin}>
                    <input
                        type="email"
                        name="email"
                        autoComplete='on'
                        placeholder="Email"
                        style={isMobile ? {...authStyles.commonStyles.input, width: '90%', fontSize: 20} :authStyles.commonStyles.input}
                        value={email}
                        onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
                    />
                    <div style={isMobile ? {...authStyles.commonStyles.inputWrapper, width: '95%'} : authStyles.commonStyles.inputWrapper}>
                        <input
                            type={isPasswordVisible ? "text" : "password"}
                            name="password"
                            autoComplete='current-password'
                            placeholder="Password"
                            style={isMobile ? { ...authStyles.commonStyles.input, width: '100%', fontSize: 20 } : { ...authStyles.commonStyles.input, width: '100%' }}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <span
                            style={authStyles.commonStyles.eyeIcon}
                            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                        >👁</span>
                    </div>
                    <div style={{ ...authStyles.commonStyles.center, ...authStyles.commonStyles.buttonContent }}>
                        <button className="buttonBlack" type="submit" >Log In</button>
                    </div>
                </form>
                <p onClick={() => navigate("/signup")} style={authStyles.commonStyles.left}>Sign Up</p>
                <p onClick={() => setResetPassword(true)} style={authStyles.commonStyles.left}>Forgot Password</p>
                <p onClick={() =>  navigate("/")} style={authStyles.commonStyles.left}>Charging Station</p>
            </div>
        </>)}
        {resetPassword && (
            <>
                <ResetPassword
                    isMobile={isMobile}
                    animate={animate}
                    email={email}
                    setEmail={setEmail}
                    handleResetPassword={handleResetPassword}
                />
            </>
        )}
    </>
    );

}

interface ConfirmEmailProps {
    isMobile: boolean;
    animate: boolean;
    handleResendEmail: () => void;
    setShowConfirmEmail: (value: boolean) => void;
    countdown: number | null;
}

const ConfirmEmail: React.FC<ConfirmEmailProps> = ({ isMobile, animate, handleResendEmail, setShowConfirmEmail, countdown  }) => {


    return (
        <div style={{...authStyles.commonStyles.formPart, transform: isMobile ? 'translateX(0%)' : (animate ? 'translateX(0%)' : 'translateX(-100%)') }}>
            <h2 style={authStyles.commonStyles.center}>Email Confirmation</h2>
            <p style={{ textAlign: 'center', width: '80%' }}>
                Please check your email to verify your account. If you don't see the email, check other places it might be, like your junk, spam, social, or other folders.
            </p>
            <div style={{ ...authStyles.commonStyles.center, ...authStyles.commonStyles.buttonContent }}>
                <button
                    type="button"
                    className="buttonBlack"
                    onClick={() => setShowConfirmEmail(false)}
                >
                    I've Verified My Email, Continue to Log In
                </button>
            </div>
            <div style={{ ...authStyles.commonStyles.center, ...authStyles.commonStyles.buttonContent }}>
                <button
                    type="button"
                    className="buttonBlack"
                    onClick={handleResendEmail}
                    disabled={countdown !== null}
                >
                    {countdown !== null ? `Resend in ${countdown} seconds` : "Resend Email"}
                </button>
            </div>
        </div>
    )
}

interface ResetPasswordProps {
    isMobile: boolean;
    animate: boolean;
    email: string;
    setEmail: (value: string) => void;
    handleResetPassword: () => void;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({ isMobile, animate, email, setEmail, handleResetPassword }) => {
    return (
        <div style={{...authStyles.commonStyles.formPart,justifyContent: isMobile ? 'flex-start' : 'center' ,transform: isMobile ? 'translateX(0%)' : (animate ? 'translateX(0%)' : 'translateX(-100%)') }}>
            <h2 style={authStyles.commonStyles.center}>Reset Password</h2>
            <form style={authStyles.commonStyles.formLogin}>
                <input
                    type="email"
                    name="email"
                    autoComplete='on'
                    placeholder="Email"
                    style={authStyles.commonStyles.input}
                    value={email}
                    onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
                />
                <div style={{ ...authStyles.commonStyles.center, ...authStyles.commonStyles.buttonContent }}>
                    <button className="buttonBlack" type="button" onClick={handleResetPassword}>Reset Password</button>
                </div>
            </form>
        </div>
    );
}



export default Login;
