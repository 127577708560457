import React, { useState } from "react";
// componets
import SplashImage from "../components/auth/SplashImage";
import Loader from "../components/Loader";
import AgreementsForm from "../components/AgreementsForm";
// assets
import SplashScreen from "../images/banners/banner-red-car.webp";
import authStyles from "../styles/authStyles";
// hooks
import { useIsMobile, useAnimateOnDesktop } from "../hooks/useIsMobile";

const Agreements: React.FC = () => {
    const isMobile = useIsMobile();
    const shouldAnimate = useAnimateOnDesktop(isMobile);

    // states
    const [loading, setLoading] = useState(false);

    return (
        <div style={authStyles.commonStyles.container}>
            <Loader loading={loading} />
            <SplashImage isMobile={isMobile} animate={shouldAnimate} SplashScreen={SplashScreen} />
            <AgreementsForm
                isMobile={isMobile}
                animate={shouldAnimate}
                setLoading={setLoading}
            />
        </div>
    );
}

export default Agreements;