import React, { CSSProperties } from 'react';

interface RentalAgreementProps {
    rentalAgreementData: () => Object;
    urlPDF: string;
    isMobile: boolean;
};

const RentalAgreement: React.FC<RentalAgreementProps> = ({ rentalAgreementData, urlPDF, isMobile }) => {
    const RentalAgreementStyles: { [key: string]: CSSProperties } = {
        containerRentalAgreementNumber: {
            marginTop: '1rem',
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '1rem',
        },
        rentalAgreementNumber: {
            fontWeight: 'bold',
            textDecoration: 'underline',
            paddingLeft: '0.5rem'
        },
        h3: {
            textDecoration: 'underline'
        },
        paragraph: {
            textAlign: 'justify'
        },
        borderImage: {
            borderBottom: '1px solid black'
        },
        agreementWrapper: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '1rem',
        },
        agreementText: {
            fontSize: isMobile ? '12px' : '1.5rem',
            paddingLeft: isMobile ? '5px' : '0.5rem'
        },
    };

    interface RentalAgreementData {
        rentersData: any;
        rentalLogistics: any;
        vehicleAndProviderInformation: any;
        agreementNumber: string;
    }

    const { rentersData, rentalLogistics, vehicleAndProviderInformation, agreementNumber } = rentalAgreementData() as RentalAgreementData;
    if (!urlPDF) {
        return (
            <div style={RentalAgreementStyles.paragraph}>
                <div style={RentalAgreementStyles.containerRentalAgreementNumber}>
                    Rental Agreement No. {agreementNumber}
                </div>

                <h2>Rental Agreement</h2>
                <h3 style={RentalAgreementStyles.h3} >Vehicle Rental Agreement</h3>
                <p >
                    BY SIGNING THIS AGREEMENT, YOU ARE RENTING A VEHICLE FROM THE OWNER OF RIGHTS IN
                    THE VEHICLE NAMED BELOW REFERRED TO herein as (THE “PROVIDER”), and agreeing to the
                    terms of this Rental Agreement
                </p>
                <br />


                <h3 style={RentalAgreementStyles.h3}>Vehicle and Provider Information</h3>
                <p>Vehicle(s) Make/Model: {vehicleAndProviderInformation.vehicleMakeModel}</p>
                <p>Fuel Out: {vehicleAndProviderInformation.fuelOut} </p>
                <p>Mileage Out: {vehicleAndProviderInformation.mileageOut}
                </p>
                <p>Vehicle(s) VIN: {vehicleAndProviderInformation.vehicleVin} </p>
                <p>Fuel In: {vehicleAndProviderInformation.fuelIn} </p>
                <p>Mileage In: {vehicleAndProviderInformation.mileageIn}
                </p>
                <p>Vehicle Owner ("Provider"): Gregg Hansen </p>
                <br />

                <h3 style={RentalAgreementStyles.h3}>Rental Logistics</h3>
                <p>Rental Delivery Time: {rentalLogistics.retalDeliveryTime}
                </p>
                <p>Vehicle Delivery Location: {rentalLogistics.vehicleDeliveryLocation} </p>
                <p>Vehicle Return Time: {rentalLogistics.vehicleReturnTime}
                </p>
                <p>Vehicle Return Location: {rentalLogistics.vehicleReturnLocation} </p>
                <br />
                <br />

                <h3 style={RentalAgreementStyles.h3}>Renter's Information (First Driver)</h3>
                <p>Full Name (First, Middle, Last): {rentersData.fullName}
                </p>
                <p>Current Address (Street, City, State, Zip Code): {rentersData.currentAddress}</p>
                <p>E-mail Address: {rentersData.email}</p>
                <p>Phone (Mobile): -</p>
                <p>Phone (Home): {rentersData.phone}</p>
                <p><strong>Driver's License:</strong></p>
                <p>No.: {rentersData.driverLicenseNumber}</p>
                <p>Issued by (State): {rentersData.driverLicenseState}</p>
                <p>Expiration Date: {rentersData.driverLicenseExpiration}</p>
                <p>Date of Birth: {rentersData.driverLicenseBirthDate}</p>
                <p><strong>Automobile Insurance:</strong></p>
                <p>Name of Insurance Company: - </p>
                <p>Policy Number: -</p>
                <p>Name and Phone Number of Automobile Insurance Agent: -
                </p>
                <br />

                <h3 style={RentalAgreementStyles.h3}>Renter's Signature</h3>
                <p >
                    “You” or “Your” means the person identified as the Renter or an Authorized Driver on this Rental
                    Agreement. All persons referred to as “You” or “Your” are jointly and severally bound by this
                    Agreement.
                </p>
                <p >
                    By signing below, You, agree that the vehicle shall not be driven by you or any person impaired by
                    the use of alcohol, narcotics, intoxicants, or drugs, used with or without a prescription.
                    <br />
                    <br />
                    <span>X_____________________</span>
                </p>

                <p>
                    By signing below, You, as renter of the Vehicle described above, agree to all of the terms and
                    conditions of this Vehicle Rental Agreement, the attached Vehicle Rental Terms and Conditions
                    and the Additional Terms in Exhibit A.
                    <br />
                    <br />
                    <span>X_____________________</span>
                </p>
                <br />

                <h3 style={RentalAgreementStyles.h3} >Vehicle Rental Agreement - Vehicle Rental Terms and Conditions</h3>
                <ol>
                    <li>
                        <p >
                            <u>Agreement to Rent a Vehicle.</u> This Vehicle Rental Agreement, together with any additional agreement signed
                            by you, any documents or agreements (or links to on-line documents or agreements) sent to you electronically
                            in connection with your Rental, and any return receipt or record with computed rental charges, together
                            constitutes your agreement to rent a Vehicle from Flux.
                        </p>
                    </li>
                    <li>
                        <p >
                            <u>Definitions.</u> Capitalized terms used in this Agreement and not otherwise defined herein have the meanings
                            set out on the Face Page and below:
                        </p>
                        <p >
                            “Agreement” means the Face Page, these Vehicle Rental Agreement Terms and Conditions, the Additional
                            Terms attached as Exhibit A, any additional agreement signed by you, any documents or agreements (or links
                            to on-line documents or agreements) sent to you electronically in connection with your Rental, and any return
                            receipt or record with computed rental charges.
                        </p>
                        <p >
                            “Applicable Law” means any federal, state, or local law, statute, ordinance, rule, regulation, or order that is
                            applicable to this Agreement, the Rental, or the operation of the Vehicle (including any accident involving the
                            Vehicle), as the case may be.
                        </p>
                        <p >
                            “Authorized Driver” means you any additional driver of the Vehicle listed on the Face Page of this Agreement.
                        </p>
                        <p >
                            “CDW” means Collision Damage Waiver.
                        </p>
                        <p >
                            “Face Page” means the part of this Agreement entitled “Vehicle Rental Agreement - Face Page.”
                        </p>
                        <p >
                            “Loss of Use & Diminished Value” means a reasonable estimate of all rental rate and usage loss because of
                            damage or repair to, or loss of, the Vehicle, including but not limited to lost profit.
                        </p>
                        <p >
                            “Rental” means your renting of the Vehicle from Flux under the terms of this Agreement.
                        </p>
                        <p>
                            “Rental Period” means the time between the Vehicle Delivery Time and the Vehicle Return Time as stated on
                            the Face Page.
                        </p>
                        <p>
                            “Vehicle” means the automobile or truck identified on the Face Page and any Vehicle that Flux may substitute
                            for it, and all its tires, tools, accessories, equipment, keys and Vehicle documents.
                        </p>
                        <p >
                            “Vehicle License Fee” means Flux's good faith estimate of the average per day per Vehicle portion of Flux's
                            total annual Vehicle licensing, titling, and registration costs, and any other fees as permitted under Applicable
                            Law, including any charges imposed by a governmental entity that a renter must pay to hire or lease the
                            Vehicle for the period of time to which the rental rate applies, a customer facility charge, airport concession
                            fee, tourism commission assessment, Vehicle license recovery fee, or other government imposed taxes or
                            fees.
                        </p>
                    </li>
                    <li>
                        <p >
                            <u>Driver Eligibility.</u> The Vehicle may be driven only by you (subject to conditions listed in this Agreement) and
                            any Authorized Driver who is named on Face Page and who has been instructed on the proper use of the
                            Vehicle. Except as otherwise provided by Applicable Law, these are the only “Authorized Drivers” who may
                            drive the Vehicle. In the event that the Renter who signs this agreement is blind, only persons whose Driver's
                            licenses have been verified by us and are listed herein as Authorized Drivers may drive the Vehicle. Operation
                            by an unauthorized driver voids any and all coverages and waivers. You and each Authorized Driver agree
                            and acknowledge that you: (a) are at least 25 years old (although Flux may have a different minimum age
                            requirement for rental of certain Vehicles, or permit Authorized Drivers below age 25 upon payment of an
                            additional fee); (b) have no major violations or alcohol/drug related incidents in the past three years; (c) have
                            no more than two violations or accidents combined in the past three years; (d) have been licensed to drive
                            for at least two years and be in possession of a valid driver's license (you may be required to provide a copy
                            of your driver's license when renting a Vehicle); and (e) have a valid Visa, MasterCard, Discover, Diners Club,
                            JCB, or American Express credit or charge card. By entering into this Agreement you agree to provide
                            documentation to Flux at any time to confirm your eligibility. If you have a foreign driver's license, you will be
                            required to supply additional documentation (including an English translation of such
                            license) and a valid passport to confirm your eligibility for rental, and may be charged an additional deposit if
                            you are an international driver. We reserve the right to deny rentals in our sole discretion based on information
                            about your eligibility status.
                        </p>
                    </li>
                    <li>
                        <p>
                            <u>Use Restrictions.</u>As a condition of your being eligible to rent a Vehicle from Flux, you agree that you
                            will
                            operate the Vehicle in a safe and prudent manner, and that the Vehicle will not be used or operated: (a) by
                            anyone other than you or an Authorized Driver; (b) under the influence of alcohol or drugs, including, without
                            limitation, any controlled substance under the federal Controlled Substances Act, Title 21, United States Code
                            (“<strong>Substances</strong>”); (c) by anyone under the influence of any prescription or non-prescription drug
                            for
                            which driving during use is not recommended on the label, or by reasonable opinion of a pharmacist or doctor,
                            or after you have consumed, vaporized, smoked, inhaled, or otherwise “used” any illegal drugs or Controlled
                            Substances; (d) to propel or tow any trailer or other vehicle; (e) except in an emergency, upon other than
                            paved public highways or paved or suitable graded private roads or driveways, or over bridges posted for a
                            maximum weight of three (3) tons or less; (f) without making a reasonable effort to ensure that all occupants
                            including you are wearing their seat belts; (g) for transporting groups, such as church groups, scout troops,
                            athletic teams, etc.; (h) for transporting any property deemed hazardous by reason of being flammable,
                            explosive, fissionable, or corrosive, or any drugs or contraband material; (i) loaded beyond the manufacturer's
                            stated passenger capacity; (j) for carrying passengers other than in the interior or cab of the Vehicle; (k) for
                            picking up or driving hitchhikers or persons unknown to you; (l) while operating devices that require one or
                            both hands while driving, including but not limited to cell phones, mobile computers, or devices that allow
                            texting, or without following Applicable Law relating to mobile phone use (which may differ by state); (m) while
                            wearing any device that limits your ability to hear, see, and otherwise judge driving conditions; (n) without
                            following Applicable Law governing headphone use in vehicles (which may differ by state); (o) for use as a
                            for-hire vehicle, for commercial purposes, or for anything other than personal use; (p) driven or transported to
                            Canada, Mexico, or any other destination outside of the United States; (q) driven or transported across state
                            borders without prior written approval from Flux; (r) transported by truck, train, aircraft, or vessel for any
                            reason, (except for pre-approved short-distance ferry transportation); (s) to carry out a crime or participate
                            in
                            any other illegal activity; (t) for racing on any roadway (racing at any speed); or
                            (u) without obeying all applicable seat-belt laws and child restraint or child car seat laws. ANY VIOLATION
                            OF THIS SECTION 4 WILL AUTOMATICALLY TERMINATE YOUR RENTAL AND IS AN EXCLUSION TO
                            AND VOIDS THE COLLISION DAMAGE WAIVER AND ANY OPTIONAL SERVICES THAT YOU HAVE
                            ACCEPTED, INCLUDING BUT NOT LIMITED TO RENTERS CONTINGENT LIABILITY INSURANCE,
                            ROADSIDE ASSISTANCE COVERAGE, PERSONAL ACCIDENT AND PERSONAL EFFECTS
                            INSURANCE, AND THE GLASS DAMAGE WAIVER. IT ALSO MAKES YOU LIABLE TO US FOR ALL
                            PENALTIES, FINES, FORFEITURES, LIENS AND RECOVERY AND STORAGE COSTS, INCLUDING
                            RELATED ATTORNEYS' FEES AND COSTS THAT WE MAY INCUR.
                        </p>
                    </li>
                    <li>
                        <p>
                            <u>Indemnity.</u> Subject to the terms and conditions of this Agreement, and to the fullest extent permitted by
                            Applicable Law, you agree to indemnify Flux and its directors, officers, employees, and agents (collectively,
                            the “Indemnified Parties”), defend the Indemnified Parties, and hold the Indemnified Parties harmless from
                            all claims, suits, actions, proceedings, liability, losses, damages, penalties, awards, and costs, including
                            attorneys' fees and costs (collectively, “Claims”), brought against or incurred by the Indemnified Parties,
                            including third-party Claims, resulting from, arising out of, or occurring in connection with this Agreement,
                            the Rental, or your use of the Vehicle.
                        </p>
                    </li>
                    <li>
                        <p >
                            <u>Disclaimer of Warranties.</u> PROVIDER MAKES NO WARRANTIES, EXPRESS OR IMPLIED, REGARDING
                            THE VEHICLE, INCLUDING ANY WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR
                            PURPOSE, AND ALL SUCH WARRANTIES ARE EXPRESSLY DISCLAIMED.
                        </p>
                    </li>
                    <li>
                        <p>
                            <u>Repossession.</u> This Agreement is a contract for rental of the Vehicle from Flux. Flux may repossess the
                            Vehicle at your expense without notice to you if the Vehicle is abandoned or used in violation of Applicable
                            Law or this Agreement.
                        </p>
                    </li>
                    <li>
                        <p >
                            <u>Early Termination by Flux.</u> Flux shall have the right, under certain circumstances and in its sole discretion, by
                            delivery of notice to you, to terminate the Rental and/or Subscription prior to expiration of the Rental and/or
                            Subscription Period and require that you return the Vehicle. In the event of an early termination under this
                            Section 8 for your Rental, Flux will refund to you, on a pro-rated basis, the portion of the fees payable by you
                            in connection with the Rental for the period from the return date to the original expiration date of the Rental
                            Period. In the event of an early termination under this Section 8 for your Subscription, Flux will refund to
                            you for the remainder of your subscription and the car will need to be returned within the timeframe designated
                            by Flux.
                        </p>
                    </li>
                    <li>
                        <p >
                            <u>Condition and Return of Vehicle.</u> You must return the Vehicle to the Vehicle Return Location specified on the
                            Face Page, on the date and at the time specified on the Face Page, in the same condition that you received
                            it, except for ordinary wear and tear. You must also return the Vehicle to Flux as instructed by Flux at any time
                            regardless of whether your Rental Period has expired or is active. You must also return the Vehicle to Flux if
                            at any time you violate any of the terms of the eligibility requirements indicated in Section 3 above. Service to
                            the Vehicle or replacement of parts or accessories during the Rental Period must have Flux's prior approval.
                            If you return the Vehicle more than one (1) hour after the Vehicle Return Time specified on the Face Page,
                            you will be charged for an additional twenty-four (24) hour Rental of the Vehicle. Also, if you fail to return the
                            Vehicle within forty-eight (48) hours after the Vehicle Return Date or as instructed by Flux for other reasons
                            indicated herein, you will be deemed to be in unlawful possession of the Vehicle and Flux may exercise all of
                            its rights and remedies including the right to recover possession of the Vehicle. If you fail to return the Vehicle
                            and fail to obtain an extension of the Rental Period from Flux, we may report the Vehicle as stolen. You will
                            also be charged a repossession fee of not less than $300 if we have to repossess the Vehicle (or more if it
                            costs us more to repossess it), and any towing and impound/storage charges. You hereby waive any claims
                            for damages or injury for false arrest against Flux arising from or related to the reporting of the Vehicle as
                            stolen or embezzled. If vehicle is stolen while on rental or subscription and customer is deemed at fault,
                            customer will not receive any type of refund and may be liable for additional fees. If vehicle is stolen while on
                            rental or subscription and customer is deemed not at fault, customer will receive at minimum, a pro-rated
                            refund on their rental or subscription.
                        </p>
                    </li>
                    <li>
                        <p>
                            <u>Responsibility for Damage or Loss; Reporting to Police.</u> You are responsible for all damage to or loss of the
                            Vehicle caused, directly or indirectly, by collision, act of nature, or violation of Section 4 above, whether or not
                            you are at fault. You are responsible for missing equipment and for the cost of repair or the actual retail fair
                            market value of the Vehicle on the date of the loss if the Vehicle is not repairable. You are responsible for the
                            reimbursement of all Loss of Use & Diminished Value that may occur, to Flux or its agent, if any damage or
                            loss that occurs during the Rental Period prohibits Flux from renting the Vehicle to others while the Vehicle is
                            being repaired, up to the maximum amount allowable under Applicable Law. You are responsible for Flux's
                            administrative expenses arising out of a Vehicle damage claim in accordance with Applicable Law. You are
                            responsible for all damage that occurs in connection with a theft of the Vehicle. You are responsible for loss
                            due to theft and all damage due to vandalism of the Vehicle if you fail to exercise ordinary care while in
                            possession of the Vehicle. Allowing a person who is not an Authorized Driver to use the Vehicle is not an
                            exercise of ordinary care, but a willful and reckless act and a material breach of this Agreement. You must
                            report all accidents involving the Vehicle, or theft of or vandalism to the Vehicle, to Flux within twenty-four (24)
                            hours of occurrence, and to the police as soon as possible after occurrence or your discovery of them. As part
                            of any accident report you must provide a written description of the accident and the insurance information of
                            the other parties involved. You must make a reasonable effort to secure evidence from any available
                            witnesses to the accident.
                        </p>
                    </li>
                    <li>
                        <p >
                            <u>Insurance.</u> You are responsible for all damage or loss you cause to others, or injury caused to others, by the
                            use or operation of the Vehicle during the Rental Period and prior to return of the Vehicle to Flux. Your own
                            insurance coverage, or any Supplemental Liability Insurance coverage you may have purchased, may cover
                            your liability under this provision, but will not limit the amount of your liability to less than the total amount of
                            losses or claims by third parties. You and all Authorized Drivers agree to comply with Applicable Law and
                            maintain liability insurance which covers bodily injury to or death of another person or property damage to
                            another's vehicle or property, in the following minimum amounts: $25,000 for bodily injury or death to any one
                            person in an accident; $50,000 for bodily injury or death to all persons in any one accident; and $15,000 for
                            property damage in any one accident. Where Applicable Law requires Flux to provide auto liability insurance,
                            or if you have no auto liability insurance, Flux provides auto liability insurance (the “Policy”) that is secondary
                            to any other valid and collectible insurance whether primary, secondary, excess, or contingent. The Policy
                            provides bodily injury and property damage liability coverage with limits no higher than minimum levels
                            prescribed by the financial responsibility laws of the jurisdiction whose laws apply to the loss. You and we
                            reject Personal Injury Protection (PIP), medical payments, no-fault, uninsured, and underinsured motorist
                            coverage. Any coverage provided to you under this Agreement is void if you violate the terms of this
                            Agreement or if you fail to cooperate in any loss investigation conducted by us or our insurer. Giving the
                            Vehicle to an unauthorized driver terminates our liability insurance coverage, if any. You grant us a limited
                            power of attorney to present claims to your insurance carrier for damage to or loss of the Vehicle that occurs
                            while you are responsible for the Vehicle.
                        </p>
                    </li>
                    <li>
                        <p >
                            <u>Charges and Costs.</u> You agree to pay us on demand for all charges due us under this Agreement, including,
                            but not limited to: (a) time and mileage for the period during which you keep the Vehicle, or a mileage charged
                            based on our experience if the odometer or its seal is tampered with or disconnected; (b) charges for additional
                            drivers; (c) charges for the optional services you elected to purchase; (d) gasoline, if you return the Vehicle
                            with less gasoline that when rented; (e) applicable sales, use and other taxes; (f) loss of, or damage to, the
                            Vehicle, which includes the cost of repair, or the retail fair market value of the Vehicle based on the valuation
                            methods accepted by the auto insurance industry on the date of the loss if the Vehicle is not repairable, plus
                            loss of use, diminution of the Vehicle's value cause by damage to it or repair of it, and our administrative
                            expenses incurred processing the claim; (g) all fines, penalties, forfeitures, court costs, towing charges and
                            other expenses involving the Vehicle assessed against us or the Vehicle, unless these expenses are our fault;
                            (h) all expenses Flux incurs in locating and recovering the Vehicle if you fail to return it or if we elect to
                            repossess the Vehicle under the terms of this Agreement; (i) all costs, including pre- and post-judgment
                            attorneys' fees, we incur collecting payment from you or otherwise enforcing our rights under this Agreement;
                            (j) a 2% per month late payment fee, or the maximum amount allowed by Applicable Law (if less than 2%) on
                            all amounts past due; (k) 1½% per month interest, or the maximum amount allowed by Applicable Law (if less
                            than 1½%) on monies due us but not paid upon return of the Vehicle; (l) a minimum of $50, plus $5 per mile
                            for every mile between the returning location and the place where the Vehicle is returned or abandoned, plus
                            any additional recovery expenses we incur; (m) $25 or the maximum amount permitted by Applicable Law,
                            whichever is greater, if you pay us with a check returned for insufficient funds; (n) a reasonable fee to clean
                            the Vehicle if returned less clean than when rented; (o) miles driven in excess of the allowable numbers of
                            miles per day or during the Rental Period as indicated on the Face Page; and (p) all expenses incurred by
                            Flux in the collection of amounts due Flux under this Agreement or in regaining possession of Vehicle or in
                            enforcing any term or condition of this Agreement, including attorneys' fees, Flux's administrative fees, and
                            any other costs or expenses incurred by Flux. For a Vehicle rented with a cash, check or money order deposit,
                            any excess deposit will be refunded by check issued within 15 business days after the end of the Rental
                            Period.
                        </p>
                    </li>
                    <li>
                        <p>
                            <u>Your Property.</u> You hereby release Flux and each of its directors, officers, shareholders, agents and
                            employees from all claims for loss of, or damage to, your personal property or that of any other person, that
                            was received, handled or stored, or that was left or carried in or on the Vehicle or in any service Vehicle or in
                            Flux's offices, whether or not the loss or damage was caused by Flux's negligence or was otherwise Flux's
                            responsibility.
                        </p>
                    </li>
                    <li>
                        <p >
                            <u>Limitation on Liability.</u> TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU HEREBY
                            RELEASE PROVIDER AND ITS DIRECTORS, OFFICERS, SHAREHOLDERS, MANAGERS, MEMBERS,
                            AGENTS AND EMPLOYEES FROM ANY LIABILITY FOR INDIRECT, INCIDENTAL, CONSEQUENTIAL,
                            SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES IN CONNECTION WITH THIS AGREEMENT, THE
                            RENTAL OR YOUR USE OF THE VEHICLE.
                        </p>
                    </li>
                    <li>
                        <p>
                            <u>GPS Vehicle Tracking.</u> You acknowledge and agree that the Vehicle may be equipped with GPS tracking
                            systems including a telematics device and that Flux, third party operators of the GPS system or affiliates of
                            such third parties may all have access to the location of the Vehicle or access to other information supplied
                            by the GPS system. You acknowledge and agree that you do not have any expectation of location privacy,
                            route privacy or speed privacy while using the Vehicle. For a copy of our Privacy Policy, please go to:
                            <a href="https://www.fluxev.city/privacy-policy">https://www.fluxev.city/privacy-policy</a>.
                        </p>
                    </li>
                    <li>
                        <p >
                            <u>Breach of Agreement.</u> The acts listed in Section 4 above are prohibited uses of the Vehicle and constitute
                            breaches of this Agreement. You waive all recourse against Flux for any criminal reports or prosecutions that
                            Flux take against you that arise out of your breach of this Agreement.
                        </p>
                    </li>
                    <li>
                        <p >
                            <u> Entire Agreement.</u> The Agreement constitutes the entire agreement between you and Flux. All prior
                            understandings, representations, and agreements between you and Flux, whether in written or electronic form
                            or made verbally, regarding this Rental are void.
                        </p>
                    </li>
                    <li>
                        <p >
                            <u>Modifications.</u> Unless otherwise explicitly stated herein, no part of this Agreement can be waived or modified
                            except by a writing that Flux and you have signed. If you wish to extend the Rental Period, you must follow
                            Flux's extension procedures as specified in [the Flux Terms of Service at: https://Fluxev.city/docs/tos.] [the
                            “Extensions” procedure as specified in Exhibit A.] Following an extension, if you fail to be physically present
                            at the end of the Rental Period for the inspection and/or fail to sign the written amendment, you hereby agree
                            to the unilateral modification by Flux of the Vehicle Return Time listed on the Face Page and agree to be
                            bound by the terms and conditions of this Agreement up to such Vehicle Return Time, as amended. In
                            addition, If you fail to be physically present at the end of the Rental Period for the inspection and/or fail to sign
                            the written amendment, you hereby waive your right to dispute any charges for damages found by Flux at the
                            end of your Rental Period.
                        </p>
                    </li>
                    <li>
                        <p >
                            <u>Waiver.</u> A waiver by either you or Flux of any breach of this Agreement is not a waiver of any additional breach
                            or waiver of the performance of your or Flux's obligations under this Agreement. Flux's acceptance of payment
                            from you or Flux's failure, refusal or neglect to exercise any of its rights under this Agreement does not
                            constitute a waiver of any other provision of this Agreement.
                        </p>
                    </li>
                    <li>
                        <p >
                            <u>Severability.</u> If any provision of this Agreement is deemed or held to be void or unenforceable, the remaining
                            provisions shall be valid and enforceable.
                        </p>
                    </li>
                    <li>
                        <p >
                            <u>Electronic Document and Signature.</u> You hereby: (a) consent to receive communications from Flux in
                            electronic form; and (b) agree that all terms and conditions, agreements, notices, disclosures and any other
                            communications that Flux provides to you electronically satisfy any legal requirement that such
                            communications would satisfy if they were in a print-on-paper writing. BY SIGNING THIS AGREEMENT
                            ELECTRONICALLY, YOU ARE ACCEPTING THIS AGREEMENT AS IF THIS AGREEMENT WERE
                            ACTUALLY SIGNED BY YOU MANUALLY. BY SIGNING THIS AGREEMENT ELECTRONICALLY, YOU
                            AGREE TO BE LEGALLY BOUND BY THIS AGREEMENT'S TERMS AND CONDITIONS, AND YOU AGREE
                            THAT YOUR ELECTRONIC SIGNATURE IS THE LEGAL EQUIVALENT OF YOUR MANUAL
                            SIGNATURE ON THIS AGREEMENT. You also agree that no certification, authority, or other third party
                            verification is necessary to validate your E-Signature and that the lack of such certification or third party
                            verification will not in any way affect the enforceability of your E-Signature or any resulting contract between
                            you and us.
                        </p>
                    </li>
                    <li>
                        <p >
                            <u>Dispute Resolution.</u>
                        </p>
                        <p >
                            <strong>Arbitration and Class Action Waiver.</strong> YOU AND FLUX EACH WAIVE THEIR RIGHT TO A JURY TRIAL
                            OR TO PARTICIPATE IN A CLASS ACTION PURSUANT TO THE FOLLOWING TERMS. You and Flux
                            agree to arbitrate any and all disputes, claims, or controversies of any kind (“Claims”) against each other,
                            including but not limited to Claims arising out of or relating to this Agreement, the Rental, and our services,
                            including without limitation Claims based on contract, tort, fraud, agency, negligence, statutory or regulatory
                            provisions or any other source of law. The arbitrator, and not any federal, state or local court or agency, shall
                            have authority to resolve any and all Claims relating to the interpretation, applicability, enforceability, or
                            formation of this Agreement, including but not limited to any Claim that all or any part of this Agreement is
                            void or voidable. You and Flux agree that no Claims will be asserted in any representative capacity on a
                            class-wide or collective basis, that no arbitration forum will have jurisdiction to decide any claims on a
                            class-wide or collective basis, and that no rules for class-wide or collective arbitration will apply. You and Flux
                            agree, however, that either party may bring an individual action in a small claims court with valid jurisdiction
                            provided that the action is not made part of a class action or other representative or collective action.
                        </p>
                        <p >
                            <strong>Dispute Resolution Procedure.</strong> Before asserting a Claim in any proceeding, you and Flux agree that either
                            party shall give the other party written notice of the Claim to be asserted thirty (30) days before initiating a
                            proceeding and make a reasonable good faith effort to resolve the Claim. If you intend to assert a Claim against
                            Flux, you must send written notice of the Claim to Walk Streets Management, LLC, 1909 Blue Crest Dr, Austin,
                            TX, 78704, Attention: Legal. If Flux intends to assert a Claim against you, we will send written notice of the
                            Claim to you at your address on the Face Page. No demand for arbitration may be made after the date when
                            the institution of legal or equitable proceedings based on such Claim would be barred by the applicable statute
                            of limitations.
                        </p>
                        <p >
                            No settlement demand or settlement offer used prior to any legal proceeding may be used in any proceeding,
                            including as evidence or as an admission of any liability (or lack thereof), and shall remain confidential
                            between you and Flux.
                        </p>
                        <p >
                            If you and Flux do not resolve the Claim within thirty (30) days after the above described notice is received,
                            either party may commence arbitration by filing a demand for arbitration with the American Arbitration
                            Association (“<strong>AAA</strong>”) pursuant to its Consumer Arbitration Rules. Claims will be resolved pursuant to the AAA's
                            Consumer Arbitration Rules in effect at the time of the Claim, as modified by this Agreement. A single
                            arbitrator will be selected according to AAA's Consumer Arbitration Rules. The parties waive any right to
                            seek or recover punitive damages, and no arbitrator shall have the power or authority to award punitive
                            damages. The parties will split all costs of arbitration on equal basis. If a party fails to advance its portion of
                            the arbitration costs, any other party may advance those costs without prejudice to its right to recoup the
                            amount advanced as a prevailing party. The AAA rules can be found at www.adr.org. This arbitration
                            agreement is subject to the Federal Arbitration Act. The arbitrator has no authority to join or consolidate
                            claims, or adjudicate joined and consolidated claims. The parties agree that the arbitrator's decision and
                            award will be final and binding and may be confirmed or challenged in any court with jurisdiction as permitted
                            under the Federal Arbitration Act.
                        </p>
                        <p >
                            If any portion of this Section is deemed to be invalid or unenforceable or is found not to apply to a
                            Claim, the
                            remainder of this Section shall remain in full force and effect. However, if the “Arbitration and Class
                            Action
                            Waiver” provision of this Section is deemed unenforceable, any class action Claim must proceed in a
                            court of
                            competent jurisdiction.
                            <br />
                            You have the right to opt out of the mandatory arbitration procedure in this Section by providing
                            written notice
                            of your intention to do so to Walk Streets Management, LLC, 1909 Blue Crest Dr, Austin, TX, 78704,
                            Attention: Legal, within 30 days after your acceptance of this Agreement.
                        </p>
                    </li>
                </ol>

                <h2>EXHIBIT A</h2>
                <h3 style={RentalAgreementStyles.h3}>Vehicle Rental Agreement - Additional Terms</h3>
                <ol>
                    <li>
                        <p>
                            <strong>Vehicle Selection:</strong> Any Vehicles that you select to rent are representative only and do not
                            indicate the type
                            of Vehicle you will receive. Even if you select a specific type of Vehicle, you may be provided a Vehicle of
                            a
                            different type, which is not substantially smaller than and seats no fewer persons than the Vehicle of your
                            original choosing. You will be issued a refund if you receive a Vehicle priced lower than your original
                            booking
                            at the time of the booking.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Optional Features:</strong> If you request optional features such as a child seat, the cost of these
                            features will be
                            refunded if they are not available on your Vehicle. Availability of these features is not guaranteed.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Rates and Quotes:</strong> Vehicle rental rates vary considerably from location to location and from
                            time to time, and
                            they change very frequently. Unless it is provided in connection with certain types of reservations, a rate
                            quote
                            you receive is merely indicative of the rates then being offered and creates neither legal rights nor
                            practical
                            expectations of Vehicle availability or the continued applicability of the quoted rate.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Reasonable Care:</strong> You must drive and park with reasonable care, keep the Vehicle clean, and
                            return it on
                            time.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>No Smoking:</strong> No smoking is allowed in or directly around any Vehicle. You will be assessed a
                            Cleaning Fee
                            of at least $250 if you or any other individual violates this policy with the Vehicle.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Pets and Cleaning:</strong> You must leave the Vehicle in a clean condition and remove any trash
                            from
                            the Vehicle
                            prior to returning it. You may be assessed a cleaning fee of up to $300 if you violate this policy.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Gas:</strong> You must return the Vehicle with the same amount of gas in the tank as there was at
                            the
                            start of the
                            Rental. If the gas is not filled back to the level present at the beginning of the Rental, you will be
                            subject
                            to the
                            refueling cost as charged by Flux pursuant to Flux's re-fueling rates. Flux may charge an additional fee for
                            refueling.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Add-Ons:</strong> You are responsible to maintain all equipment in the Vehicle in good condition. If
                            you
                            return such
                            equipment damaged or fail to return it, you will be charged fees for such damage or return, including the
                            following fees: GPS: $200, Car seat: $100, FasTrak or other toll transponder: $75.
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>Authorized Drivers:</strong> Only you or a pre-approved Authorized Driver may drive or operate the
                            Vehicle during
                            the Rental Period. IF AN UNAUTHORIZED INDIVIDUAL OPERATES THE VEHICLE DURING THE RENTAL
                            PERIOD, ALL INSURANCE COVERAGE FURNISHED TO YOU BY PROVIDER, INCLUDING PURCHASED
                            COVERAGE, WILL BE VOID, AND YOU MAY BE CHARGED AN UNAUTHORIZED DRIVER FEE OF UP TO
                            $250.
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>Documentation of Pre-existing Damage:</strong> Before driving the Vehicles, Flux may inspect the
                            Vehicle
                            and take
                            photos of any pre-existing damage. Nevertheless, it is ultimately your responsibility to confirm that all
                            pre-
                            existing damage is noted, including any non-functional features or components of the Vehicle. You may be
                            liable for any damage that is not documented before driving the Vehicle.
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>Parking Tickets and Traffic Violations:</strong> You agree to be solely liable for parking tickets,
                            traffic citations, and
                            other violations that occur during the Rental, and all fees resulting from such violations, even if you are
                            not
                            driving. It is solely your responsibility to pay these fees in a timely manner and to comply with all legal
                            obligations that arise from such violations. If you wish to dispute a ticket you must inform us immediately.
                            If
                            you do not pay the tickets or fees, Flux will charge you for the amount of the ticket, plus any penalties
                            associated with the ticket/citation, plus an up to $40 administrative fee to pay for the ticket.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Driving Safety:</strong> If you believe that a Vehicle is unsafe to drive due to an "easily"
                            correctable
                            problem (as determined solely by Flux), for example, a flat tire, Flux will work with you to remedy this
                            condition quickly. If you remain uncomfortable with the Vehicle after a fix is performed by a certified,
                            licensed mechanic, you may return the Vehicle to the original delivery location to exchange Vehicles, based
                            on
                            availability. Flux, however, is under absolutely no obligation to arrange for a different Vehicle to be
                            provided
                            to you and under no circumstances will Flux have an alternate Vehicle brought to you.
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>Maintenance:</strong> You are responsible for reporting to Flux immediately any serious conditions
                            or
                            problems with the Vehicle, including a "Check Engine" light, as soon as they occur. You should discontinue
                            driving immediately if you notice a serious condition or problem and contact Flux; Flux will attempt to
                            arrange
                            an alternate Vehicle for your use or help you resolve the serious condition or problem.
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>Stickers and Car Modification:</strong> You may not place stickers, signs, symbols, or other
                            devices,
                            modifications, advertisements or publicity materials on the interior or exterior of the Vehicle.
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>Maintenance and Repairs:</strong> You are not responsible for routine maintenance and repairs of the
                            Vehicle. However, you may be held liable for the repair cost of all other repairs or damage to the car
                            during
                            the Rental, and must follow the reporting, claims processing, and repair procedure set forth below for all
                            such
                            damage.
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>Cancellations:</strong> Cancellations can be made any time until 48 hours before the scheduled
                            delivery
                            time with no fee. For cancellations made within 48 hours of the scheduled delivery time, no refund will be
                            issued.
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>Changes:</strong> Changes to a Rental length or time period can be made any time until 2 hours
                            before
                            the scheduled
                            delivery time with no fee except any price difference as calculated by current prices at the time of the
                            change.
                            Changes to a Rental length or time period made within 2 hours before the scheduled Vehicle Delivery Time
                            will incur a rescheduling fee of up to $50 in addition to any price difference as calculated by current
                            prices
                            at
                            the time of the change. For changes made within 48 hours of the scheduled Vehicle Delivery Time, no refund
                            will be issued for difference in price.
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>Extensions:</strong> You may contact Flux to see if the Vehicle is available from Flux for an
                            extended
                            time period. If
                            you wish to extend the Rental and the Vehicle is available, you will be assessed any additional charges for
                            the new Rental days based on current market pricing, with a minimum of one extra day worth of charges. Your
                            original price is not the prevailing price if you wish to extend; you must inquire as to the prevailing
                            price
                            that
                            will apply to any extension. If an extension is not available, the Vehicle must be returned at the original
                            scheduled Vehicle Return Time. Changes to a Rental Period made within 2 hours before the scheduled
                            Vehicle Delivery Time will incur a rescheduling fee in addition to any price difference as calculated by
                            current
                            prices at the time of the change.
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>Delays:</strong> You are responsible for arriving at the scheduled time for your scheduled delivery
                            and
                            return. If you
                            arrive over 10 minutes after your scheduled Vehicle Delivery Time or scheduled Vehicle Return Time, a fee
                            may apply. If you fail to return the Vehicle at your scheduled Vehicle Return Time, you may be assessed
                            additional charges for an additional Rental day based on current market pricing.
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>Returns:</strong> You are ultimately responsible for returning the Vehicle to the designated Vehicle
                            Return Location
                            and will be charged significant penalty fees if You do not comply. These charges often exceed $1,000.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>One Way Rentals:</strong> One-way Rentals (picking up at one location and returning at another) may
                            be
                            permitted
                            by Flux in some cases. It is at Flux's discretion which one-way combinations are permitted.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>No Shows:</strong> A No Show is defined as failing to arrive, or notify Flux of your delay, within
                            10
                            minutes of your
                            scheduled Vehicle Delivery Time. A “No Show” will result in your reservation being canceled and does not
                            entitle you to a refund. If you wish to rebook, there is no guarantee that your originally quoted rate will
                            be
                            available.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Responsibility:</strong> You hereby agree that Flux and its affiliates, and its and their directors,
                            officers, shareholders,
                            employees, and agents, are not responsible for consequential damages should any of them fail to meet their
                            obligations in connection with a Rental transaction. Similarly, you are not responsible for consequential
                            damages should you fail to meet your obligations in connection with the transaction.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Impound:</strong> If the Vehicle is impounded during your Rental, you will be fully responsible for
                            any
                            towing and
                            storage fees that arise as a result of the impound, unless the impound was caused by an issue completely
                            outside of your control (such as the registration being expired). It will be your responsibility to assist
                            Flux
                            in
                            retrieving the Vehicle, or to do it yourself, if it is possible.
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>Payments for Damage and Accidents:</strong> If you choose not to select the optional CDW from Flux,
                            or
                            if you
                            invalidate the CDW you selected, and you cause damage to a Vehicle, your payment method on file may be
                            charged for the entire amount of the damage repair cost. You also agree to be held responsible for any
                            reasonable administrative fees, as well as rental car costs (including loss of rental income/loss of use
                            value)
                            while the Vehicle is being repaired.
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>Cooperation with Loss Investigation:</strong> If the Vehicle is damaged in any way or involved in a
                            collision or
                            accident, you agree to fully cooperate in any loss investigation that Flux may choose to conduct.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Claims Administrative Fee & Diminished Value:</strong> You will be charged a claim administrative
                            fee of:
                            ($50 if
                            the repair estimate is less than $500, $100 if between $500 and $1,499, and $150 if greater than $1,500).
                            Furthermore you will be charged diminishment of value (10% of the repair estimate if the damages are greater
                            than $499).
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>Estimate Fee:</strong> If you do not select the optional CDW or provide coverage information, you
                            will
                            be charged an
                            estimate fee of $80.
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>Collection & Subrogation Fees:</strong> You may be charged for any costs that occur as part of the
                            collection
                            process.
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>Cracks on Windshield:</strong> If you do not select the optional Glass Damage Waiver or provide
                            coverage
                            information, you will be charged up to $75 for cracks on the windshield smaller than a quarter.
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>Keys:</strong> You will be charged a fee between $300 and $1,000 for a lost key.
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>Flat Tire / Tire Damage:</strong> If the tire needs to be replaced you will be charged up to $350
                            per tire.
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>Minor Exterior Wear & tear:</strong> Minor Exterior Wear & Tear is any dings, dents, cracks, or
                            scratches to the
                            exterior body of the vehicle that are three inches or less. Minor Exterior Wear & Tear includes, but isn’t
                            limited to, rims, wheels, hubcaps, glass, tire or any painted or textured area for the body of the vehicle,
                            and
                            moldings. There will be a $200 flat fee charged to the customer for damage that qualifies as Minor Exterior
                            Wear & Tear. Any damage which is greater than Minor Exterior Wear & Tear as described herein will be
                            charged to renter at full repair or replacement value.
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>Deposit:</strong> Prior to delivery of the Vehicle you agree to provide a vehicle security deposit
                            (“Deposit”). You acknowledge that, unless prohibited by applicable Law, Flux is authorized to deduct any
                            part of the Deposit to cover liabilities arising from your use of the Vehicle. For security purposes only a
                            payment card can be used to provide the Deposit. In general, an authorization of a minimum of $200 plus the
                            amount due for the Rental will be taken as authorization against the payment method for security purposes.
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>EV Rentals:</strong> The following section outlines the supplementary conditions applicable within
                            the rental agreement when renting an Electric Vehicle through Flux.
                        </p>
                    </li>

                </ol>

                <ul>
                    <li>
                        <p>
                            <strong>EV Charge Level at Pick-up and Return:</strong> Flux will provide the EV at the time of vehicle pick-up with
                            a
                            battery charge of 80%. The customer is required to return the EV with 80% charge, or additional
                            charges may apply. The customer is required to return their car with a battery charge of a minimum of
                            20%, otherwise, the customer will be charged an additional “Undercharge Battery Fee.”
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Please note that a breakdown caused by low battery (below 20%) will be your sole
                                responsibility.</strong>
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Range:</strong> Range is the estimated distance an EV can travel on a single charge. The EV information
                            provided with your reservation that describes a range is not guaranteed. The battery life of the EV is
                            impacted by a number of factors including weather, driving and road conditions. It is your responsibility
                            to ensure the EV has sufficient remaining battery life to return the EV to Flux or reach an EV charging
                            station.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Charging During Rental:</strong> The customer is fully self-responsible for charging their EV during their rental.
                            The usage of charging stations may incur additional costs for the customer.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Equipment:</strong> The EV will be provided to you with certain equipment for which you are responsible. You
                            are responsible to notify Flux if any of the equipment is not with the EV at the time of pick up.
                            Otherwise, you will be charged for any missing equipment at return. Our collision Damage Waiver
                            (CDW) does not apply to damage or loss of the equipment provided with the EV.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Charging Kit:</strong> The Charging Kit consists of 1 Mobile Connector; 1 Storage Bag; and 1 NEMA
                            5-15 / J1772 Adapter. You are responsible to return all contents of the Charging Kit upon your
                            rental return. If the Charging Kit, or any part of the contents are damaged or lost, you will be
                            charged for a complete Charging Kit, as these items are not available to be replaced
                            individually, and a service fee.
                        </p>
                    </li>

                </ul>

                <p >
                    By signing below, I acknowledge and consent to the use of
                    an electronic signature and affirm that this signature is legally binding in accordance with the terms of this
                    agreement.

                    <br />
                    <br />
                    <span>X_____________________</span>
                </p>
            </div>
        )
    } else {
        // show the PDF URL and tell to use confim it
        return (
            <div style={RentalAgreementStyles.agreementWrapper}>
                <p style={RentalAgreementStyles.agreementText}>
                    Please review the <a href={urlPDF} target="_blank" rel="noreferrer">Signed Rental Agreement</a> and confirm it to start the trip.
                </p>
            </div>
        )
    }
};

export default RentalAgreement;
