// components/ModalChargingStationDetail.tsx
import React, { CSSProperties } from 'react';

interface ModalChargingStationDetailProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
    isMobile: boolean;
}

const ModalChargingStationDetail: React.FC<ModalChargingStationDetailProps> = ({ isOpen, onClose, children, isMobile }) => {
    if (!isOpen) return null;

    return (
        <div style={styles.overlay} onClick={onClose}>
            <div style={styles.modal(isMobile)} onClick={(e) => e.stopPropagation()}>
                <button style={styles.closeButton} onClick={onClose}>×</button>
                <div style={styles.content}>{children}</div>
            </div>
        </div>
    );
};

const styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    } as CSSProperties,
    modal: (isMobile: boolean) =>
    ({
        backgroundColor: 'white',
        borderRadius: '8px',
        width: isMobile ? '90%' : '50%',
        maxHeight: '70%',
        overflowY: 'auto',
        position: 'relative',
    } as CSSProperties),
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        background: 'none',
        border: 'none',
        fontSize: '24px',
        cursor: 'pointer',
    } as CSSProperties,
    content: {
        padding: '20px',
    } as CSSProperties,
};

export default ModalChargingStationDetail;
