import { toast } from 'react-toastify';

const showToast = (message: string, type: "error" | "success" | "info" | "warning" = "info", duration: number = 3000) => {
    const toastOptions:object = {
        position: "bottom-right",
        autoClose: duration,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    };

    switch(type) {
        case "error":
            toast.error(message, toastOptions);
            break;
        case "success":
            toast.success(message, toastOptions);
            break;
        case "info":
            toast.info(message, toastOptions);
            break;
        case "warning":
            toast.warn(message, toastOptions);
            break;
        default:
            toast(message, toastOptions);
            break;
    }
}


export default showToast;
