import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Components
import TimePickerMobile from '../components/picker/TimePickerMobile';
import DateRangePickerMobile from '../components/picker/DateRangePickerMobile';
import { DateRange } from 'react-day-picker';
// styles
import styles from '../styles/bookingStyles.module.css';
// hooks
import { useIsMobile, useAnimateOnDesktop } from '../hooks/useIsMobile';
// assets
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import logo from '../images/logo.jpg';
import placeHolder from '../images/place-holder.png';
// utils
import showToast from '../utils/toastHelpers';
// others
import moment from 'moment-timezone';

const Booking: React.FC = () => {
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const shouldAnimate = useAnimateOnDesktop(isMobile);

    const getDefaultDatesAndTimes = () => {
        const now = moment();
        const isAfter7pm = now.hour() >= 19;
        const isBefore8am = now.hour() < 8;
        const defaultFromDate = isAfter7pm
            ? moment().add(1, 'day').startOf('day').add(12, 'hours')
            : isBefore8am
            ? moment().startOf('day').add(12, 'hours')
            : moment().add(4, 'hours');

        const defaultToDate = isAfter7pm
            ? moment().add(2, 'days').startOf('day').add(12, 'hours')
            : isBefore8am
            ? moment().add(1, 'day').startOf('day').add(12, 'hours')
            : moment().add(4, 'hours').add(1, 'day');

        // Default times based on the time of day
        const defaultTime = isAfter7pm || isBefore8am
            ? '12:00 PM'
            : (() => {
                const currentMinTime = moment().add(4, 'hours');
                const remainder = 5 - (currentMinTime.minute() % 5);
                return currentMinTime.add(remainder === 5 ? 0 : remainder, 'minutes').format('hh:mm A');
            })();

        return { defaultFromDate, defaultToDate, defaultTime };
    };

    // Calculate defaults once
    const [defaults] = useState(getDefaultDatesAndTimes());
    const { defaultFromDate, defaultToDate, defaultTime } = defaults;

    const [selectedDates, setSelectedDates] = useState<DateRange | undefined>({
        from: defaultFromDate.toDate(),
        to: defaultToDate.toDate(),
    });

    const [pickUpTime, setPickUpTime] = useState(defaultTime);
    const [returnTime, setReturnTime] = useState(defaultTime);

    const handleDateChange = (range: DateRange) => {
        setSelectedDates(range);
    };

    const handleClearDates = () => {
        const { defaultFromDate, defaultToDate } = getDefaultDatesAndTimes();
        setSelectedDates({
            from: defaultFromDate.toDate(),
            to: defaultToDate.toDate(),
        });
    };

    const handleClearTimes = () => {
        const { defaultTime } = getDefaultDatesAndTimes();
        setPickUpTime(defaultTime);
        setReturnTime(defaultTime);
    };

    const formatTime = (time: string) => {
        return moment(time, 'hh:mm A').format('HH:mm:ss');
    };

    const formatDate = (date: any) => {
        return moment(date).format('YYYY-MM-DD');
    };

    const handleSearch = async () => {
        if (!selectedDates?.from || !selectedDates?.to) {
            showToast('Please select dates', 'error');
            return;
        }
        if (!pickUpTime || !returnTime) {
            showToast('Please select times', 'error');
            return;
        }
        const now = moment();
        const isAfter7pm = now.hour() >= 19;
        const isBefore8am = now.hour() < 8;
        const selectedPickUpDateTime = moment(`${formatDate(selectedDates.from)} ${pickUpTime}`, 'YYYY-MM-DD hh:mm A');
        const minAllowedDateTime = isAfter7pm
            ? moment().add(1, 'day').startOf('day').add(12, 'hours')
            : isBefore8am
            ? moment().startOf('day').add(12, 'hours')
            : moment().add(3, 'hours').add(59, 'minutes');
        const message = isAfter7pm
            ? 'Pick-up time cannot be earlier than tomorrow at 12 PM'
            : isBefore8am
            ? 'Pick-up time cannot be earlier than today at 12 PM'
            : 'Pick-up time cannot be earlier than 4 hours from now';

        if (selectedPickUpDateTime.isBefore(minAllowedDateTime)) {
            showToast(message, 'error');
            handleClearDates();
            handleClearTimes();
            return;
        }
        navigate(`/booking/vehicles?startDate=${formatDate(selectedDates.from)}&endDate=${formatDate(selectedDates.to)}&pickUpTime=${formatTime(pickUpTime)}&returnTime=${formatTime(returnTime)}`);
    };

    return (
        <div className={styles.container}>
            <div className={styles.innerContainer} style={{
                transform: isMobile ? 'translateX(0%)' : (shouldAnimate ? 'translateX(0%)' : 'translateX(-100%)')
            }}>
                <div className={styles.scrollableContent}>
                    <div className={styles.header}>
                        <LazyLoadImage
                            src={logo}
                            alt="flux-logo"
                            effect="blur"
                            placeholderSrc={placeHolder}
                            className={styles.logo}
                        />
                        <p className={styles.subtitle}>Choose your preferred date and time</p>
                    </div>
                    <div className={styles.pickerContainer}>
                        <DateRangePickerMobile
                            range={selectedDates}
                            setRange={handleDateChange}
                            disabledDays={{ before: moment().hours() >= 19 ? moment().add(1, 'day').toDate() : moment().toDate() }}
                        />
                        <button onClick={handleClearDates} className={styles.clearButton}>Clear Dates</button>
                        <TimePickerMobile
                            onTimeChange={(time: string) => {
                                setPickUpTime(time);
                                setReturnTime(time);
                            }}
                            name="Pick Up Time"
                            initialTime={pickUpTime}
                        />
                        <TimePickerMobile
                            onTimeChange={(time: string) => setReturnTime(time)}
                            name="Return Time"
                            initialTime={returnTime}
                        />
                        <button onClick={handleClearTimes} className={styles.clearButton}>Clear Times</button>
                        <button type="button" className={styles.searchButton} onClick={handleSearch}>Search Vehicles</button>
                    </div>
                </div>
                {isMobile && <div style={{ height: '130px', width: '100%' }}></div>}
            </div>
        </div>
    );
};

export default Booking;
