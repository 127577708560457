import React, { createContext, useContext, useState,useEffect, useMemo,useCallback } from 'react';
import { getAuth, signInWithEmailAndPassword, signOut, sendEmailVerification } from '@firebase/auth';
import { app } from '../firebase/firebase';
import fluxRestApi from '../services/FluxRestApi';
import { useNavigate } from 'react-router-dom';
import showToast from '../utils/toastHelpers';

interface AuthContextType {
    user: any;
    setUser: React.Dispatch<React.SetStateAction<any>>;
    contactData: any;
    setContactData: React.Dispatch<React.SetStateAction<any>>;
    authSignIn: (email: string, password: string) => Promise<AuthResponse>;
    authSignOut: () => Promise<AuthResponse>;
    authSignUp: (email: string, password: string, phone: string, marketingSms: boolean, accountUpdateSms: boolean, verifiedPhone: boolean) => Promise<AuthResponse>;
    ResendEmailConfirmation: (email: string, password: string) => Promise<AuthResponse>;
    lastResend: Date | null;
    setLastResend: React.Dispatch<React.SetStateAction<Date | null>>;
    updateContactData: (currentContactData: any, newData: any)  => Promise<AuthResponse>;
    approveAgreements: (currentContactData: any, newData: any) => Promise<AuthResponse>;
    updatePersonaInquiryId: (currentContactData: any, newData: any) => Promise<AuthResponse>;
    updateIDScan: (currentContactData: any, newData: any) => Promise<AuthResponse>;
    updatePartialProperties: (currentContactData: any, newData: any) => Promise<AuthResponse>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }
    return context;
};
interface AuthProviderProps  {
    children: React.ReactNode;
}

interface AuthResponse {
    success: boolean;
    message: string;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const navigate = useNavigate();
    // states
    const [user, setUser] = useState<any>(null);
    const [contactData, setContactData] = useState<any>(null);
    const auth = getAuth(app);
    const [lastResend, setLastResend] = useState<Date | null>(null);

    // funtions
    const getContactData = useCallback(async (email: string) => {
        const contactDataResponse = await fluxRestApi.getContactDataByEmail(email);
        if (contactDataResponse?.data) {
            setContactData(contactDataResponse.data);
        } else {
            throw new Error('Failed to fetch contact data');
        }
    }, [setContactData]);

    const approveAgreements = useCallback(async (currentContactData: any, newData: any) => {
        if (!currentContactData?.contact?.id) return { success: false, message: "Sorry, please try again later,(id not found)" };
        if (!newData) return { success: false, message: "Sorry, please try again later,(data not found)" };
        if(!currentContactData) return { success: false, message: "Sorry, please try again later,(currentContactData not found)" };
        const approveAgreementsResponse = await fluxRestApi.approveAgreements(newData);
        if (approveAgreementsResponse?.data?.contact) {
            // the response don't return the email and other data, so we need to get the contact data again
            try{
                getContactData(currentContactData.contact.email);
                return { success: true, message: "Your account has been updated successfully!" };
            }catch (error){
                console.log(error,'error on getContactData after approveAgreements');
                return { success: false, message: "Sorry, please try again later (getting contact data)" };
            }
        } else {
            return { success: false, message: "Sorry, please try again later" };
        }
    }, [getContactData]);

    const updateContactData = useCallback(async (currentContactData: any, newData: any) => {
        if (!currentContactData?.contact?.id) return { success: false, message: "Sorry, please try again later,(id not found)" };
        if (!newData) return { success: false, message: "Sorry, please try again later,(data not found)" };
        if(!currentContactData) return { success: false, message: "Sorry, please try again later,(currentContactData not found)" };
        const updateContactDataResponse = await fluxRestApi.updateContactData(currentContactData?.contact?.id,newData);
        if (updateContactDataResponse?.data?.contact) {
            // the response don't return the email and other data, so we need to get the contact data again
            try{
                getContactData(currentContactData.contact.email);
                return { success: true, message: "Your account has been updated successfully!" };
            }catch (error){
                console.log(error,'error on getContactData after updateContactData');
                return { success: false, message: "Sorry, please try again later (getting contact data)" };
            }
        } else {
            return { success: false, message: "Sorry, please try again later" };
        }
    }, [getContactData]);

    const updatePersonaInquiryId = useCallback(async (currentContactData: any, newData: any) => {
        if (!currentContactData?.contact?.email) return { success: false, message: "Sorry, please try again later,(email not found)" };
        if (!newData) return { success: false, message: "Sorry, please try again later,(data not found)" };
        if(!currentContactData) return { success: false, message: "Sorry, please try again later,(currentContactData not found)" };
        const updatePersonaInquiryIdResponse = await fluxRestApi.fetchStatusPersona(currentContactData.contact.email,newData);
        if (updatePersonaInquiryIdResponse?.data?.status === 'success') {
            // the response don't return the email and other data, so we need to get the contact data again
            try{
                getContactData(currentContactData.contact.email);
                return { success: true, message: "Your account has been updated successfully!" };
            }catch (error){
                console.log(error,'error on getContactData after updatePersonaInquiryId');
                return { success: false, message: "Sorry, please try again later (getting contact data)" };
            }
        } else {
            return { success: false, message: "Sorry, please try again later" };
        }
    }, [getContactData]);

    const updateIDScan = useCallback(async (currentContactData: any, newData: any) => {
        if (!currentContactData?.contact?.id) return { success: false, message: "Sorry, please try again later,(id not found)" };
        if (!currentContactData?.contact?.email) return { success: false, message: "Sorry, please try again later,(email not found)" };
        if (!newData?.idscan_request_id) return { success: false, message: "Sorry, please try again later,(idscan_request_id not found)" };
        if (!newData?.idscan_status) return { success: false, message: "Sorry, please try again later,(idscan_status not found)" };
        const updateIDScanResponse = await fluxRestApi.updatePartialFluxProperties(currentContactData.contact.id,newData);
        if (updateIDScanResponse?.data?.contact) {
            // the response don't return the email and other data, so we need to get the contact data again
            try{
                getContactData(currentContactData.contact.email);
                return { success: true, message: "Your account has been updated successfully!" };
            }catch (error){
                console.log(error,'error on getContactData after updateIDScan');
                return { success: false, message: "Sorry, please try again later (getting contact data)" };
            }
        } else {
            return { success: false, message: "Sorry, please try again later" };
        }
    }, [getContactData]);

    const updatePartialProperties = useCallback(async (currentContactData: any, newData: any) => {
        if (!currentContactData?.contact?.id) return { success: false, message: "Sorry, please try again later,(id not found)" };
        if (!currentContactData?.contact?.email) return { success: false, message: "Sorry, please try again later,(email not found)" };
        if (!newData) return { success: false, message: "Sorry, please try again later,(data not found)" };
        const updatePartialPropertiesResponse = await fluxRestApi.updatePartialFluxProperties(currentContactData.contact.id, newData);
        if (updatePartialPropertiesResponse?.data?.contact) {
            // the response don't return the email and other data, so we need to get the contact data again
            try{
                getContactData(currentContactData.contact.email);
                return { success: true, message: "Your account has been updated successfully!" };
            }catch (error){
                console.log(error,'error on getContactData after updatePartialProperties');
                return { success: false, message: "Sorry, please try again later (getting contact data)" };
            }
        } else {
            return { success: false, message: "Sorry, please try again later" };
        }
    }, [getContactData]);


    const createUser = useCallback(async (email: string, password: string, phone: string, marketingSms: boolean, accountUpdateSms: boolean, verifiedPhone: boolean) => {
        const createUserResponse = await fluxRestApi.createUser(email, password, phone, marketingSms, accountUpdateSms, undefined, undefined, undefined, verifiedPhone);
        if (createUserResponse?.data?.user) {
            return { success: true, message: "Your account has been created successfully!" };
        } else {
            return { success: false, message: "Sorry, please try again later" };
        }
    }, []);

    const authSignIn = useCallback(async (email: string, password: string) => {
        try {
            const response = await signInWithEmailAndPassword(auth, email, password);
            if (response.user.emailVerified) {
                setUser(response.user);
                await getContactData(email)
                return { success: true, message: "Logged in successfully!" };
            } else {
                await signOut(auth);
                setUser(null);
                return { success: false, message: "Your account is not verified, please, check your email!" };
            }
        } catch (error: any) {
            if (user) {
                await signOut(auth);
                setUser(null);
            }
            // handle error
            const errorMessages: Record<string, string> = {
                'auth/user-not-found': "No user found with this email. Please sign up or enter a correct email.",
                'auth/wrong-password': "Incorrect password. Please try again.",
                'auth/too-many-requests': "Too many requests. Please try again later.",
                'auth/quota-exceeded': "Too many requests. Please try again later.",
                'auth/invalid-email': "Invalid email. Please try again.",
                'auth/internal-error': "Internal error. Please try again later.",
            };
            // In your catch block:
            const message = errorMessages[error.code] || error.message;
            return { success: false, message };
        }
    }, [auth, setUser, getContactData, user]);


    const authSignOut = useCallback(async () => {
        try {
            await signOut(auth);
            setUser(null);
            setContactData(null);
            return { success: true, message: "Logged out successfully!" };
        } catch (error: any) {
            // handle error
            return { success: false, message: error.message };
        }
    }, [auth, setUser, setContactData]);

    const authSignUp = useCallback(async (email: string, password: string, phone: string, marketingSms: boolean, accountUpdateSms: boolean, verifiedPhone: boolean) => {
        const createUserResponse = await createUser(email, password, phone, marketingSms, accountUpdateSms, verifiedPhone);
        return createUserResponse;
    }, [createUser]);

    const ResendEmailConfirmation = useCallback(async (email: string, password: string) => {
        try {
            // Check if it's too soon to resend
            const now = new Date();
            if (lastResend && (now.getTime() - lastResend.getTime()) < 30000) { // 30000ms = 30 seconds
                return { success: false, message: "Please wait before trying to resend." };
            }

            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            if (!user) throw new Error("User not found");

            await sendEmailVerification(user);
            await signOut(auth);

            //Update the last resend time
            setLastResend(now);

            return { success: true, message: "Email confirmation sent!" };
        } catch (error: any) {
            console.log(error, 'error on resend email');
            // handle error
            return { success: false, message: "Sorry, please try again!" };
        }
    }, [auth, lastResend, setLastResend]);

    // effects
    useEffect(() => {
        console.log("AuthProvider useEffect");
        const unsubscribe = getAuth(app).onAuthStateChanged(async (firebaseUser) => {
            if (firebaseUser && firebaseUser.email && firebaseUser.emailVerified) {
                // User is logged in
                setUser(firebaseUser);
                try {
                    await getContactData(firebaseUser.email)
                    console.log("User is logged in by AuthProvider");
                } catch (error) {
                    console.error("Error fetching contact data:", error);
                    await signOut(auth);
                    setUser(null);
                    setContactData(null);
                    // Navigate to the login page
                    showToast("Sorry, you must be Sign in again", "error");
                    navigate('/login');
                }
            } else {
                // User is logged out
                console.log("User is not logged in by AuthProvider");
                setUser(null);
                setContactData(null);
            }
        });

        // Clean up the listener when the component is unmounted
        return () => unsubscribe();

    }, [navigate, auth, getContactData,]);

    const contextValue = useMemo(() => {
        return { user, setUser, contactData, setContactData, authSignIn, authSignOut, authSignUp, ResendEmailConfirmation, lastResend, setLastResend, updateContactData, approveAgreements,updatePersonaInquiryId, updateIDScan,updatePartialProperties };
    }, [user, contactData, authSignIn, authSignOut, authSignUp, ResendEmailConfirmation, lastResend, updateContactData, approveAgreements, updatePersonaInquiryId, updateIDScan, updatePartialProperties]);

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );

};
