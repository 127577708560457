import React from "react";

// styles
const styles: { [key: string]: React.CSSProperties } = {
    selectContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '10px',
    },
    select: {
        padding: '10px 20px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        cursor: 'pointer',
        outline: 'none',
        transition: 'background-color 0.3s',
    },
};

interface TabArray {
    label: string;
    value: string;
}

interface TabSelectorProps {
    activeTab: string;
    tabs: TabArray[];
    onTabChange: (tabValue: string) => void;
}

const TabSelector: React.FC<TabSelectorProps> = ({ activeTab, onTabChange, tabs }) => {
    return (
        <div style={styles.selectContainer}>
            <select
                style={styles.select}
                value={activeTab}
                onChange={(e) => onTabChange(e.target.value)}
            >
                {tabs.map(tab => (
                    <option key={tab.value} value={tab.value}>
                        {tab.label}
                    </option>
                ))}
            </select>
        </div>
    );
};


export default TabSelector;
