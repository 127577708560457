import React, { CSSProperties, MouseEvent } from 'react';
import { useIsMobile } from '../../hooks/useIsMobile';

interface ModalProps {
    isVisible: boolean;
    children: React.ReactNode;
    onClose: () => void;
}

const ModalComponentTripDetails: React.FC<ModalProps> = ({ isVisible, children, onClose }) => {
    const isMobile = useIsMobile();
    const modalStyles: CSSProperties = {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: isMobile ? 'flex-start' : 'center',
        zIndex: 2000,
    };

    const containerStyles: CSSProperties = {
        backgroundColor: '#fff',
        borderRadius: '4px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
        width: isMobile ? '95%' : '80%',
        maxWidth: isMobile ? '95%' : '80%',
        maxHeight: isMobile ? '90%' : '80%',
        overflow: 'auto',
        position: 'relative',
    };

    const closeButtonStyles: CSSProperties = {
        position: 'absolute',
        top: isMobile ? 0 : '10px',
        right: isMobile ? '5px' : '10px',
        border: 'none',
        backgroundColor: 'transparent',
        padding: '10px',
        cursor: 'pointer',
        fontSize: '18px',
        fontWeight: 'bold',
        zIndex: 2001,
    };

    const handleBackgroundClick = (e: MouseEvent<HTMLDivElement>) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        isVisible ? (
            <>
                <div style={modalStyles} onClick={handleBackgroundClick}>
                    <div style={containerStyles}>
                        <button style={closeButtonStyles} onClick={onClose}>
                            X
                        </button>
                        {children}
                    </div>
                </div>
            </>
        ) : null
    );
}

export default ModalComponentTripDetails;
